<div class="user-dashboard-page main-content" fxLayout="column" fxLayoutAlign="top center">
  <div class="button-bar" style="width:90vw;" fxLayout="row" fxLayoutAlign="top left" fxLayoutGap="10">
    <button *ngIf="!isSubPage && worker.status === 1" kendoButton themeColor="primary" fillMode="outline" icon="chevron-left" (click)="goToButtonOnClick('list')">All workers</button>
    <button *ngIf="!isSubPage && worker.status === 0" kendoButton themeColor="primary" fillMode="outline" icon="chevron-left" (click)="goToButtonOnClick('deletedList')">All deleted workers</button>
    <button *ngIf="isSubPage && supervisor" kendoButton themeColor="primary" fillMode="outline" icon="chevron-left" (click)="goToButtonOnClick('supervisorDashboard')">Dashboard of supervisor <b>{{supervisor.firstName}} {{supervisor.lastName}}</b></button>
    <button *ngIf="isSubPage && !supervisor && worker.status === 1" kendoButton themeColor="primary" fillMode="outline" icon="chevron-left" (click)="goToButtonOnClick('list')">All workers</button>
    <button *ngIf="isSubPage && !supervisor && worker.status === 0" kendoButton themeColor="primary" fillMode="outline" icon="chevron-left" (click)="goToButtonOnClick('deletedList')">All deleted workers</button>
    <button kendoButton themeColor="primary" fillMode="outline" icon="chevron-right" (click)="goToButtonOnClick('pictureCompare')">Picture compare</button>
  </div>
  <div class="detail-page dashboard" style="width:90vw;">
    <!-- LAYOUT OF DASHBOARD FOR GENERAL MANAGERS-->
    <div class="title-container">
      <h2 *ngIf="worker">Dashboard of worker {{worker.firstName}} {{worker.lastName}} </h2>
    </div>
    <kendo-tilelayout class="tile" [columns]="12" [rowHeight]="10" *ngIf="outputWorker">
      <!-- Picture -->
      <kendo-tilelayout-item [col]="responsiveWrappers.picture.col" [colSpan]="responsiveWrappers.picture.colspan" [rowSpan]="responsiveWrappers.picture.rowspan">
        <kendo-tilelayout-item-header class="title">
          <h3>Picture</h3>
        </kendo-tilelayout-item-header>
        <kendo-tilelayout-item-body>
          <div class="content">
            <app-worker-detail-picture [workerData] = "outputWorker"></app-worker-detail-picture>
          </div>
        </kendo-tilelayout-item-body>
      </kendo-tilelayout-item>
      <!-- Own data -->
      <kendo-tilelayout-item [col]="responsiveWrappers.workerData.col" [colSpan]="responsiveWrappers.workerData.colspan" [rowSpan]="responsiveWrappers.workerData.rowspan">
        <kendo-tilelayout-item-header class="title">
          <h3>Worker data</h3>
        </kendo-tilelayout-item-header>
        <kendo-tilelayout-item-body>
          <div class="content">
            <app-worker-detail-data [workerData] = "outputWorker" [generalManager] = "outputGeneralManager" [cropManager] = "outputCropManager" [supervisor] = "outputSupervisor"></app-worker-detail-data>
          </div>
        </kendo-tilelayout-item-body>
      </kendo-tilelayout-item>
      <!-- All assignment (leave at the moment) -->
      <kendo-tilelayout-item [col]="responsiveWrappers.assignedToSupervisor.col" [colSpan]="responsiveWrappers.assignedToSupervisor.colspan" [rowSpan]="responsiveWrappers.assignedToSupervisor.rowspan">
        <kendo-tilelayout-item-header class="list-subpage title" fxLayout="row" fxLayoutAlign="none">
          <div class="main-title" fxFlex="50">
            <h3>
              Assigned to supervisors
            </h3>
          </div>
          <!--<div fxFlex="50" class="button-row" style="text-align:right;" *ngIf="currentUser.userTypeId <= 2">
              <button kendoButton [primary]="false" [icon]="'checkbox-checked'" (click)="goToButtonOnClick('supervisorsAssignment')">Assign</button>
          </div>-->
        </kendo-tilelayout-item-header>
        <kendo-tilelayout-item-body>
          <div class="content">
            <app-worker-detail-relatedsupervisors [workerData] = "outputWorker"></app-worker-detail-relatedsupervisors>
          </div>
        </kendo-tilelayout-item-body>
      </kendo-tilelayout-item>
    </kendo-tilelayout>
  </div>
</div>
