
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  NotificationRef,
  NotificationService,
} from '@progress/kendo-angular-notification';
import { UsersService } from 'src/app/modules/users/users.service';
import { User } from 'src/app/shared/models/user.model';
import { PageNavigationService } from 'src/app/shared/page-navigation.service';
import { SharedService } from 'src/app/shared/shared.service';

@Component({
  selector: 'app-general-manager-detail-data',
  templateUrl: './general-manager-detail-data.component.html',
  styleUrls: ['./general-manager-detail-data.component.scss']
})

export class GeneralManagerDetailDataComponent implements OnInit {

  public pageCode: string;

  @Input() userData: User;

  constructor(
    private _sharedService: SharedService,
    private _router: Router,
  ) {}

  ngOnInit(): void {

  }


  public goToEditPage() {
    this._router.navigate(['staff/generalManagers/update'],{ queryParams: { generalManagerId: this.userData.id} });
  }

  public getGenderText(genderValue: number): string{
    const genderText = this._sharedService.getGenderText(genderValue);
    return genderText;
  }
}
