import { Component, Input, OnInit } from '@angular/core';
import { Worker } from 'src/app/shared/models/worker.model';

@Component({
  selector: 'app-worker-detail-picture',
  templateUrl: './worker-detail-picture.component.html',
  styleUrls: ['./worker-detail-picture.component.scss']
})
export class WorkerDetailPictureComponent implements OnInit {

  @Input() workerData: Worker;

  public imageDialogOpened: boolean = false;

  constructor() { }

  ngOnInit(): void {

  }

  public openCloseImageDialog(status: boolean): void {
    this.imageDialogOpened = status;
  }

}
