import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import {
  DataBindingDirective,
  DataStateChangeEvent,
  GridDataResult,
  MultipleSortSettings,
  PagerPosition
} from '@progress/kendo-angular-grid';
import { PagerType } from "@progress/kendo-angular-grid/pager/pager-settings";
import { process, State } from '@progress/kendo-data-query';
import { User } from 'src/app/shared/models/user.model';
import { SharedService } from 'src/app/shared/shared.service';
import { NotificationRef, NotificationService } from '@progress/kendo-angular-notification';
import { UsersService } from '../../users.service';
import { PageNavigationService } from 'src/app/shared/page-navigation.service';

@Component({
  selector: 'app-user-detail-related',
  templateUrl: './user-detail-related.component.html',
  styleUrls: ['./user-detail-related.component.scss']
})

export class UserDetailRelatedComponent implements OnInit {

  @ViewChild(DataBindingDirective) dataBinding: DataBindingDirective;

  public pageCode: string;
  public userId: number;
  private _currentUser: User;
  public gridData: GridDataResult;
  public mySelection: string[] = [];
  public usersDataArray: User[] = [];

  private _varPagerTypes: PagerType = 'numeric';
  private _varPagerPosition: PagerPosition = 'bottom';

  public pagingSettings = {
    pagerTypes: this._varPagerTypes,
    type: 'input',
    // buttonCount: 5,
    info: true,
    previousNext: true,
    position: this._varPagerPosition,
    pageSize: 5,
    responsive: true,
    pageSizes: [
      5,
      10,
      20
    ]
  };

  public sortingSettings: MultipleSortSettings = {
    allowUnsort: true
  };

  public state: State = {
    skip: 0,
    take: this.pagingSettings.pageSize,
    // Initial filter descriptor
  };

  public showHeader = false;

  public splitButtonItems = [
    {
      text: "Detail",
      icon: "detail-section",
      action: "detail"
    },
    {
      text: "Edit",
      icon: "edit",
      action: "edit"
    },
    {
      text: "Delete",
      icon: "delete",
      action: "delete"
    }
  ];

  constructor(private _sharedService: SharedService,private _router: Router, private _usersService: UsersService,
    private _notificationService: NotificationService, private _pageNavigationService: PageNavigationService) {};

  public ngOnInit(): void {
    this.pageCode = this._pageNavigationService.pageCode;
    if (this._pageNavigationService.dataItemId) {
      this.userId = this._pageNavigationService.dataItemId;
      this._usersService.getUserById(this.userId).subscribe(user => {
        this._currentUser = user;
      });
    }
    this._setGridData();
  }

  private _setGridData() {

    let functionGet;
    switch (this.pageCode.toLowerCase()) {
      case 'generalmanagers':
        functionGet = this._usersService.getCropManagers(this.userId);
        break;
      case 'cropmanagers':
        functionGet = this._usersService.getCropManagers(this.userId); //supervisors
        break;
    }
    if (functionGet) {
      functionGet.subscribe((result) => {
        result.forEach(user=> {
          user.genderText = this._sharedService.getGenderText(user.gender);
        } );
        this.usersDataArray = result;
        this.gridData = process(result, this.state);
      });
    }
  }

  public dataStateChange(state: DataStateChangeEvent): void {
    if (state && state.group) {
      // state.group.map((group) => (group.aggregates = this.aggregates));
    }
    this.state = state;
    this.gridData = process(this.usersDataArray, this.state);
  }

  public onSplitButtonClick(): void {
    // nothing
  }

  public onSplitButtonItemClick(button: { [key: string]: unknown }, dataItem:User): void {
  }

  private _deleteRecord(id: number)
  {
    // console.log('deletedId', id);
    this._usersService.deleteUser(id).subscribe({
      complete:()=> {
        this._setGridData();
        this._notificationService.show({
          content: 'The record has been deleted!',
          animation: { type: 'slide', duration: 500 },
          position: { horizontal: 'center', vertical: 'bottom' },
          type: { style: 'success', icon: true },
          hideAfter: 2000,
        });
      },
      error: (error) => {
        // console.log('error___this._usersService.deleteUser', error)
        const notification: NotificationRef =
          this._notificationService.show({
            content: 'Internal error',
            animation: { type: 'slide', duration: 500 },
            position: { horizontal: 'center', vertical: 'bottom' },
            type: { style: 'error', icon: true },
            hideAfter: 1000,
          });
      }
    });
  }
}

