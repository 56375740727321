import { ExpansionPanelComponent } from "@progress/kendo-angular-layout";
import { Component, Input, OnInit, QueryList, ViewChildren } from "@angular/core";
import { Worker } from "src/app/shared/models/worker.model";
import { User } from "src/app/shared/models/user.model";
import { SharedService } from "src/app/shared/shared.service";
import { WorkersService } from "../../workers.service";

@Component({
  selector: 'app-worker-detail-relatedsupervisors',
  templateUrl: './worker-detail-relatedsupervisors.component.html',
  styleUrls: ['./worker-detail-relatedsupervisors.component.scss']
})
export class WorkerDetailRelatedsupervisorsComponent implements OnInit {

  @Input() workerData: Worker;

  @ViewChildren(ExpansionPanelComponent)
  panels: QueryList<ExpansionPanelComponent>;

  public supervisors: User[];
  public recordCount = 0;

  constructor(private _workersService: WorkersService, private _sharedService: SharedService) { }

  ngOnInit(): void {

    this._workersService.getSupervisorsByWorkerId(this.workerData.id).subscribe((result) => {
      result.forEach(user=> {
        user.genderText = this._sharedService.getGenderText(user.gender);
      } );
      this.supervisors = result;
      this.recordCount = result.length;
    });
  }

  public onAction(index: number): void {
    this.panels.forEach((panel, idx) => {
      if (idx !== index && panel.expanded) {
        panel.toggle();
      }
    });
  }

}
