import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router} from '@angular/router';
import { MessageService } from '@progress/kendo-angular-l10n';
import { DrawerComponent, DrawerMode, DrawerSelectEvent } from '@progress/kendo-angular-layout';
import { CustomMessagesService } from '../shared/custom-messages.service';
import { LocalStorageService } from '../shared/local-storage.service';
import { Menu } from '../shared/models/menu.model';
import { User } from '../shared/models/user.model';
import { SharedService } from '../shared/shared.service';


@Component({
  selector: 'app-app-layout',
  templateUrl: './app-layout.component.html',
  styleUrls: ['./app-layout.component.scss']
})


export class AppLayoutComponent implements OnInit, AfterViewInit {
  public selected = 'Profile';
  public customMsgService: CustomMessagesService;
  public mode: DrawerMode = 'overlay';
  public mini = false;
  public drawerExpanded = false;
  public item!: Menu;
  public drawerItems: Menu[] = [];
  public menuWidth = 400;
  public currentUser: User;
  public currentActiveMenuCode: string = '';
  private _menus: Menu[]
  private _pageName: string;


  constructor(public msgService: MessageService, private _localStorageService: LocalStorageService) {
    this.customMsgService = this.msgService as CustomMessagesService

    if (window.innerWidth <= 667) {
      this.menuWidth = window.innerWidth;
    } else {
      this.menuWidth = 400;
    }
  }

  ngOnInit() {

      const lsLoggedUser = localStorage.getItem('loggedUser')
      if (lsLoggedUser) {
        this.currentUser = JSON.parse(lsLoggedUser);
      }
      this._menus = this._localStorageService.getActualMenuLs(this.currentUser.userTypeId, false);

      let titleMenu: Menu = {
        id: 0,
        code: '*menutitle*',
        name: 'MENU',
        icon: '',
        selected: 0,
        expanded: 0,
        hasChildren: false,
        level: 0,
        parentMenuId: 0,
        menuOrder: 0,
        path: '',
        children: [],
        hidden: false
      };
      // console.log('titleMenu:', titleMenu);
      this.drawerItems.push(titleMenu);
      this.drawerItems.push(... this._menus);
      // console.log(this.drawerItems);

  }

  ngAfterViewInit(): void {

    this.drawerItems.forEach(itemP => { //parents
      let parentExpanded = false;
      if (itemP.children) {
        itemP.children.forEach(itemC => { //children
          itemC.selected = 0;
          if (itemC.path === this._pageName) {
            itemC.selected = 1;
            parentExpanded = true;
            this.currentActiveMenuCode = itemC.code;
          }
        });
        if (parentExpanded) {
          itemP.expanded = 1;
          itemP.selected = 1;
        }
      }
    });

  }

  public toggleDrawer(drawer: DrawerComponent): void {
      if (drawer.expanded) {
        this.openCloseMenuMain('close');
      } else {
        this.openCloseMenuMain('open');
      }
  }

  public onSelect(ev: DrawerSelectEvent): void {
    this.drawerItems.forEach((item) => {
      if (item.id !== ev.item.id) {
        item.expanded = 0;
      }
    });
    ev.item.expanded = 1;
    this.openCloseMenuMain('open');
  }

  public openCloseMenuMain(mode: string) {
    if (mode === 'open') {
      this.drawerExpanded = true;
    }
    if (mode === 'close') {
      this.drawerExpanded = false;
    }
  }

  public resetItems(): Menu[] {
    const arr: Menu[] = [];
    this.drawerItems = this._menus;
    this.drawerItems.forEach((item) => {
      arr.push(Object.assign({}, item));
    });
    return arr;
  }

  public goToPage(item: any) {
    if (item.path) {
        window.location.href = '/'+item.path;
    }
  }
}
