<div class="statistic-page main-content">
  <div class="button-bar" style="width:100%"> Reports > Workers at crop statistic</div>
  <div class="card-container">
    <kendo-tilelayout class="tile" [columns]="12" [rowHeight]="10">
      <kendo-tilelayout-item [col]="1" [colSpan]="12" [rowSpan]="3" >
        <div class="search-bar" fxLayout="row" fxLayoutAlign="none" fxLayoutGap="30">
          <div class="search-field" fxLayout="row" fxLayoutAlign=" none" fxLayoutGap="10">
            <span class="k-label"><b>Date from</b></span>
            <kendo-datepicker
              name="dateFrom"
              [(value)]="dateFrom"
              [max]="dateFromMax"
              [placeholder]="''"
              [style]="'width:180px'"
              [format]="'dd-MM-yyyy'"
            ></kendo-datepicker>
          </div>
          <div class="search-field" fxLayout="row" fxLayoutAlign=" none" fxLayoutGap="10">
            <span class="k-label"><b>Date to</b></span>
            <kendo-datepicker
              name="dateTo"
              [(value)]="dateTo"
              [max]="dateToMax"
              [placeholder]="''"
              [style]="'width:180px'"
              [format]="'dd-MM-yyyy'"
            ></kendo-datepicker>
          </div>
          <div class="search-field" fxLayout="row" fxLayoutAlign=" none" fxLayoutGap="10">
            <span class="k-label"><b>Show charts</b></span>
            <ul class="k-radio-list k-list-horizontal">
              <li class="k-radio-item" >
                <input type="radio" name="showCharts" #showChartsTrue [value]="1" kendoRadioButton checked (change)="handleShowChartsValue(1)"/>
                <kendo-label class="k-radio-label" [for]="showChartsTrue" text="Yes"> </kendo-label>
              </li>
              <li class="k-radio-item">
                <input type="radio" name="showCharts" #showChartsFalse [value]="0" kendoRadioButton (change)="handleShowChartsValue(0)" />
                <kendo-label class="k-radio-label" [for]="showChartsFalse" text="No"> </kendo-label>
              </li>
            </ul>
          </div>
          <div class="search-field" fxLayout="row" fxLayoutAlign=" none" fxLayoutGap="10">
            <button kendoButton themeColor="primary" icon="search" (click)="applyFilter()">Search</button>
          </div>
        </div>
      </kendo-tilelayout-item>
      <!-- Own data -->
      <kendo-tilelayout-item [col]="1" [colSpan]="12" [rowSpan]="20" >
        <!-- grid -->
        <kendo-grid
          [data]="gridData"
          [skip]="state.skip"
          (dataStateChange)="dataStateChange($event)"
          style="max-height: 500px; margin-top: 10px !important"
        >
        <ng-template kendoGridToolbarTemplate class="grid-toolbar">
          <div style="display:flex; width:100%;">
            <kendo-grid-spacer></kendo-grid-spacer>
            <div class="maintitle"><h2>Workers at crop statistic</h2></div>
            <kendo-grid-spacer></kendo-grid-spacer>
            <div class="commands-bar">
              <button kendoGridExcelCommand type="button" icon="file-excel" *ngIf="!hiddenForMobile">
                Export Excel</button
              >
            </div>
          </div>
        </ng-template>
          <kendo-grid-column-group *ngIf="!hiddenForMobile"
            title="Crops"
            [width]="200"
            [locked]="false"
            [headerClass]="'gridcolumn header group-header'"
          >
            <kendo-grid-column *ngIf="!hiddenForMobile"
              field="cropId"
              title="Id"
              filter="numeric"
              [headerClass]="'gridcolumn header'"
            >
              <ng-template kendoGridGroupFooterTemplate  let-aggregates="aggregates">
                0
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-column *ngIf="!hiddenForMobile"
              field="cropName"
              title="name"
              filter="text"
              [headerClass]="'gridcolumn header'"
            >
            <ng-template kendoGridFooterTemplate let-column="column">Sum</ng-template></kendo-grid-column>
          </kendo-grid-column-group>
          <kendo-grid-column-group *ngIf="!hiddenForMobile"
            [title]="labelPeriodA"
            [width]="500"
            [locked]="false"
            [headerClass]="'gridcolumn header group-header'"
          >
            <ng-template kendoGridHeaderTemplate let-column="column">
              Period A <br />{{labelPeriodA}}
            </ng-template>
            <kendo-grid-column *ngIf="!hiddenForMobile"
              field="periodA.quantityAmount"
              title="no. of workers"
              [headerClass]="'gridcolumn header align-right'"
              [headerStyle]="{'text-align': 'right'}"
              [footerStyle]="{'text-align': 'right'}"
            >
              <ng-template kendoGridCellTemplate let-dataItem>
                <span class="cell periodA amount">{{dataItem.periodA.quantityAmount}}</span>
              </ng-template>
              <ng-template kendoGridFooterTemplate let-column="column">
                <span class="cell periodA amount sum">{{total["periodA.quantityAmount"].sum}}</span>
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-column *ngIf="!hiddenForMobile"
              field="periodA.quantityPerc"
              title="% of workers"
              [headerClass]="'gridcolumn header align-right'"
              [headerStyle]="{'text-align': 'right'}"
              [footerStyle]="{'text-align': 'right'}"
            >
              <ng-template kendoGridCellTemplate let-dataItem>
                <span class="cell periodA perc">{{dataItem.periodA.quantityPerc | number: '1.2-2'}} %</span>
              </ng-template>
              <ng-template kendoGridFooterTemplate let-column="column">
                <span class="cell periodA perc sum">{{(total["periodA.quantityPerc"].sum) | number: '1.2-2'}} %</span>
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-column *ngIf="!hiddenForMobile"
              field="periodA.hoursAmount"
              title="no. of hours"
              [headerClass]="'gridcolumn header align-right'"
              [headerStyle]="{'text-align': 'right'}"
              [footerStyle]="{'text-align': 'right'}"
            >
              <ng-template kendoGridCellTemplate let-dataItem>
                <span class="cell periodA amount">{{dataItem.periodA.hoursAmount | number: '1.2-2'}}</span>
              </ng-template>
              <ng-template kendoGridFooterTemplate let-column="column">
                <span class="cell periodA amount sum">{{(total["periodA.hoursAmount"].sum | number: '1.2-2') }}</span>
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-column *ngIf="!hiddenForMobile"
              field="periodA.hoursPerc"
              title="% of hours"
              [headerClass]="'gridcolumn header align-right'"
              [headerStyle]="{'text-align': 'right'}"
              [footerStyle]="{'text-align': 'right'}"
            >
              <ng-template kendoGridCellTemplate let-dataItem>
                <span class="cell periodA perc">{{dataItem.periodA.hoursPerc | number: '1.2-2'}} %</span>
              </ng-template>
              <ng-template kendoGridFooterTemplate let-column="column">
                <span class="cell periodA perc sum">{{(total["periodA.hoursPerc"].sum) | number: '1.2-2'}} %</span>
              </ng-template>
            </kendo-grid-column>
          </kendo-grid-column-group>
          <kendo-grid-column-group *ngIf="!hiddenForMobile"
            [title]="labelPeriodB"
            [width]="500"
            [locked]="false"
            [headerClass]="'gridcolumn header group-header'"
          >
            <ng-template kendoGridHeaderTemplate let-column="column">
              Period B <br />{{labelPeriodB}}
            </ng-template>
            <kendo-grid-column *ngIf="!hiddenForMobile"
              field="periodB.quantityAmount"
              title="no. of workers"
              [headerClass]="'gridcolumn header align-right'"
              [headerStyle]="{'text-align': 'right'}"
              [footerStyle]="{'text-align': 'right'}"
            >
              <ng-template kendoGridCellTemplate let-dataItem>
                <span class="cell periodB amount">{{dataItem.periodB.quantityAmount}}</span>
              </ng-template>
              <ng-template kendoGridFooterTemplate let-column="column">
                <span class="cell periodB amount sum">{{total["periodB.quantityAmount"].sum}}</span>
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-column *ngIf="!hiddenForMobile"
              field="periodB.quantityPerc"
              title="% of workers"
              [headerClass]="'gridcolumn header align-right'"
              [headerStyle]="{'text-align': 'right'}"
              [footerStyle]="{'text-align': 'right'}"
            >
              <ng-template kendoGridCellTemplate let-dataItem>
                <span class="cell periodB perc">{{dataItem.periodB.quantityPerc | number: '1.2-2'}} %</span>
              </ng-template>
              <ng-template kendoGridFooterTemplate let-column="column">
                <span class="cell periodB perc sum">{{(total["periodB.quantityPerc"].sum) | number: '1.2-2'}} %</span>
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-column *ngIf="!hiddenForMobile"
              field="periodB.hoursAmount"
              title="no. of hours"
              [headerClass]="'gridcolumn header align-right'"
              [headerStyle]="{'text-align': 'right'}"
              [footerStyle]="{'text-align': 'right'}"
            >
              <ng-template kendoGridCellTemplate let-dataItem>
                <span class="cell periodB amount">{{dataItem.periodB.hoursAmount | number: '1.2-2'}}</span>
              </ng-template>
              <ng-template kendoGridFooterTemplate let-column="column">
                <span class="cell periodB amount sum">{{(total["periodB.hoursAmount"].sum) | number: '1.2-2'}}</span>
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-column *ngIf="!hiddenForMobile"
              field="periodB.hoursPerc"
              title="% of hours"
              [headerClass]="'gridcolumn header align-right'"
              [headerStyle]="{'text-align': 'right'}"
              [footerStyle]="{'text-align': 'right'}"
            >
              <ng-template kendoGridCellTemplate let-dataItem>
                <span class="cell periodB perc">{{dataItem.periodB.hoursPerc | number: '1.2-2'}} %</span>
              </ng-template>
              <ng-template kendoGridFooterTemplate let-column="column">
                <span class="cell periodB perc sum">{{(total["periodB.hoursPerc"].sum) | number: '1.2-2'}} %</span>
              </ng-template>
            </kendo-grid-column>
          </kendo-grid-column-group>
          <kendo-grid-column-group *ngIf="!hiddenForMobile"
            [title]="'Difference'"
            [width]="500"
            [locked]="false"
            [headerClass]="'gridcolumn header group-header'"
          >
            <ng-template kendoGridHeaderTemplate let-column="column">
              Difference
            </ng-template>
            <kendo-grid-column *ngIf="!hiddenForMobile"
              field="difference.quantityAmount"
              title="no. of workers"
              [headerClass]="'gridcolumn header align-right'"
              [headerStyle]="{'text-align': 'right'}"
              [footerStyle]="{'text-align': 'right'}"
            >
              <ng-template kendoGridCellTemplate let-dataItem>
                <span class="cell difference amount {{dataItem.difference.quantityAmount<0 ? 'negative' : 'positive'}}">
                  <span class="k-icon k-i-sort-asc-sm" *ngIf="dataItem.difference.quantityAmount>0"></span>
                  <span class="k-icon k-i-sort-desc-sm" *ngIf="dataItem.difference.quantityAmount<0"></span>
                  {{dataItem.difference.quantityAmount}}</span>
              </ng-template>
              <ng-template kendoGridFooterTemplate let-column="column">
                <span class="cell difference amount sum">{{total["difference.quantityAmount"].sum}}</span>
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-column *ngIf="!hiddenForMobile"
              field="difference.quantityPerc"
              title="% of workers"
              [headerClass]="'gridcolumn header align-right'"
              [headerStyle]="{'text-align': 'right'}"
              [footerStyle]="{'text-align': 'right'}"
            >
              <ng-template kendoGridCellTemplate let-dataItem>
                <span class="cell difference perc {{dataItem.difference.quantityPerc<0 ? 'negative' : 'positive'}}">
                  <span class="k-icon k-i-sort-asc-sm" *ngIf="dataItem.difference.quantityPerc>0"></span>
                  <span class="k-icon k-i-sort-desc-sm" *ngIf="dataItem.difference.quantityPerc<0"></span>
                  {{dataItem.difference.quantityPerc | number: '1.2-2'}} %</span>
              </ng-template>
              <ng-template kendoGridFooterTemplate let-column="column">
                <span class="cell difference perc sum">{{(total["difference.quantityAmount"].sum/total["periodA.quantityAmount"].sum*100) | number: '1.2-2'}} %</span>
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-column *ngIf="!hiddenForMobile"
              field="difference.hoursAmount"
              title="no. of hours"
              [headerClass]="'gridcolumn header align-right'"
              [headerStyle]="{'text-align': 'right'}"
              [footerStyle]="{'text-align': 'right'}"
            >
              <ng-template kendoGridCellTemplate let-dataItem>
                <span class="cell difference amount {{dataItem.difference.hoursAmount<0 ? 'negative' : 'positive'}}">
                  <span class="k-icon k-i-sort-asc-sm" *ngIf="dataItem.difference.hoursAmount>0"></span>
                  <span class="k-icon k-i-sort-desc-sm" *ngIf="dataItem.difference.hoursAmount<0"></span>
                  {{dataItem.difference.hoursAmount | number: '1.2-2'}}</span>
              </ng-template>
              <ng-template kendoGridFooterTemplate let-column="column">
                <span class="cell difference amount sum">{{(total["difference.hoursAmount"].sum) | number: '1.2-2'}}</span>
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-column *ngIf="!hiddenForMobile"
              field="difference.hoursPerc"
              title="% of hours"
              [headerClass]="'gridcolumn header align-right'"
              [headerStyle]="{'text-align': 'right'}"
              [footerStyle]="{'text-align': 'right'}"
            >
              <ng-template kendoGridCellTemplate let-dataItem>
                <span class="cell difference perc {{dataItem.difference.hoursPerc<0 ? 'negative' : 'positive'}}">
                  <span class="k-icon k-i-sort-asc-sm" *ngIf="dataItem.difference.hoursPerc>0"></span>
                  <span class="k-icon k-i-sort-desc-sm" *ngIf="dataItem.difference.hoursPerc<0"></span>
                  {{dataItem.difference.hoursPerc | number: '1.2-2'}} %</span>
              </ng-template>
              <ng-template kendoGridFooterTemplate let-column="column">
                <span class="cell difference perc sum">{{(total["difference.hoursAmount"].sum/total["periodA.hoursAmount"].sum*100) | number: '1.2-2'}} %</span>
              </ng-template>
            </kendo-grid-column>
          </kendo-grid-column-group>
          <kendo-grid-excel fileName="report.xlsx" [fetchData]="excelExportData">
          </kendo-grid-excel>
        </kendo-grid>
      </kendo-tilelayout-item>
      <!-- charts workers-->
      <kendo-tilelayout-item [col]="1" [colSpan]="6" [row]="24" [rowSpan]="15" *ngIf="showCharts">
        <kendo-chart>
          <kendo-chart-title
            [text]="chartLabelWorkersPeriodA"
            color="#FFFFFF"
            background="#6a5941"
            [margin]="20"
          >
          </kendo-chart-title>
          <kendo-chart-legend position="bottom"></kendo-chart-legend>
          <kendo-chart-series>
            <kendo-chart-series-item
              type="pie"
              [data]="pieDataWorkersPeriodA"
              field="value"
              categoryField="category"
              [labels]="{ visible: true, content: labelContent }"

            >
            </kendo-chart-series-item>
          </kendo-chart-series>
        </kendo-chart>
      </kendo-tilelayout-item>
      <kendo-tilelayout-item [col]="7" [colSpan]="6" [row]="24" [rowSpan]="15" *ngIf="showCharts">
        <kendo-chart>
          <kendo-chart-title
            [text]="chartLabelWorkersPeriodB"
            color="#FFFFFF"
            background="#6a5941"
            [margin]="20"
          >
          </kendo-chart-title>
          <kendo-chart-legend position="bottom"></kendo-chart-legend>
          <kendo-chart-series>
            <kendo-chart-series-item
              type="pie"
              [data]="pieDataWorkersPeriodB"
              field="value"
              categoryField="category"
              [labels]="{ visible: true, content: labelContent }"
            >
            </kendo-chart-series-item>
          </kendo-chart-series>
        </kendo-chart>
      </kendo-tilelayout-item>
      <!-- charts hours -->
      <kendo-tilelayout-item [col]="1" [colSpan]="6" [row]="39" [rowSpan]="15" *ngIf="showCharts">
        <kendo-chart>
          <kendo-chart-title
            [text]="chartLabelHoursPeriodA"
            color="#FFFFFF"
            background="#6a5941"
            [margin]="20"
          >
          </kendo-chart-title>
          <kendo-chart-legend position="bottom"></kendo-chart-legend>
          <kendo-chart-series>
            <kendo-chart-series-item
              type="pie"
              [data]="pieDataHoursPeriodA"
              field="value"
              categoryField="category"
              [labels]="{ visible: true, content: labelContent }"

            >
            </kendo-chart-series-item>
          </kendo-chart-series>
        </kendo-chart>
      </kendo-tilelayout-item>
      <kendo-tilelayout-item [col]="7" [colSpan]="6" [row]="39" [rowSpan]="15" *ngIf="showCharts">
        <kendo-chart>
          <kendo-chart-title
            [text]="chartLabelHoursPeriodB"
            color="#FFFFFF"
            background="#6a5941"
            [margin]="20"
          >
          </kendo-chart-title>
          <kendo-chart-legend position="bottom"></kendo-chart-legend>
          <kendo-chart-series>
            <kendo-chart-series-item
              type="pie"
              [data]="pieDataHoursPeriodB"
              field="value"
              categoryField="category"
              [labels]="{ visible: true, content: labelContent }"
            >
            </kendo-chart-series-item>
          </kendo-chart-series>
        </kendo-chart>
      </kendo-tilelayout-item>
    </kendo-tilelayout>
  </div>
</div>

