import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from 'src/app/shared/models/user.model';
import { PageNavigationService } from 'src/app/shared/page-navigation.service';
import { UsersService } from '../users.service';

@Component({
  selector: 'app-user-detail',
  templateUrl: './user-detail.component.html',
  styleUrls: ['./user-detail.component.scss'],
})
export class UserDetailComponent implements OnInit {

  public userId: number;
  public user: User;
  public parentUserId: number;
  public parentUser: User;
  public isSubPage: boolean;
  public pageCode: string

  //configuration variables
  public columns = 1;

  constructor(private _router: Router, private _route: ActivatedRoute, private _usersService: UsersService, private _pageNavigationService: PageNavigationService ) {}

  ngOnInit(): void {

    if (this._pageNavigationService.dataItemId) {
      this.userId = this._pageNavigationService.dataItemId;
      this._usersService.getUserById(this.userId).subscribe(user => {
        this.user = user;
      });
    }
    if (this.parentUserId) {
      this._usersService.getUserById(this.parentUserId).subscribe(parentUser => {
        this.parentUser = parentUser;
      });
    }

    if (window.innerWidth <= 768) {
      this.columns = 1;
    } else {
      this.columns = 3;
    }
  }

  public goToButtonOnClick(pageCode: string): void {

  }

  public onInsertButtonClick(): void {


  }
}
