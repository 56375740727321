import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, throwError } from 'rxjs';
import { User} from 'src/app/shared/models/user.model';
import { environment } from 'src/environments/environment';

enum EndpointRoutes {
  GET_USER = 'user/get',
  UPDATE_USER = 'user/update',
  INSERT_USER = 'user/create',
  DELETE_USER = 'user/delete',
  GET_GENERAL_MANAGERS = 'users/getGeneralManagers',
  GET_CROP_MANAGERS = 'users/getCropManagers',
  GET_SUPERVISORS = 'users/getSupervisors'
}

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  constructor(private _http: HttpClient) { }

  public getUserById(userId: number): Observable<User> {
    let params = new HttpParams();
    params = params.append('id', userId.toString());
    return this._http.get<User>(environment.serverUrl + EndpointRoutes.GET_USER, { params: params }).pipe(
      map(responseData => {
        return responseData;
      }),
      catchError(errorRes => {
        return throwError(() => errorRes);
      })
    );
  }

  public insertUser(userObject: User): Observable<User> {
    return this._http.put<User>(environment.serverUrl + EndpointRoutes.INSERT_USER, { user: userObject }).pipe(
      map(responseData => {
        return responseData;
      }),
      catchError(errorRes => {
        return throwError(errorRes);
      })
    );
  }

  public updateUser(userObject: User): Observable<User> {
    return this._http.post<User>(environment.serverUrl + EndpointRoutes.UPDATE_USER, { user: userObject }).pipe(
      map(responseData => {
        return responseData;
      }),
      catchError(errorRes => {
        return throwError(errorRes);
      })
    );
  }

  public deleteUser(userId: number): Observable<any> {
    let params = new HttpParams();
    params = params.append('id', userId.toString());
    return this._http.delete<any>(environment.serverUrl + EndpointRoutes.DELETE_USER, { params }).pipe(
      map(responseData => {
        return responseData;
      }),
      catchError(errorRes => {
        return throwError(errorRes);
      })
    );
  }

  public getGeneralManagers(): Observable<User[]> {
    return this._http.get<User[]>(environment.serverUrl + EndpointRoutes.GET_GENERAL_MANAGERS).pipe(
      map(responseData => {
        return responseData;
      }),
      catchError(errorRes => {
        return throwError(() => errorRes);
      })
    );
  }

  public getCropManagers(generalManagerId: number): Observable<User[]> {
    let params = new HttpParams();
    params = params.append('id', generalManagerId.toString());
    return this._http.get<User[]>(environment.serverUrl + EndpointRoutes.GET_CROP_MANAGERS, { params: params }).pipe(
      map(responseData => {
        return responseData;
      }),
      catchError(errorRes => {
        return throwError(() => errorRes);
      })
    );
  }

  public getSupervisors(cropManagerId: number): Observable<User[]> {
    let params = new HttpParams();
    params = params.append('id', cropManagerId.toString());
    return this._http.get<User[]>(environment.serverUrl + EndpointRoutes.GET_SUPERVISORS, { params: params }).pipe(
      map(responseData => {
        return responseData;
      }),
      catchError(errorRes => {
        return throwError(() => errorRes);
      })
    );
  }
}
