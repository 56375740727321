import { Component, Input, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { Router } from '@angular/router';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { DataBindingDirective, DataStateChangeEvent, GridDataResult, MultipleSortSettings } from '@progress/kendo-angular-grid';
import { GroupDescriptor, process, State } from '@progress/kendo-data-query';
import * as moment from 'moment';
import { ComparePicturesReport } from 'src/app/shared/models/report.model';
import { UsersService } from '../../users/users.service';
import { ReportsService } from '../reports.service';
import { DialogService } from "@progress/kendo-angular-dialog";
import { ComparePicturesDialogComponent } from "./compare-pictures-dialog/compare-pictures-dialog.component";
import { SharedService } from "../../../shared/shared.service";

@Component({
  selector: 'app-compare-pictures-report',
  templateUrl: './compare-pictures-report.component.html',
  styleUrls: ['./compare-pictures-report.component.scss']
})
export class ComparePicturesReportComponent implements OnInit {
  @ViewChild('dialogContainer', {read: ViewContainerRef})
  public dialogContainer: ViewContainerRef;

  @ViewChild(DataBindingDirective) dataBinding: DataBindingDirective;

  @Input() generalManagerId: number;
  @Input() cropManagerId: number;
  @Input() supervisorId: number;

  public dateFromMax: Date = new Date();
  public dateToMax: Date = new Date();

  public dateFrom : Date = new Date(new Date().setDate(new Date().getDate()-1)); //yesterday
  public dateTo : Date = new Date(new Date().setDate(new Date().getDate()-1)); //yesterday
  public selectedSupervisorId?: number;
  public selectedUnitFarmId?: number;

  public gridData: GridDataResult;
  public mySelection: string[] = [];
  public comparePicturesArray: ComparePicturesReport[] = [];
  public pageCode: string;

  public hiddenForMobile: boolean = false;
  public fixedWidth: number = 150;

  public state: State = {
    skip: 0
  };

  public showHeader = false;

  public group: GroupDescriptor[] = [
  ];

  public sortSettings: MultipleSortSettings = {
    mode: 'multiple'
  };

  public total: any;

  public unitFarmsSource: Array<{ text: string; value: number }> = [];
  public unitFarmsArray: Array<{ text: string; value: number }>;

  public supervisorsSource: Array<{ text: string; value: number }> = [];
  public supervisorsArray: Array<{ text: string; value: number }>;

  public splitButtonItems = [
    {
      text: "Detail",
      icon: "eye",
      action: "detail"
    }
  ];

  constructor(
    private _reportsService: ReportsService, private _usersService: UsersService, private _router: Router,
    private dialogService: DialogService, private _sharedService: SharedService,
  ) {
      this.hiddenForMobile = (window.innerWidth <= 1024);
      if (window.innerWidth <= 768) {
        this.fixedWidth = 100;
      }
    };

  public ngOnInit(): void {

    const lsLoggedUser = localStorage.getItem('loggedUser')
    if (lsLoggedUser) {
      this._sharedService.getUnitFarms().subscribe({
        next: (res) => {
          res.forEach(rec => {
            this.unitFarmsSource.push({text: rec.name, value: rec.id});
            this.unitFarmsArray = this.unitFarmsSource;
          });
        },
        error: () => console.log('Unable to fetch unit farms'),
      });
      this._usersService.getSupervisors(0).subscribe(result => {
        result.forEach(rec => {
          this.supervisorsSource.push({text: rec.firstName + ' ' + rec.lastName, value: rec.id});
          this.supervisorsArray = this.supervisorsSource;
        });
      });
    }
    if (this._loadFiltersSession()) {
      this.applyFilter();
    } else {
      this._setGridData();
    }
  }

  private _setGridData() {
    const dateFrom = moment(this.dateFrom).format('YYYY-MM-DD');
    const dateTo = moment(this.dateTo).format('YYYY-MM-DD');
    const supervisorId = this.selectedSupervisorId;
    const unitFarmId = this.selectedUnitFarmId;

    this._reportsService.comparePicturesReport(dateFrom, dateTo, supervisorId, unitFarmId).subscribe(result => {
      if (result) {
        this.comparePicturesArray = result;
        this.gridData = process(this.comparePicturesArray, this.state);
        this.excelExportData = this.excelExportData.bind(this);
      }
    });

  }

  private _saveFiltersSession() {
    const filters = {
      dateFrom: this.dateFrom,
      dateTo: this.dateTo,
      supervisorId: this.selectedSupervisorId
    };
    localStorage.setItem('reports.compare-pictures', JSON.stringify(filters));
  }

  private _loadFiltersSession(): boolean{
    const lsItem = localStorage.getItem('reports.compare-pictures')
    if (lsItem) {
      const filters = JSON.parse(lsItem);
      // console.log(filters);
      this.dateFrom =  new Date(filters.dateFrom);
      this.dateTo = new Date(filters.dateTo);
      this.selectedSupervisorId = filters.supervisorId;
      return true;
    }
    return false;
  }


  public dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    this.gridData = process(this.comparePicturesArray, this.state);
  }

  public excelExportData(): ExcelExportData {
    const excelState = this.state;
    const excelData = process(this.comparePicturesArray, {
      group: this.state.group,
      sort: this.state.sort,
      filter: this.state.filter,

    });
    const result: ExcelExportData = {
      data: excelData.data,
      group: excelState.group
    };
    return result;
  }

  public applyFilter() {
    this._saveFiltersSession();
    this._setGridData();
  }

  public handleSupervisorFilter(value: any) {
    this.supervisorsArray = this.supervisorsSource.filter(
      (s) => s.text.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  }

  public handleSupervisorSelection(data: any) {
    if (data) {
      this.selectedSupervisorId = data.value;
    } else {
      this.selectedSupervisorId = undefined;
    }
  }

  public handleUnitFarmFilter(value: any) {
    this.unitFarmsArray = this.unitFarmsSource.filter(
      (u) => u.text.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  }

  public handleUnitFarmSelection(data: any) {
    if (data) {
      this.selectedUnitFarmId = data.value;
    } else {
      this.selectedUnitFarmId = undefined;
    }
  }

  public onButtonDetailClick(dataItem:ComparePicturesReport): void {
      this._router.navigate(['staff/workers/picturecompare'],{
        queryParams: { workerId: dataItem.worker.id, locationId: dataItem.id, date: dataItem.reportedDate }
      });
  }

  public onButtonCompareClick(dataItem: ComparePicturesReport) {
    const dialogRef = this.dialogService.open({
      title: 'Pictures comparison',
      content: ComparePicturesDialogComponent,
      appendTo: this.dialogContainer,
      width: window.innerWidth <= 768 ? '90%' : '50%',
      maxHeight: '70%'
    });
    const compareDialog = dialogRef.content.instance as ComparePicturesDialogComponent;
    compareDialog.workerId = dataItem.worker.id;
    compareDialog.locationId = dataItem.id;
    compareDialog.reportedDate = dataItem.reportedDate;
    compareDialog.closeAction = () => dialogRef.close();
  }

}
