import { Component, Input, OnInit, ViewChild} from '@angular/core';
import { SeriesLabelsContentArgs } from '@progress/kendo-angular-charts';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { DataBindingDirective, DataStateChangeEvent, GridDataResult } from '@progress/kendo-angular-grid';
import { IntlService } from '@progress/kendo-angular-intl';
import { aggregateBy, AggregateDescriptor, process, State } from '@progress/kendo-data-query';
import * as moment from 'moment';
import { WorkerAtCropReport } from 'src/app/shared/models/report.model';
import { ReportsService } from '../reports.service';

@Component({
  selector: 'app-workers-at-crop-report',
  templateUrl: './workers-at-crop-report.component.html',
  styleUrls: ['./workers-at-crop-report.component.scss']
})
export class WorkersAtCropReportComponent implements OnInit {

  @ViewChild(DataBindingDirective) dataBinding: DataBindingDirective;

  @Input() generalManagerId: number;
  @Input() cropManagerId: number;
  @Input() supervisorId: number;

  public gridData: GridDataResult;
  public mySelection: string[] = [];
  public workerAtCropArray: WorkerAtCropReport[] = [];
  public pageCode: string;

  public hiddenForMobile: boolean = false;
  public fixedWidth: number = 150;

  public state: State = {
    skip: 0
  };

  public showHeader = false;

  public aggregates: AggregateDescriptor[] = [
    { field: 'periodA.quantityAmount', aggregate: 'sum' },
    { field: 'periodA.quantityPerc', aggregate: 'sum' },
    { field: 'periodA.hoursAmount', aggregate: 'sum' },
    { field: 'periodA.hoursPerc', aggregate: 'sum' },
    { field: 'periodB.quantityAmount', aggregate: 'sum' },
    { field: 'periodB.quantityPerc', aggregate: 'sum' },
    { field: 'periodB.hoursAmount', aggregate: 'sum' },
    { field: 'periodB.hoursPerc', aggregate: 'sum' },
    { field: 'difference.quantityAmount', aggregate: 'sum' },
    { field: 'difference.quantityPerc', aggregate: 'sum' },
    { field: 'difference.hoursAmount', aggregate: 'sum' },
    { field: 'difference.hoursPerc', aggregate: 'sum' }
  ];
  public total: any;

  public pieDataWorkersPeriodA: any[] = [];
  public pieDataWorkersPeriodB: any[] = [];
  public pieDataHoursPeriodA: any[] = [];
  public pieDataHoursPeriodB: any[] = [];

  public dateFromMax: Date = new Date();
  public dateToMax: Date = new Date();

  public selectedShowChartsValue: number;
  public showCharts: boolean = true;

  public dateFrom : Date = new Date(new Date().getFullYear(), 0, 1);
  public dateTo : Date = new Date();
  public labelPeriodA: string;
  public labelPeriodB: string;
  public chartLabelWorkersPeriodA: string;
  public chartLabelWorkersPeriodB: string;
  public chartLabelHoursPeriodA: string;
  public chartLabelHoursPeriodB: string;

  constructor(private _reportsService: ReportsService, private intl: IntlService) {
      this.hiddenForMobile = (window.innerWidth <= 1024);
      if (window.innerWidth <= 768) {
        this.fixedWidth = 100;
      }
    };

  public ngOnInit(): void {

    const lsLoggedUser = localStorage.getItem('loggedUser')
    if (lsLoggedUser) {
      //this._currentUser = JSON.parse(lsLoggedUser);
    }
    this._setGridData();
  }

  private _setGridData() {
    this.workerAtCropArray = this._reportsService.workerAtCropReportExample();
    if (this.workerAtCropArray) {
      // console.log(this.workerAtCropArray);
      this.gridData = process(this.workerAtCropArray, this.state);
      this.total = aggregateBy(this.workerAtCropArray, this.aggregates);

      let tempFrom = moment(new Date(this.dateFrom.getFullYear()-1, this.dateFrom.getMonth(), this.dateFrom.getDate())).format('DD-MM-YYYY');
      let tempTo = moment(new Date(this.dateTo.getFullYear()-1, this.dateTo.getMonth(), this.dateTo.getDate())).format('DD-MM-YYYY');
      this.labelPeriodA = tempFrom + ' | ' + tempTo;
      this.chartLabelWorkersPeriodA = 'WORKERS: ' + tempFrom + ' | ' + tempTo;
      this.chartLabelHoursPeriodA = 'HOURS: ' + tempFrom + ' | ' + tempTo;
      tempFrom = moment(this.dateFrom).format('DD-MM-YYYY');
      tempTo = moment(this.dateTo).format('DD-MM-YYYY');
      this.labelPeriodB = tempFrom + ' | ' + tempTo;
      this.chartLabelWorkersPeriodB = 'WORKERS: ' + tempFrom + ' | ' + tempTo;
      this.chartLabelHoursPeriodB = 'HOURS: ' + tempFrom + ' | ' + tempTo;
      this.pieDataWorkersPeriodA = [];
      this.pieDataWorkersPeriodB = [];
      this.pieDataHoursPeriodA = [];
      this.pieDataHoursPeriodB = [];
      this.workerAtCropArray.forEach(rec => {
        this.pieDataWorkersPeriodA.push({ category: rec.cropName, value: rec.periodA.quantityAmount, percentage: rec.periodA.quantityPerc });
        this.pieDataWorkersPeriodB.push({ category: rec.cropName, value: rec.periodB.quantityAmount, percentage: rec.periodB.quantityPerc });
        this.pieDataHoursPeriodA.push({ category: rec.cropName, value: rec.periodA.hoursAmount, percentage: rec.periodA.hoursPerc });
        this.pieDataHoursPeriodB.push({ category: rec.cropName, value: rec.periodB.hoursAmount, percentage: rec.periodB.hoursPerc });
      });
      this.labelContent = this.labelContent.bind(this);
      this.excelExportData = this.excelExportData.bind(this);
    }
  }

  public dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    this.gridData = process(this.workerAtCropArray, this.state);
  }

  public excelExportData(): ExcelExportData {
    const excelState = this.state;
    const excelData = process(this.workerAtCropArray, {
      group: this.state.group,
      sort: this.state.sort,
      filter: this.state.filter
    });
    const result: ExcelExportData = {
      data: excelData.data,
      group: excelState.group
    };
    return result;
  }

  public labelContent(e: SeriesLabelsContentArgs): any {
    const myLabel = e.category + ': ' + e.value + ' / ' +  (e.percentage ? this.intl.formatNumber(e.percentage,'p2') : '');
    return myLabel;
  }

  public handleShowChartsValue(value: number) {
    this.selectedShowChartsValue = value;
  }

  public applyFilter() {

    // apply dateFrom and dateTo filters
    if ((this.dateFrom != undefined) &&  (this.dateTo != undefined)) {

      if (this.dateFrom > this.dateTo) {
        this.dateFrom = this.dateTo;
      }
      this._setGridData();
    }

    // apply filter assigned
    if (this.selectedShowChartsValue != undefined)  {
      this.showCharts = (this.selectedShowChartsValue === 1);
    }
  }


}
