import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from 'src/app/shared/models/user.model';
import { Worker } from 'src/app/shared/models/worker.model';
import { UsersService } from '../../users/users.service';
import { WorkersService } from '../workers.service';

@Component({
  selector: 'app-worker-detail',
  templateUrl: './worker-detail.component.html',
  styleUrls: ['./worker-detail.component.scss']
})
export class WorkerDetailComponent implements OnInit {

  public isSubPage: boolean;
  public pageName: string

  //currentUser
  public currentUser: User;

  //currentWorker
  public workerId: number;
  public worker: Worker;

  //parent general manager
  public generalManagerId: number;
  public generalManager: User;

  //parent crop manager
  public cropManagerId: number;
  public cropManager: User;

  //parent supervisor
  public supervisorId: number;
  public supervisor: User;

  // output
  public outputWorker: Worker;
  public outputGeneralManager: User;
  public outputCropManager: User;
  public outputSupervisor: User;

  //configuration variables
  public columns = 1;
  public currentResCode = "xl";

  public responsiveWrappers = {
      picture:
        {
          col: 1,
          colspan: 4,
          rowspan: 15
        },
      workerData:
        {
          col: 5,
          colspan: 4,
          rowspan: 15
        },
      assignedToSupervisor:
        {
          col: 9,
          colspan: 4,
          rowspan: 15
        },
      listOfActiveTasks:
        {
          col: 1,
          colspan: 12,
          rowspan: 18
        }
  };

  constructor(
    private _router: Router, private _route: ActivatedRoute,
    private _workersService: WorkersService, private _usersService: UsersService
  ) {
    if (window.innerWidth <= 1280) {
      this.currentResCode = "xl";
    }
    if (window.innerWidth <= 1024) {
      this.currentResCode = "l";
      this.responsiveWrappers = {
        picture:
          {
            col: 1,
            colspan: 6,
            rowspan: 15
          },
        workerData:
          {
            col: 7,
            colspan: 6,
            rowspan: 15
          },
        assignedToSupervisor:
          {
            col: 1,
            colspan: 6,
            rowspan: 15
          },
        listOfActiveTasks:
          {
            col: 1,
            colspan: 12,
            rowspan: 18
          }
      };
    }
    if (window.innerWidth <= 768) {
      this.currentResCode = "m";
      this.responsiveWrappers = {
        picture:
          {
            col: 1,
            colspan: 12,
            rowspan: 15
          },
        workerData:
          {
            col: 1,
            colspan: 12,
            rowspan: 13
          },
        assignedToSupervisor:
          {
            col: 1,
            colspan: 12,
            rowspan: 15
          },
        listOfActiveTasks:
          {
            col: 1,
            colspan: 12,
            rowspan: 18
          }
      };
    }
    if (window.innerWidth <= 667) {
      this.currentResCode = "s";
    }
    if (window.innerWidth <= 447) {
      this.currentResCode = "xs";

    }
  }

  ngOnInit(): void {

    const lsLoggedUser = localStorage.getItem('loggedUser')
    if (lsLoggedUser) {
      this.currentUser = JSON.parse(lsLoggedUser);
    }
    this.pageName = this._route.snapshot.url[2].path;
    if (this.pageName === 'dashboard') {
      this._route.queryParams
      .subscribe(params => {
        this.workerId = params['workerId'];
        this.generalManagerId = params['generalManagerId'];
        this.cropManagerId = params['cropManagerId'];
        this.supervisorId = params['supervisorId'];
        if (this.workerId) {
          this._workersService.getWorkerById(this.workerId).subscribe(worker => {
            this.worker = worker;
            this.outputWorker = worker;
            this.isSubPage = true;
          });
        }
        if (this.generalManagerId) {
          this._usersService.getUserById(this.generalManagerId).subscribe(user => {
            this.generalManager = user;
            this.outputGeneralManager = user;
          });
        }
        if (this.cropManagerId) {
          this._usersService.getUserById(this.cropManagerId).subscribe(user => {
            this.cropManager = user;
            this.outputCropManager = user;
          });
        }
        if (this.supervisorId) {
          this._usersService.getUserById(this.supervisorId).subscribe(user => {
            this.supervisor = user;
            this.outputSupervisor = user;
          });
        }
      });
    }

    if (window.innerWidth <= 768) {
      this.columns = 1;
    } else {
      this.columns = 3;
    }
  }

  public goToButtonOnClick(pageCode: string): void {
    if (pageCode === 'list') {
      this._router.navigate(['/staff/workers/list']);
    }
    if (pageCode === 'deletedList') {
      this._router.navigate(['/staff/workers/deleted']);
    }
    if (pageCode === 'supervisorDashboard') {
      this._router.navigate(['staff/supervisors/dashboard'],{ queryParams: { generalManagerId: this.generalManagerId, cropManagerId: this.cropManagerId, supervisorId: this.supervisorId} });
    }
    if (pageCode === 'supervisorsAssignment') {
      this._router.navigate(['staff/workers/assignmentssupervisors'],{ queryParams: { generalManagerId: this.generalManagerId, cropManagerId: this.cropManagerId, supervisorId: this.supervisorId, workerId: this.workerId} });
    }
    if (pageCode === 'pictureCompare') {
      this._router.navigate(['staff/workers/picturecompare'],{ queryParams: { generalManagerId: this.generalManagerId, cropManagerId: this.cropManagerId, supervisorId: this.supervisorId, workerId: this.workerId} });
    }
  }

}
