import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Menu } from './models/menu.model';
import { TextString } from './models/textString.model';
import { UnitFarm } from './models/unit-farm.model';
import { User } from './models/user.model';
import { UserSetting } from './models/userSetting.model';

enum EndpointRoutes {
  GET_LOGGED_USER = 'user/get',
  GET_MENUS = 'menus/get',
  GET_STRINGS = 'strings/get',
  GET_USER_SETTINGS ='userSettings/get',
  GET_UNITFARMS ='unitFarms/get',
  GET_CAMPS ='camps/get'
}

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  constructor(private _http: HttpClient) { }

  public getLoggedUserById(userId: number): Observable<User> {
    let params = new HttpParams();
    params = params.append('id', userId.toString());
    return this._http.get<User>(environment.serverUrl + EndpointRoutes.GET_LOGGED_USER, { params: params }).pipe(
      map(responseData => {
        return responseData;
      }),
      catchError(errorRes => {
        return throwError(() => errorRes);
      })
    );
  }

  public getMenus(): Observable<Menu[]> {
    return this._http.get<Menu[]>(environment.serverUrl + EndpointRoutes.GET_MENUS).pipe(
      map(responseData => {
        return responseData;
      }),
      catchError(errorRes => {
        return throwError(() => errorRes);
      })
    );
  }

  public getStrings(): Observable<TextString[]> {
    return this._http.get<TextString[]>(environment.serverUrl + EndpointRoutes.GET_STRINGS).pipe(
      map(responseData => {
        return responseData;
      }),
      catchError(errorRes => {
        return throwError(() => errorRes);
      })
    );
  }

  public getUserSettingsById(userId: number): Observable<UserSetting[]> {
    let params = new HttpParams();
    params = params.append('userId', userId.toString());
    return this._http.get<UserSetting[]>(environment.serverUrl + EndpointRoutes.GET_USER_SETTINGS, { params: params }).pipe(
      map(responseData => {
        return responseData;
      }),
      catchError(errorRes => {
        return throwError(() => errorRes);
      })
    );
  }

  public getUnitFarms(): Observable<UnitFarm[]> {
    return this._http.get<UnitFarm[]>(environment.serverUrl + EndpointRoutes.GET_UNITFARMS).pipe(
      map(responseData => {
        return responseData;
      }),
      catchError(errorRes => {
        return throwError(() => errorRes);
      })
    );
  }

  public getCamps(): Observable<UnitFarm[]> {
    return this._http.get<UnitFarm[]>(environment.serverUrl + EndpointRoutes.GET_CAMPS).pipe(
      map(responseData => {
        return responseData;
      }),
      catchError(errorRes => {
        return throwError(() => errorRes);
      })
    );
  }


  /*normal functions*/
  public getGenderText(genderValue: number): string{
    let genderText;
    switch (genderValue) {
      case 1:
        genderText = 'Male';
        break;
      case 2:
        genderText = 'Female';
        break;
      default:
        genderText = '';
        break
    }
    return genderText;
  }

  public replaceAll(str:string, find:string, replace:string) {
    var escapedFind=find.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
    return str.replace(new RegExp(escapedFind, 'g'), replace);
  }

}
