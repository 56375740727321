<div class="statistic-page main-content">
  <div class="button-bar" style="width:100%"> Report - Compare pictures</div>
  <div class="card-container">
    <kendo-tilelayout class="tile" [columns]="12" [rowHeight]="10">
      <kendo-tilelayout-item [col]="1" [colSpan]="12" [rowSpan]="3" >
        <div class="search-bar" fxLayout="row" fxLayoutAlign="none" fxLayoutGap="30">
          <div class="search-field" fxLayout="row" fxLayoutAlign="none" fxLayoutGap="20">
            <span class="k-label"><b>From</b></span>
            <kendo-datepicker
              name="dateFrom"
              [(value)]="dateFrom"
              [max]="dateFromMax"
              [placeholder]="''"
              [style]="'width:150px'"
              [format]="'dd-MM-yyyy'"
            ></kendo-datepicker>
          </div>
          <div class="search-field" fxLayout="row" fxLayoutAlign=" none" fxLayoutGap="10">
            <span class="k-label"><b>To</b></span>
            <kendo-datepicker
              name="dateTo"
              [(value)]="dateTo"
              [max]="dateToMax"
              [placeholder]="''"
              [style]="'width:150px'"
              [format]="'dd-MM-yyyy'"
            ></kendo-datepicker>
          </div>
          <div class="search-field" fxLayout="row" fxLayoutAlign=" none" fxLayoutGap="10">
            <span class="k-label"><b>Supervisors</b></span>
            <kendo-combobox #supervisorId
              [data]="supervisorsArray"
              textField="text"
              valueField="value"
              [filterable]="true"
              (filterChange)="handleSupervisorFilter($event)"
              (selectionChange)="handleSupervisorSelection($event)"
              placeholder="All supervisors"
              [valuePrimitive]="true"
              [(ngModel)]="selectedSupervisorId"
              [style]="'width:200px'"
            ></kendo-combobox>
          </div>
          <div class="search-field" fxLayout="row" fxLayoutAlign=" none" fxLayoutGap="10">
            <span class="k-label"><b>Unit Farms</b></span>
            <kendo-combobox #unitFarmId
                            [data]="unitFarmsArray"
                            textField="text"
                            valueField="value"
                            [filterable]="true"
                            (filterChange)="handleUnitFarmFilter($event)"
                            (selectionChange)="handleUnitFarmSelection($event)"
                            placeholder="All unit farms"
                            [valuePrimitive]="true"
                            [(ngModel)]="selectedUnitFarmId"
                            [style]="'width:180px'"
            ></kendo-combobox>
          </div>
          <div class="search-field" fxLayout="row" fxLayoutAlign=" none" fxLayoutGap="10">
            <button kendoButton themeColor="primary" icon="search" (click)="applyFilter()">Search</button>
          </div>
        </div>
      </kendo-tilelayout-item>
      <!-- Own data -->
      <kendo-tilelayout-item [col]="1" [colSpan]="12" [rowSpan]="30" >
        <!-- grid -->
        <kendo-grid
          [kendoGridBinding]="comparePicturesArray"
          (dataStateChange)="dataStateChange($event)"
          style="max-height: 100%; margin-top: 10px !important"
          [sortable]="sortSettings"
          [groupable]="true"
          [group]="group"
          [columnMenu]="{ filter: true }"
          [autoSize]="true"
        >
          <kendo-grid-column-group
            title="Workers"
            [locked]="false"
            [headerClass]="'gridcolumn header group-header'"
          >
            <ng-template kendoGridHeaderTemplate let-column="column">
              Workers
            </ng-template>
            <kendo-grid-column
              field="worker.name"
              title="name"
              filter="text"
              [headerClass]="'gridcolumn header'"
              [width]="200"
            >
            </kendo-grid-column>
          </kendo-grid-column-group>
          <kendo-grid-column-group
            title="Reporters"
            [locked]="false"
            [headerClass]="'gridcolumn header group-header'"
          >
            <ng-template kendoGridHeaderTemplate let-column="column">
              Reporters
            </ng-template>
            <kendo-grid-column
              field="reporter.name"
              title="name"
              filter="text"
              [headerClass]="'gridcolumn header'"
              [width]="200"
            >
            </kendo-grid-column>
          </kendo-grid-column-group>
          <kendo-grid-column
            field="reportedDate"
            title="reported date"
            filter="text"
            [headerClass]="'gridcolumn header'"
            [width]="100"
          >
          </kendo-grid-column>
          <kendo-grid-column
            field="reportedTime"
            title="reported time"
            filter="text"
            [headerClass]="'gridcolumn header'"
            [width]="100"
          >
          </kendo-grid-column>
          <kendo-grid-column
            field="comment"
            title="comment"
            filter="text"
            [headerClass]="'gridcolumn header'"
            [width]="200"
          >
          </kendo-grid-column>
          <kendo-grid-column
            [headerClass]="{ 'text-center': true }"
            [class]="{ 'text-center': true }"
            [columnMenu]="false"
            [minResizableWidth]="225"
            [width]="225"
          >
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
              <button kendoButton themeColor="primary" icon="eye" (click)="onButtonDetailClick(dataItem)">Detail</button>
              &nbsp;
              <button kendoButton themeColor="secondary" icon="mirror" (click)="onButtonCompareClick(dataItem)">Compare</button>
            </ng-template>
          </kendo-grid-column>
        </kendo-grid>
      </kendo-tilelayout-item>
    </kendo-tilelayout>
  </div>
</div>
<div #dialogContainer></div>
