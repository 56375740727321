<kendo-grid
  [data]="gridData"
  [pageSize]="state.take"
  [skip]="state.skip"
  [sort]="state.sort"
  [filter]="state.filter"
  [group]="state.group"
  [sortable]="sortingSettings"
  [pageable]="{
    info: pagingSettings.info,
    type: pagingSettings.pagerTypes,
    pageSizes: pagingSettings.pageSizes,
    previousNext: pagingSettings.previousNext,
    position: pagingSettings.position,
    responsive: pagingSettings.responsive
  }"
  [reorderable]="!hiddenForMobile"
  [resizable]="!hiddenForMobile"
  filterable="menu"
  [columnMenu]="{ filter: true }"
  (dataStateChange)="dataStateChange($event)"
  style="max-height: 800px; margin-top: 10px !important"
>
  <ng-template kendoGridToolbarTemplate class="grid-toolbar">
    <div style="display:flex; width:100%;">
      <div class="maintitle"><h2 style="margin-top:0px">Crop managers</h2></div>
      <kendo-grid-spacer></kendo-grid-spacer>
      <div class="commands-bar">
        <div class="search-bar" style="display:inline-block; width:300px;">
          <input
            [style.width.px]="300"
            placeholder="Search in all columns..."
            kendoTextBox
            (input)="onFilter($event)"
          />
        </div>
        &nbsp;
        <button kendoButton [primary]="true" icon="plus-circle" (click)="onInsertButtonClick()">Insert</button
        >
        <button kendoGridExcelCommand type="button" icon="file-excel" *ngIf="!hiddenForMobile">
          Export Excel</button
        >
      </div>
    </div>
  </ng-template>
  <kendo-grid-column *ngIf="hiddenForMobile"
    title="Crop manager"
    [columnMenu]="false"
    [headerClass]="'gridcolumn header'"
  >
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <span class="responsive-row"><b>{{dataItem.firstName}} {{dataItem.lastName}}</b></span>
        <span class="responsive-row"><b>Id Card:</b> {{dataItem.code}}</span>
        <span class="responsive-row"><b>Gender:</b> {{dataItem.genderText}}</span>
        <span class="responsive-row"><b>Email:</b> {{dataItem.email}}</span>
        <span class="responsive-row"><b>Mobile:</b> {{dataItem.mobile}}</span>
        <div class="responsive-buttonrow">
          <kendo-splitbutton
            [data]="splitButtonItems"
            (itemClick)="onSplitButtonItemClick($event, dataItem)">
            Actions
          </kendo-splitbutton>
        </div>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column *ngIf="!hiddenForMobile"
    field="firstName"
    title="First Name"
    filter="text"
    [headerClass]="'gridcolumn header'"
  >
  </kendo-grid-column>
  <kendo-grid-column *ngIf="!hiddenForMobile"
    field="lastName"
    title="Last Name"
    filter="text"
    [headerClass]="'gridcolumn header'"
  >
  </kendo-grid-column>
  <kendo-grid-column *ngIf="!hiddenForMobile"
    field="code"
    title="Id Card"
    filter="text"
    [headerClass]="'gridcolumn header'"
  >
  </kendo-grid-column>
  <kendo-grid-column *ngIf="!hiddenForMobile"
    field="genderText"
    title="Gender"
    filter="text"
    [headerClass]="'gridcolumn header'"
    [hidden]="true"
  >
  </kendo-grid-column>
  <kendo-grid-column *ngIf="!hiddenForMobile"
    field="email"
    title="Email"
    filter="text"
    [headerClass]="'gridcolumn header'"
  >
  </kendo-grid-column>
  <kendo-grid-column *ngIf="!hiddenForMobile"
    field="mobile"
    title="Mobile"
    filter="text"
    [headerClass]="'gridcolumn header'"
  >
  </kendo-grid-column>
  <kendo-grid-column *ngIf="!hiddenForMobile"
    [headerClass]="{ 'text-center': true }"
    [class]="{ 'text-center': true }"
    [columnMenu]="false"
    [width]="150"
  >
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
      <kendo-splitbutton
        [data]="splitButtonItems"
        (itemClick)="onSplitButtonItemClick($event, dataItem)"
      >
        Actions
      </kendo-splitbutton>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-excel fileName="Users.xlsx" [fetchData]="excelExportData">
  </kendo-grid-excel>
</kendo-grid>
