
<div class="data-row" fxLayout="row" fxLayoutAlign="none">
  <div class="label">Code</div>
  <div class="input">{{workerData.code}}</div>
</div>
<div class="data-row" fxLayout="row" fxLayoutAlign="none">
  <div class="label">First name</div>
  <div class="input">{{workerData.firstName}}</div>
</div>
<div class="data-row"  fxLayoutAlign="none">
  <div class="label">Last name</div>
  <div class="input">{{workerData.lastName}}</div>
</div>
<div class="data-row" fxLayout="row" fxLayoutAlign="none">
  <div class="label">Gender</div>
  <div class="input">{{getGenderText(workerData.gender)}}</div>
</div>
<div class="data-row" fxLayout="row" fxLayoutAlign="none">
  <div class="label">Email</div>
  <div class="input"><a *ngIf="workerData.email" href="mailto:{{workerData.email}}" target="_blank">{{workerData.email}}</a></div>
</div>
<div class="data-row" fxLayout="row" fxLayoutAlign="none">
  <div class="label">Mobile</div>
  <div class="input"><a  *ngIf="workerData.mobile" href="tel:{{workerData.mobile}}" target="_blank">{{workerData.mobile}}</a></div>
</div>
<div class="data-row" fxLayout="row" fxLayoutAlign="none" *ngIf="showCost">
  <div class="label">Cost</div>
  <div class="input">{{workerData.cost}}</div>
</div>
<div class="data-row" fxLayout="row" fxLayoutAlign="none" *ngIf="showNfcTag">
  <div class="label">Nfc tag</div>
  <div class="input">{{workerData.nfcTag}}</div>
</div>
<div class="button-row" *ngIf="showEditButton || showDashboardButton">
  <button *ngIf="showEditButton" kendoButton [primary]="false" [icon]="'edit'" (click)="goToEditPage()">Detail</button>
</div>
