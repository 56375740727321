import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LoginService } from 'src/app/login/login.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { User } from 'src/app/shared/models/user.model';


@Component({
  selector: 'app-main-toolbar',
  templateUrl: './main-toolbar.component.html',
  styleUrls: ['./main-toolbar.component.scss']
})
export class MainToolbarComponent implements OnInit {

  @Output() public toggle = new EventEmitter();
  @Input() public selectedPage?: string;

  public currentUser: User;
  public menuIconName = 'menu';

  constructor(private _loginService: LoginService, public translateService: TranslateService, private _router: Router) { }

  ngOnInit(): void {
    const lsLoggedUser = localStorage.getItem('loggedUser')
    if (lsLoggedUser) {
      this.currentUser = JSON.parse(lsLoggedUser);
    }
  }

  onToggleSidenav() {
    this.toggle.emit();
    this.menuIconName = (this.menuIconName === 'menu') ? 'close' : 'menu';
  }

  doUserLogout() {
    this._loginService.logout();
  }

  viewProfile() {
    this._router.navigate(['/settings/user/profile']);
  }

}
