<div class="profile-page main-content" fxLayout="column" fxLayoutAlign="top center">
  <div class="button-bar" style="max-width: 1024px; width:90vw;">
    <button kendoButton themeColor="primary" fillMode="outline" icon="chevron-left" (click)="goToButtonOnClick('list')">All general managers</button>&nbsp;
    <button *ngIf="pageName!=='insert' && user" kendoButton themeColor="primary" fillMode="outline" icon="chevron-left" (click)="goToButtonOnClick('dashboard')">Dashboard of general manager <b>{{user.firstName.value}} {{user.lastName.value}}</b></button>&nbsp;
  </div>
  <div class="card-container" fxLayout="column" fxLayoutAlign="top center">
      <kendo-card style="max-width: 1024px; width:90vw; padding:30px; padding-top:0px;">
          <form class="k-form k-form-horizontal" [formGroup]="formGroup">
              <fieldset class="k-form-fieldset">
                  <div class="form-title">
                      <h2>{{mainTitle}}</h2>
                  </div>
                  <div class="row form-section">
                    <div class="row from-row">
                      <div class="col-12 section-title no-border">
                          <h3>Personal data</h3>
                      </div>
                    </div>
                    <div class="row form-row">
                        <div class="col-6 col-md-12">
                            <kendo-formfield orientation="horizontal">
                                <kendo-label class="k-label" [for]="firstName" [text]="'First Name'"> </kendo-label>
                                <kendo-textbox formControlName="firstName" [clearButton]="true" #firstName> </kendo-textbox>
                                <kendo-formerror *ngIf="formGroup.controls['firstName'].hasError('required')"> <i> Error: This field is required </i></kendo-formerror>
                                <kendo-formerror *ngIf="formGroup.controls['firstName'].hasError('maxlength')"> <i> Error: The max allowed length is {{ user.firstName.maxLength }} characters </i></kendo-formerror>
                            </kendo-formfield>
                        </div>
                        <div class="col-6 col-md-12">
                            <kendo-formfield orientation="horizontal">
                                <kendo-label class="k-label" [for]="lastName" [text]="'Last Name'"> </kendo-label>
                                <kendo-textbox formControlName="lastName" [clearButton]="true" #lastName> </kendo-textbox>
                                <kendo-formerror *ngIf="formGroup.controls['lastName'].hasError('required')"> <i> Error: This field is required </i></kendo-formerror>
                                <kendo-formerror *ngIf="formGroup.controls['lastName'].hasError('maxlength')"> <i> Error: The max allowed length is {{ user.lastName.maxLength }} characters </i></kendo-formerror>
                            </kendo-formfield>
                        </div>
                    </div>
                    <div class="row form-row">
                      <div class="col-6 col-md-12">
                          <kendo-formfield orientation="horizontal">
                              <kendo-label class="k-label" [for]="address" [text]="'Address'"> </kendo-label>
                              <kendo-textbox formControlName="address" [clearButton]="true" #address> </kendo-textbox>
                              <kendo-formerror *ngIf="formGroup.controls['address'].hasError('maxlength')"> <i> Error: The max allowed length is {{ user.address.maxLength }} characters </i></kendo-formerror>
                          </kendo-formfield>
                      </div>
                      <div class="col-6 col-md-12">
                        <kendo-formfield orientation="horizontal">
                          <kendo-label class="k-label" [for]="code" [text]="'Code'"> </kendo-label>
                          <kendo-textbox formControlName="code" [clearButton]="true" #code> </kendo-textbox>
                          <kendo-formerror *ngIf="formGroup.controls['code'].hasError('maxlength')"> <i> Error: The max allowed length is {{ user.code.maxLength }} characters </i></kendo-formerror>
                        </kendo-formfield>
                      </div>
                    </div>
                    <div class="row form-row">
                        <div class="col-6 col-md-12">
                            <kendo-formfield orientation="horizontal">
                                <kendo-label class="k-label" [for]="email" [text]="'E-mail'"> </kendo-label>
                                <kendo-textbox formControlName="email" [clearButton]="true" #email> </kendo-textbox>
                                <kendo-formerror *ngIf="formGroup.controls['email'].hasError('maxlength')"> <i> Error: The max allowed length is {{ user.firstName.maxLength }} characters </i></kendo-formerror>
                                <kendo-formerror *ngIf="formGroup.controls['email'].hasError('email')"> <i>Error: Please enter a valid email</i></kendo-formerror>
                            </kendo-formfield>
                        </div>
                        <div class="col-6 col-md-12">
                            <kendo-formfield orientation="horizontal">
                              <kendo-label class="k-label" [for]="mobile" [text]="'Mobile'"> </kendo-label>
                              <kendo-textbox formControlName="mobile" [clearButton]="true" #mobile> </kendo-textbox>
                              <kendo-formerror *ngIf="formGroup.controls['mobile'].hasError('maxlength')"> <i> Error: The max allowed length is {{ user.mobile.maxLength }} characters </i></kendo-formerror>
                            </kendo-formfield>
                        </div>
                    </div>
                    <div class="row form-row">
                        <div class="col-6 col-md-12">
                            <kendo-formfield orientation="horizontal">
                              <label class="k-label"> Gender </label>
                              <ul class="k-radio-list k-list-horizontal">
                                  <li class="k-radio-item">
                                      <input type="radio" #genderMale [value]="1" kendoRadioButton formControlName="gender" />
                                      <kendo-label class="k-radio-label" [for]="genderMale" text="Male"> </kendo-label>
                                  </li>
                                  <li class="k-radio-item">
                                      <input type="radio" #genderFemale [value]="2" kendoRadioButton formControlName="gender" />
                                      <kendo-label class="k-radio-label" [for]="genderFemale" text="Female"> </kendo-label>
                                  </li>
                              </ul>
                            </kendo-formfield>
                        </div>
                        <div class="col-6 col-md-12">
                          <kendo-formfield orientation="horizontal">
                              <kendo-label class="k-label" [for]="dateOfBirth" [text]="'Date of birth'"> </kendo-label>
                              <kendo-datepicker formControlName="dateOfBirth" format="dd-MM-yyyy" #dateOfBirth> </kendo-datepicker>
                          </kendo-formfield>
                        </div>
                    </div>
                  </div>
                  <div class="form-section">
                    <div class="row from-row">
                      <div class="col-12 section-title">
                          <h3>Employment data</h3>
                      </div>
                    </div>
                    <div class="row form-row">
                      <div class="col-6 col-md-12">
                        <kendo-formfield orientation="horizontal">
                            <kendo-label class="k-label" [for]="workerTypeId" [text]="'Worker type'"> </kendo-label>
                            <kendo-combobox formControlName="workerTypeId" [data]="workerTypesArray" textField="text" valueField="value" [filterable]="true" (filterChange)="handleWorkerTypeFilter($event)" placeholder="Worker type" #workerTypeId></kendo-combobox>
                            <kendo-formerror *ngIf="formGroup.controls['workerTypeId'].hasError('required')"> <i> Error: This field is required </i></kendo-formerror>
                        </kendo-formfield>
                      </div>
                      <div class="col-6 col-md-12">
                        <kendo-formfield orientation="horizontal">
                            <kendo-label class="k-label" [for]="jobTitle" [text]="'Job title'"> </kendo-label>
                            <kendo-textbox formControlName="jobTitle" [clearButton]="true" #jobTitle> </kendo-textbox>
                        </kendo-formfield>
                      </div>
                    </div>
                    <div class="row form-row">
                      <div class="col-6 col-md-12">
                        <kendo-formfield orientation="horizontal">
                            <kendo-label class="k-label" [for]="issueDate" [text]="'Issue date'"> </kendo-label>
                            <kendo-datepicker formControlName="issueDate" format="dd-MM-yyyy" #issueDate> </kendo-datepicker>
                        </kendo-formfield>
                      </div>
                      <div class="col-6 col-md-12"></div>
                    </div>
                    <div class="row form-row">
                      <div class="col-6 col-md-12">
                        <kendo-formfield orientation="horizontal">
                            <kendo-label class="k-label" [for]="employmentDate" [text]="'Employment date'"> </kendo-label>
                            <kendo-datepicker formControlName="employmentDate" format="dd-MM-yyyy" #employmentDate> </kendo-datepicker>
                        </kendo-formfield>
                      </div>
                      <div class="col-6 col-md-12">
                        <kendo-formfield orientation="horizontal">
                            <kendo-label class="k-label" [for]="expiryDate" [text]="'Expiry date'"> </kendo-label>
                            <kendo-datepicker formControlName="expiryDate" format="dd-MM-yyyy" #expiryDate> </kendo-datepicker>
                        </kendo-formfield>
                      </div>
                    </div>
                  </div>
                  <div class="form-section">
                    <div class="row from-row">
                      <div class="col-12 section-title">
                          <h3>Account data</h3>
                      </div>
                    </div>
                    <div class="row form-row">
                      <div class="col-6 col-md-12">
                          <kendo-formfield orientation="horizontal">
                              <kendo-label class="k-label" [for]="username" [text]="'Username'"> </kendo-label>
                              <kendo-textbox formControlName="username" [clearButton]="true" #username> </kendo-textbox>
                              <kendo-formerror *ngIf="formGroup.controls['username'].hasError('required')"> <i> Error: This field is required </i></kendo-formerror>
                              <kendo-formerror *ngIf="formGroup.controls['username'].hasError('maxlength')"> <i> Error: The max allowed length is {{ user.username.maxLength }} characters </i></kendo-formerror>
                          </kendo-formfield>
                      </div>
                      <div class="col-6 col-md-12">
                          <kendo-formfield orientation="horizontal">
                              <kendo-label class="k-label" [for]="password" text="Password"> </kendo-label>
                              <kendo-textbox formControlName="password" [clearButton]="true" #password >
                                <ng-template kendoTextBoxSuffixTemplate *ngIf="pageName==='insert'">
                                  <button
                                    kendoButton
                                    look="clear"
                                    icon="eye"
                                    (click)="toggleVisibility()"
                                  ></button>
                                </ng-template>
                              </kendo-textbox>
                              <kendo-formerror *ngIf="formGroup.controls['password'].hasError('required')"> <i> Error: This field is required </i></kendo-formerror>
                              <kendo-formerror *ngIf="formGroup.controls['password'].hasError('maxlength')"> <i> Error: The max allowed length is {{ user.password.maxLength }} characters </i></kendo-formerror>
                          </kendo-formfield>
                      </div>
                    </div>
                </div>
                <div class="buttons-section">
                    <div class="row">
                        <div class="col-12 align right">
                            <button kendoButton (click)="cancelChanges()">
                                Reset
                            </button>
                            <button kendoButton [primary]="true" (click)="saveChanges()">
                                Save
                            </button>
                        </div>
                    </div>
                </div>
              </fieldset>
          </form>
      </kendo-card>
  </div>
</div>
