import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorageService } from '../shared/local-storage.service';
import { Menu, MenuChild } from '../shared/models/menu.model';
import { User } from '../shared/models/user.model';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {

  //configuration variables
  public dashboardType = "menu";
  public menus: Menu[];
  public columns = 0;

  public currentUser: User;

  constructor(private _router: Router, private _localStorageService: LocalStorageService) {
  }

  ngOnInit(): void {

    const lsLoggedUser = localStorage.getItem('loggedUser')
    if (lsLoggedUser) {
      this.currentUser = JSON.parse(lsLoggedUser);
    }

    this.menus = this._localStorageService.getActualMenuLs(this.currentUser.userTypeId, true);
    // console.log('myMenu:', this.menus);
    if (window.innerWidth <= 768) {
      this.columns = 1;
    } else {
      this.columns = this.menus.length;
    }
    /* old code
    this._sharedService.getMenus().subscribe(result => {
      this.menus = result;
      this.menus.forEach((menuL1: Menu, indexL1: number)=>{
        menuL1.hidden = false;
        if (menuL1.code === 'dashboard') {
          this.menus.splice(indexL1,1);
        }
        if (menuL1.children) {
          menuL1.children.forEach((menuL2: MenuChild) => {
            let toDelete = false;
            switch(this.currentUser.userTypeId) {
              case 2: //crop manager
                if (menuL2.code === 'generalManagers') {
                  toDelete = true
                }
                if (menuL2.code === 'cropManagers') {
                  toDelete = true;
                }
                break;
              case 3: //supervisor
                if (menuL2.code === 'generalManagers') {
                  toDelete = true;
                }
                if (menuL2.code === 'cropManagers') {
                  toDelete = true;
                }
                if (menuL2.code === 'supervisors') {
                  toDelete = true;
                }
                break;
            }
            menuL2.hidden = false;
            if (toDelete) {
              menuL2.hidden = true;
            }
          });
        }
      });
    });
    */
  }

  public onButtonClick(menuChild: MenuChild): void {
    if (menuChild.path) {
      const redirectUrl = menuChild.path ? menuChild.path : '/home';
      this._router.navigate([redirectUrl]);
    }

  }

}

