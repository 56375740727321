import { Camp } from "./camp-model";
import { UnitFarm } from "./unit-farm.model";

type cString = {
  name: string;
  value?: string | null;
  defaultValue: string | null;
  isNullable: boolean;
  maxLength: number;
};

type cNumber = {
  name: string;
  value?: number | null;
  defaultValue: number | null;
  isNullable: boolean;
};

export class Worker {

    id: number;
    status: number;
    firstName: string;
    lastName: string;
    gender: number;
    genderText: string;
    email: string;
    mobile: string;
    dateOfBirth: string;
    cost: number;
    nfcTag: string;
    code: string;
    motherName: string;
    grandfatherName: string;
    jobTitle: string;
    address: string;
    city: string;
    agencyName: string;
    workerTypeId: number;
    workerType: WorkerType;
    unitFarmId: number;
    unitFarm: UnitFarm;
    campId: number;
    camp: Camp;
    supervisorId: number;
    path: string;
    userTypeId: number;
    workerCardDateId: number;
    issueDate: string;
    expiryDate: string;
    createdDate: string;
    employmentDate: string;
    deletedAt: string;
    deletedReason: string;
    printedDateTime: string;
}

export class WorkerDb {
  public id: cNumber = {
    name: 'id',
    defaultValue: 0,
    isNullable: false,
  };

  public status: cNumber = {
    name: 'status',
    defaultValue: 0,
    isNullable: false,
  }

  public firstName: cString = {
    name: 'firstName',
    defaultValue: '',
    isNullable: false,
    maxLength: 255,
  };

  public lastName: cString = {
    name: 'lastName',
    defaultValue: '',
    isNullable: false,
    maxLength: 255,
  };

  public gender: cNumber = {
    name: 'gender',
    defaultValue: 0, //0 = Not specified, 1 = Male, 2 = Female
    isNullable: false,
  };

  public genderText: cString = {
    name: 'genderText',
    defaultValue: '',
    isNullable: true,
    maxLength: 255,
  };

  public email: cString = {
    name: 'email',
    defaultValue: '',
    isNullable: true,
    maxLength: 255,
  };

  public mobile: cString = {
    name: 'mobile',
    defaultValue: '',
    isNullable: true,
    maxLength: 255,
  };

  public dateOfBirth: cString = {
    name: 'dateOfBirth',
    defaultValue: '',
    isNullable: true,
    maxLength: 10,
  };

  public cost: cNumber = {
    name: 'cost',
    defaultValue: 0,
    isNullable: false,
  };

  public nfcTag: cString = {
    name: 'nfcTag',
    value: '',
    defaultValue: null,
    isNullable: true,
    maxLength: 255,
  };

  public code: cString = {
    name: 'code',
    value: '',
    defaultValue: null,
    isNullable: true,
    maxLength: 255,
  };

  public motherName: cString = {
    name: 'motherName',
    value: '',
    defaultValue: null,
    isNullable: true,
    maxLength: 255,
  };

  public grandfatherName: cString = {
    name: 'grandfatherName',
    value: '',
    defaultValue: null,
    isNullable: true,
    maxLength: 255,
  };

  public jobTitle: cString = {
    name: 'jobTitle',
    value: '',
    defaultValue: null,
    isNullable: true,
    maxLength: 255,
  };

  public address: cString = {
    name: 'address',
    value: '',
    defaultValue: null,
    isNullable: true,
    maxLength: 512,
  };

  public city: cString = {
    name: 'city',
    value: '',
    defaultValue: null,
    isNullable: true,
    maxLength: 255,
  };

  public agencyName: cString = {
    name: 'agencyName',
    value: '',
    defaultValue: null,
    isNullable: true,
    maxLength: 255,
  };

  public workerTypeId: cNumber = {
    name: 'workerTypeId',
    defaultValue: null,
    isNullable: true,
  };

  public unitFarmId: cNumber = {
    name: 'unitFarmId',
    defaultValue: null,
    isNullable: true,
  };

  public campId: cNumber = {
    name: 'campId',
    defaultValue: null,
    isNullable: true,
  };

  public supervisorId: cNumber = {
    name: 'supervisorId',
    defaultValue: null,
    isNullable: true,
  };

  public path: cString = {
    name: 'path',
    value: '',
    defaultValue: null,
    isNullable: true,
    maxLength: 255,
  };

  public userTypeId: cNumber = {
    name: 'userTypeId',
    defaultValue: null,
    isNullable: true,
  };

  public workerCardDateId: cNumber = {
    name: 'workerCardDateId',
    defaultValue: null,
    isNullable: true,
  };

  public issueDate: cString = {
    name: 'issueDate',
    value: null,
    defaultValue: null,
    isNullable: true,
    maxLength: 10,
  };

  public expiryDate: cString = {
    name: 'expiryDate',
    value: null,
    defaultValue: null,
    isNullable: true,
    maxLength: 10,
  };

  public employmentDate: cString = {
    name: 'employmentDate',
    value: null,
    defaultValue: null,
    isNullable: true,
    maxLength: 10,
  };

  public createdDate: cString = {
    name: 'createdDate',
    value: null,
    defaultValue: null,
    isNullable: true,
    maxLength: 10,
  };

  public printedDateTime: cString = {
    name: 'printedDateTime',
    value: null,
    defaultValue: null,
    isNullable: true,
    maxLength: 20,
  };

  constructor(

    idValue: number,
    statusValue: number,
    firstNameValue: string,
    lastNameValue: string,
    genderValue: number,
    costValue: number,
    emailValue?: string,
    mobileValue?: string,
    nfcTagValue?: string,
    codeValue? :string,
    motherNameValue ?: string,
    grandfatherNameValue ?: string,
    jobTitleValue? :string,
    addressValue? :string,
    cityValue? :string,
    agencyNameValue?: string,
    dateOfBirthValue?: string,
    workerTypeIdValue? :number,
    unitFarmIdValue? :number,
    campIdValue?: number,
    supervisorIdValue?: number,
    pathValue? :string,
    userTypeIdValue?: number,
    workerCardDateIdValue? :number,
    issueDateValue? :string,
    expiryDateValue? :string,
    employmentDateValue? :string,
    createdDateValue? :string,
    printedDateTimeValue? :string,

  ) {
    this.id.value = idValue;
    this.status.value = statusValue;
    this.firstName.value = firstNameValue;
    this.lastName.value = lastNameValue;
    this.gender.value = genderValue;
    this.email.value = emailValue;
    this.mobile.value = mobileValue;
    this.cost.value = costValue;
    this.nfcTag.value = nfcTagValue;
    this.code.value = codeValue;
    this.motherName.value = motherNameValue;
    this.grandfatherName.value = grandfatherNameValue;
    this.jobTitle.value = jobTitleValue;
    this.address.value = addressValue;
    this.city.value = cityValue;
    this.agencyName.value = agencyNameValue;
    this.dateOfBirth.value = dateOfBirthValue;
    this.workerTypeId.value = workerTypeIdValue;
    this.unitFarmId.value = unitFarmIdValue;
    this.campId.value = campIdValue;
    this.supervisorId.value = supervisorIdValue;
    this.path.value = pathValue;
    this.userTypeId.value = userTypeIdValue;
    this.workerCardDateId.value = workerCardDateIdValue;
    this.issueDate.value = issueDateValue;
    this.expiryDate.value = expiryDateValue;
    this.createdDate.value = createdDateValue;
    this.employmentDate.value = employmentDateValue;
    this.printedDateTime.value = printedDateTimeValue;
  }
}
