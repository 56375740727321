<app-main-toolbar (toggle)="toggleDrawer(drawer)" [selectedPage]="selected"></app-main-toolbar>
<kendo-drawer-container>
  <kendo-drawer
      #drawer
      [items]="drawerItems"
      [mode]="mode"
      [mini]="mini"
      [miniWidth]="70"
      [width]="menuWidth"
      [animation]="true"
      [(expanded)]="drawerExpanded"
      [autoCollapse]="false"
      (select)="onSelect($event)"
      [classList]="'main-menu'"
  >
    <ng-template kendoDrawerItemTemplate let-item>
      <ng-container *ngIf="item.code !== '*menutitle*';else titleTemplate">
        <div *ngIf="!item.hidden" class="divmenu divmenu-level-{{ item.level }} {{item.expanded?'expanded':''}} {{item.selected?'selected':''}}">
          <span class="k-icon div-icon {{ item.icon}}" (click)="goToPage(item)"></span>
          <div class="menu-title" (click)="goToPage(item)">{{ item.name }}<span></span></div>
          <span
            *ngIf="(item.expanded) && item['children']"
            class="k-icon k-i-arrow-chevron-down expanded"
            style="margin-left: auto;"
          ></span>
          <span
            *ngIf="(!item.expanded) && item['children']"
            class="k-icon k-i-arrow-chevron-right arrow-left"
            style="margin-left: auto"
          ></span>
        </div>
        <ng-container *ngFor="let child of item.children">
          <div *ngIf="!child.hidden" class="divmenu divmenu-level-{{ child.level }} {{child.selected?'selected':''}}">
            <div class="menu-title" (click)="goToPage(child)">{{ child.name }} <span></span></div>
          </div>
        </ng-container>
      </ng-container>
      <ng-template #titleTemplate>
          <div class="divmenu-title">
            {{ item.name }}
          </div>
      </ng-template>
    </ng-template>
  </kendo-drawer>
  <kendo-drawer-content>
      <router-outlet></router-outlet>
  </kendo-drawer-content>
</kendo-drawer-container>
<app-main-footer></app-main-footer>
