import { Component, Input, OnInit, ViewChild} from '@angular/core';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { DataBindingDirective, DataStateChangeEvent, GridDataResult } from '@progress/kendo-angular-grid';
import { IntlService } from '@progress/kendo-angular-intl';
import { aggregateBy, AggregateDescriptor, process, State } from '@progress/kendo-data-query';
import * as moment from 'moment';
import { WorkersSalaryReport } from 'src/app/shared/models/report.model';
import { ReportsService } from '../reports.service';

@Component({
  selector: 'app-workers-salary-report',
  templateUrl: './workers-salary-report.component.html',
  styleUrls: ['./workers-salary-report.component.scss']
})
export class WorkersSalaryReportComponent implements OnInit {

  @ViewChild(DataBindingDirective) dataBinding: DataBindingDirective;

  @Input() generalManagerId: number;
  @Input() cropManagerId: number;
  @Input() supervisorId: number;

  public gridData: GridDataResult;
  public mySelection: string[] = [];
  public workerSalaryArray: WorkersSalaryReport[] = [];
  public pageCode: string;

  public hiddenForMobile: boolean = false;
  public fixedWidth: number = 150;

  public state: State = {
    skip: 0
  };

  public showHeader = false;

  public aggregates: AggregateDescriptor[] = [
    { field: 'monthWorkerSalary.workedHoursAmount', aggregate: 'sum' },
    { field: 'monthWorkerSalary.salaryAmount', aggregate: 'sum' },
    { field: 'yearWorkerSalary.workedHoursAmount', aggregate: 'sum' },
    { field: 'yearWorkerSalary.salaryAmount', aggregate: 'sum' }
  ];
  public total: any;

  public pieDataMonth: any[] = [];
  public pieDataYear: any[] = [];


  public month: number = new Date().getMonth();
  public year: number = new Date().getFullYear();
  public datePickerValue: Date = new Date(this.year, this.month);

  public selectedShowChartsValue: number;
  public showCharts: boolean = true;

  public labelMonth: string;
  public labelYear: string;

  constructor(private _reportsService: ReportsService, private intl: IntlService) {
      this.hiddenForMobile = (window.innerWidth <= 1024);
      if (window.innerWidth <= 768) {
        this.fixedWidth = 100;
      }
    };

  public ngOnInit(): void {

    const lsLoggedUser = localStorage.getItem('loggedUser')
    if (lsLoggedUser) {
      //this._currentUser = JSON.parse(lsLoggedUser);
    }

    this._setGridData();

  }

  private _setGridData() {
    this.workerSalaryArray = this._reportsService.workersSalaryReportExample();
    if (this.workerSalaryArray) {

      this.month = this.datePickerValue.getMonth();
      this.year = this.datePickerValue.getFullYear();
      this.labelMonth = moment(this.datePickerValue).format('MMMM YYYY');
      this.labelYear = moment(this.datePickerValue).format('YYYY');

      this.gridData = process(this.workerSalaryArray, this.state);
      this.total = aggregateBy(this.workerSalaryArray, this.aggregates);

      this.excelExportData = this.excelExportData.bind(this);
    }
  }

  public dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    this.gridData = process(this.workerSalaryArray, this.state);
  }

  public excelExportData(): ExcelExportData {
    const excelState = this.state;
    const excelData = process(this.workerSalaryArray, {
      group: this.state.group,
      sort: this.state.sort,
      filter: this.state.filter
    });
    const result: ExcelExportData = {
      data: excelData.data,
      group: excelState.group
    };
    return result;
  }

  public applyFilter() {
    this._setGridData();
  }

  public formatWorkingTime(minutes: number) {
    const hours = Math.floor(minutes/60);
    const minutesLeft = minutes % 60;
    const workingTimeString = `${hours < 10 ? '0' : ''}${hours}h   :  ${minutesLeft < 10 ? '0': ''}${minutesLeft}m`;
    return workingTimeString.toUpperCase();
}

}
