<div class="pictures-wrapper">
  <div>
    <h3>Worker picture</h3>
    <div>
      <img *ngIf="outputWorker && outputWorker.path; else noWorkerImage"
           [src]="outputWorker.path" [alt]="outputWorker.lastName + ' ' + outputWorker.firstName">
      <ng-template #noWorkerImage>
        <div class="no-image-wrapper">
          <img src="/assets/media/images/no-image.png" alt="no-worker-image">
        </div>
      </ng-template>
    </div>
  </div>
  <div>
    <h3>Picture of {{ reportedDate | date:'dd-MM-yyyy' }}</h3>
    <div *ngIf="workerPictureCompare && workerPictureCompare.picturePath; else noImage">
      <img [src]="workerPictureCompare.picturePath"
           [alt]="workerPictureCompare.workerLastName + ' ' + workerPictureCompare.workerFirstName">
    </div>
    <ng-template #noImage>
      <div class="no-image-wrapper">
        <img src="/assets/media/images/no-image.png" alt="no-image">
      </div>
    </ng-template>
  </div>
</div>
<br>
<div style="float: right;">
  <button kendoButton themeColor="secondary" class="k-button-l" (click)="closeAction()">Close</button>
</div>
