<kendo-grid
  [data]="gridData"
  [pageSize]="state.take"
  [skip]="state.skip"
  [sort]="state.sort"
  [filter]="state.filter"
  [sortable]="sortingSettings"
  [pageable]="{
    info: pagingSettings.info,
    type: pagingSettings.pagerTypes,
    pageSizes: pagingSettings.pageSizes,
    previousNext: pagingSettings.previousNext,
    position: pagingSettings.position,
    responsive: pagingSettings.responsive
  }"
  [reorderable]="!hiddenForMobile"
  [resizable]="!hiddenForMobile"
  filterable="menu"
  [columnMenu]="{ filter: true }"
  (dataStateChange)="dataStateChange($event)"
  style="max-height: 800px; margin-top: 10px !important"
>
  <ng-template kendoGridToolbarTemplate class="grid-toolbar">
    <div style="display:flex; width:100%;">
      <div class="maintitle"><h2 style="margin-top:0px">Deleted Workers</h2></div>
      <kendo-grid-spacer></kendo-grid-spacer>
      <div class="commands-bar">
        <div class="search-bar" style="display:inline-block; width:300px;">
          <input
            [style.width.px]="300"
            placeholder="Search in all columns..."
            kendoTextBox
            (input)="onFilter($event)"
          />
        </div>
        &nbsp;
        <button kendoGridExcelCommand type="button" icon="file-excel" *ngIf="!hiddenForMobile">
          Export Excel</button
        >
      </div>
    </div>
  </ng-template>
  <kendo-grid-column *ngIf="hiddenForMobile"
    title="Worker"
    [columnMenu]="false"
    [headerClass]="'gridcolumn header'"
  >
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <span class="responsive-row"><b>{{dataItem.firstName}} {{dataItem.lastName}}</b></span>
        <span class="responsive-row"><b>Code:</b> {{dataItem.code}}</span>
        <span class="responsive-row"><b>Gender:</b> {{dataItem.genderText}}</span>
        <span class="responsive-row"><b>City:</b> {{dataItem.city}}</span>
        <span class="responsive-row"><b>Worker type:</b> {{dataItem.workerTypeName}}</span>
        <span class="responsive-row"><b>Unit farm:</b> {{dataItem.unitFarmName}}</span>
        <span class="responsive-row"><b>Employment date:</b> {{dataItem.employmentDate}}</span>
        <span class="responsive-row"><b>Expiry date:</b> {{dataItem.expiryDate}}</span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column *ngIf="!hiddenForMobile"
    field="code"
    title="Code"
    filter="text"
    [headerClass]="'gridcolumn header'"
  >
</kendo-grid-column>
  <kendo-grid-column *ngIf="!hiddenForMobile"
    field="firstName"
    title="First Name"
    filter="text"
    [width]="fixedWidth"
    [headerClass]="'gridcolumn header'"
  >
  </kendo-grid-column>
  <kendo-grid-column *ngIf="!hiddenForMobile"
    field="lastName"
    title="Last Name"
    filter="text"
    [width]="fixedWidth"
    [headerClass]="'gridcolumn header'"
  >
  </kendo-grid-column>
  <kendo-grid-column *ngIf="!hiddenForMobile"
    field="genderText"
    title="Gender"
    filter="text"
    [headerClass]="'gridcolumn header'"
    [hidden]="true"
  >
  </kendo-grid-column>
  <kendo-grid-column *ngIf="!hiddenForMobile"
    field="city"
    title="City"
    filter="text"
    [headerClass]="'gridcolumn header'"
    [hidden]="true"
  >
  </kendo-grid-column>
  <kendo-grid-column *ngIf="!hiddenForMobile"
    field="workerTypeName"
    title="Worker Type"
    filter="text"
    [headerClass]="'gridcolumn header'"
    [hidden]="true"
  >
  </kendo-grid-column>
  <kendo-grid-column *ngIf="!hiddenForMobile"
    field="unitFarmName"
    title="Unit farm"
    filter="text"
    [headerClass]="'gridcolumn header'"
    [hidden]="true"
  >
  </kendo-grid-column>
  <kendo-grid-column *ngIf="!hiddenForMobile"
    field="supervisorFullName"
    title="Supervisor"
    filter="text"
    [headerClass]="'gridcolumn header'"
  >
  </kendo-grid-column>
  <kendo-grid-column
    field="employmentDate"
    title="Employment date"
    filter="date"
    [headerClass]="'gridcolumn header'"
  >
    <ng-template kendoGridCellTemplate let-dataItem>
        {{dataItem.employmentDate | date: 'dd-MM-yyyy'}}
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column *ngIf="!hiddenForMobile"
    field="expiryDate"
    title="Expiry date"
    filter="date"
    [headerClass]="'gridcolumn header'"
  >
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem.expiryDate | date: 'dd-MM-yyyy'}}
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column *ngIf="!hiddenForMobile"
  field="createdDate"
  title="Creation date"
  filter="date"
  [headerClass]="'gridcolumn header'"
>
  <ng-template kendoGridCellTemplate let-dataItem>
    {{dataItem.createdDate | date: 'dd-MM-yyyy'}}
  </ng-template>
</kendo-grid-column>
  <kendo-grid-column
    field="deletedAt"
    title="Deleted at"
    filter="date"
    [headerClass]="'gridcolumn header'"
  >
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem.deletedAt | date: 'dd-MM-yyyy'}}
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column
    field="deletedReason"
    title="Delete Reason"
    filter="text"
    [headerClass]="'gridcolumn header'">
  </kendo-grid-column>
  <kendo-grid-column *ngIf="!hiddenForMobile"
  [headerClass]="{ 'text-center': true }"
  [class]="{ 'text-center': true }"
  [width]="150"
  [columnMenu]="false"
>
  <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
    <kendo-splitbutton
      [data]="splitButtonItems"
      (itemClick)="onSplitButtonItemClick($event, dataItem)"
    >
      Actions
    </kendo-splitbutton>
  </ng-template>
</kendo-grid-column>
  <kendo-grid-excel fileName="Workers.xlsx" [fetchData]="excelExportData">
  </kendo-grid-excel>
</kendo-grid>
