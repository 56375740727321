import { Component, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LoadingService } from '../shared/loading-spinner/loading.service';
import { TextBoxComponent } from '@progress/kendo-angular-inputs';
import { forkJoin } from 'rxjs';
import { SharedService } from '../shared/shared.service';
import { environment } from 'src/environments/environment';
import { LoginService } from './login.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {

  @ViewChild('loginForm')
  loginForm!: NgForm;

  @ViewChild("password")
  public passwordField!: TextBoxComponent;

  public loginFormGroup: FormGroup;
  public errorMessage!: String;

  constructor(private _formBuilder: FormBuilder, private _router: Router, private _loginService: LoginService,
    private _loadingService: LoadingService, private _sharedService: SharedService) {

  this.loginFormGroup = this._formBuilder.group({
    'username': ['', <any>Validators.required],
    'password': ['', <any>Validators.required]
  });
  }

  public ngAfterViewInit(): void {
    const passwordEl = this.passwordField.input.nativeElement;
    passwordEl.type = passwordEl.type === "password" ? "text" : "password";
  }

  public toggleVisibility(): void {
    const passwordEl = this.passwordField.input.nativeElement;
    passwordEl.type = passwordEl.type === "password" ? "text" : "password";
  }

  public onLoginFormSubmit(): void {

    this.loginFormGroup.markAllAsTouched();
    const usernameControl = this.loginFormGroup.get('username');
    if (usernameControl) {
      usernameControl.setErrors(null);
      if (this.loginFormGroup.valid) {
        this._loginService.loginPost(this.loginFormGroup.value.username.trim(), this.loginFormGroup.value.password.trim()).subscribe({
          next: () => {
            this._loginService.userData.subscribe(userData => {
              if (userData) {
                forkJoin([
                  this._sharedService.getLoggedUserById(userData.id),
                  this._sharedService.getMenus(),
                  this._sharedService.getStrings(),
                  this._sharedService.getUserSettingsById(userData.id),
                  this._sharedService.getUnitFarms(),
                  this._sharedService.getCamps(),
                ]).subscribe(result => {
                  const loggedUser = result [0];
                  const menus = result[1];
                  const textStrings = result [2];
                  const userSettings = result [3];
                  const unitFarms = result [4];
                  const camps = result [5];
                  const workerTypes: Array<{ text: string; value: number }> = [
                    { text: "Permanent", value: 1 },
                    { text: "Temporary", value: 2 }
                  ];

                  localStorage.clear();

                  localStorage.setItem(environment.userSessionUniqueId, JSON.stringify(userData));
                  localStorage.setItem('loggedUser', JSON.stringify(loggedUser));
                  localStorage.setItem('menus', JSON.stringify(menus));
                  localStorage.setItem('textStrings', JSON.stringify(textStrings));
                  localStorage.setItem('userSettings', JSON.stringify(userSettings));
                  localStorage.setItem('workerTypes', JSON.stringify(workerTypes));
                  localStorage.setItem('unitFarms', JSON.stringify(unitFarms));
                  localStorage.setItem('camps', JSON.stringify(camps));
                  // console.log('localStorage', localStorage);
                  this._router.navigate(['/main']).then(() => {
                    this._loadingService.setHttpProgressStatus(false);
                  });
                });

              }
            });
          },
          error: () => {
            this._loginService.userData.subscribe(userData => {
              if (userData) {
                this._router.navigate(['/main']).then(() => {
                  this._loadingService.setHttpProgressStatus(false);
                });
              }
            });
          }
        });
      }
    }
  }

  public clearForm(): void {
    this.loginFormGroup.reset();
  }

}
