import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  NotificationRef,
  NotificationService,
} from '@progress/kendo-angular-notification';
import { User } from 'src/app/shared/models/user.model';
import { PageNavigationService } from 'src/app/shared/page-navigation.service';
import { SharedService } from 'src/app/shared/shared.service';
import { UsersService } from '../../users.service';

@Component({
  selector: 'app-user-detail-data',
  templateUrl: './user-detail-data.component.html',
  styleUrls: ['./user-detail-data.component.scss'],
})
export class UserDetailDataComponent implements OnInit {

  public userData: User = new User();
  public userId: number;
  public pageCode: string;
  constructor(
    private _sharedService: SharedService,
    private _usersService: UsersService,
    private _notificationService: NotificationService,
    private _router: Router,
    private _pageNavigationService: PageNavigationService
  ) {}

  ngOnInit(): void {
    if (this._pageNavigationService.dataItemId) {
      this.userId = this._pageNavigationService.dataItemId;
    }
    this.pageCode = this._pageNavigationService.pageCode;

    if (this.userId) {
      switch (this.pageCode) {
        case "generalManagers":
          this._usersService.getUserById(this.userId).subscribe({
            next: (user: User) => {
              this.userData = user;
              // console.log('userData:', this.userData);
            },
            error: (error) => {
              this._showErrorMessage(error, this.pageCode);
            },
          });
          break;
        case "cropManagers":
          this._usersService.getUserById(this.userId).subscribe({
            next: (user: User) => {
              this.userData = user;
              // console.log('userData:', this.userData);
            },
            error: (error) => {
              this._showErrorMessage(error, this.pageCode);
            },
          });
          break;
      }
    }
  }

  private _showErrorMessage(error: any, pageCode: string) {
    // console.log('error___this._usersService.getUserById_'+pageCode, error);
    const notification: NotificationRef = this._notificationService.show({
      content: 'Internal error',
      animation: { type: 'slide', duration: 500 },
      position: { horizontal: 'center', vertical: 'bottom' },
      type: { style: 'error', icon: true },
      hideAfter: 1000,
    });
    notification.afterHide?.subscribe(() => {
      //if (this.pageName === 'profile') {
      //  this._router.navigate(['/settings/users/list']);
      //}
    });
  }

  public goToEditPage() {

  }

  public getGenderText(genderValue: number): string{
    const genderText = this._sharedService.getGenderText(genderValue);
    return genderText;
  }
}
