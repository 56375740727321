import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from 'src/app/shared/models/user.model';
import { PageNavigationService } from 'src/app/shared/page-navigation.service';
import { UsersService } from '../../users/users.service';

@Component({
  selector: 'app-crop-manager-detail',
  templateUrl: './crop-manager-detail.component.html',
  styleUrls: ['./crop-manager-detail.component.scss'],
})
export class CropManagerDetailComponent implements OnInit {

  public isSubPage: boolean;
  public pageName: string

  //currentUser
  public userId: number;
  public user: User;

  //parent general manager
  public generalManagerId: number;
  public generalManager: User;

  // output
  public outputUser: User;
  public outputGeneralManager: User;

  //configuration variables
  public columns = 1;
  public currentResCode = "xl";

  public responsiveWrappers = {
    cropManagerData:
      {
        col: 1,
        colspan: 4,
        rowspan: 13
      },
    listOfSupervisors:
      {
        col: 5,
        colspan: 8,
        rowspan: 20
      }
  };

  constructor(private _router: Router, private _route: ActivatedRoute, private _usersService: UsersService) {
    if (window.innerWidth <= 1280) {
      this.currentResCode = "xl";
    }
    if (window.innerWidth <= 1024) {
      this.currentResCode = "l";
      this.responsiveWrappers = {
        cropManagerData:
          {
            col: 1,
            colspan: 12,
            rowspan: 13
          },
        listOfSupervisors:
          {
            col: 1,
            colspan: 12,
            rowspan: 20
          }
      };
    }
    if (window.innerWidth <= 768) {
      this.currentResCode = "m";
    }
    if (window.innerWidth <= 667) {
      this.currentResCode = "s";
    }
    if (window.innerWidth <= 447) {
      this.currentResCode = "xs";
    }
  }

  ngOnInit(): void {

    this.pageName = this._route.snapshot.url[2].path;
    if (this.pageName === 'dashboard') {
      this._route.queryParams
      .subscribe(params => {
        this.userId = params['cropManagerId'];
        this.generalManagerId = params['generalManagerId'];
        if (this.userId) {
          this._usersService.getUserById(this.userId).subscribe(user => {
            this.user = user;
            this.outputUser = user;
            this.isSubPage = true;
          });
        }
        if (this.generalManagerId) {
          this._usersService.getUserById(this.generalManagerId).subscribe(user => {
            this.generalManager = user;
            this.outputGeneralManager = user;
          });
        }
      });
    }

    if (window.innerWidth <= 768) {
      this.columns = 1;
    } else {
      this.columns = 3;
    }
  }

  public goToButtonOnClick(pageCode: string): void {
    if (pageCode === 'list') {
      this._router.navigate(['/staff/cropManagers/list']);
    }
    if (pageCode === 'generalManagerDashboard') {
      this._router.navigate(['staff/generalManagers/dashboard'],{ queryParams: { generalManagerId: this.generalManagerId } });
    }
  }

  public onInsertButtonClick(): void {
    this._router.navigate(['staff/supervisors/insert'],{ queryParams: { generalManagerId: this.generalManagerId, cropManagerId: this.userId} });
  }

}
