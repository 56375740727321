<div class="statistic-page main-content">
  <div class="button-bar" style="width:100%"> Report - Daily hours</div>
  <div class="card-container">
    <kendo-tilelayout class="tile" [columns]="12" [rowHeight]="10">
      <!-- Own data -->
      <kendo-tilelayout-item [col]="1" [colSpan]="12" [rowSpan]="30" >
        <!-- grid -->
        <kendo-grid
          [kendoGridBinding]="dailyHoursArray"
          (dataStateChange)="dataStateChange($event)"
          style="max-height: 100%; margin-top: 10px !important;"
          [sortable]="sortSettings"
          [groupable]="true"
          [group]="group"
          (groupChange)="onGroupChange($event)"
          [columnMenu]="{ filter: true }"
        >
        <ng-template kendoGridToolbarTemplate class="grid-toolbar">
          <div style="display:flex; width:100%;">
            <div class="search-bar" fxLayout="row" fxLayoutAlign="none" fxLayoutGap="10">
              <div class="search-field" fxLayout="column" fxLayoutAlign=" none" fxLayoutGap="2">
                <span class="k-label" style="display:block;"><b>Check-In date from</b></span>
                <kendo-datepicker
                  name="dateFrom"
                  [(value)]="dateFrom"
                  [max]="dateFromMax"
                  [placeholder]="''"
                  [style]="'width:180px'"
                  [format]="'dd-MM-yyyy'"
                ></kendo-datepicker>
              </div>
              <div class="search-field" fxLayout="column" fxLayoutAlign=" none" fxLayoutGap="2">
                <span class="k-label" style="display:block;"><b>Check-In date to</b></span>
                <kendo-datepicker
                  name="dateTo"
                  [(value)]="dateTo"
                  [max]="dateToMax"
                  [placeholder]="''"
                  [style]="'width:180px'"
                  [format]="'dd-MM-yyyy'"
                ></kendo-datepicker>
              </div>
              <div class="search-field" fxLayout="column" fxLayoutAlign="center" fxLayoutGap="2">
                <span class="k-label"><b>Supervisors</b></span>
                <kendo-combobox #supervisorId
                  [data]="supervisorsArray"
                  textField="text"
                  valueField="value"
                  [filterable]="true"
                  (filterChange)="handleSupervisorFilter($event)"
                  (selectionChange)="handleSupervisorSelection($event)"
                  placeholder="All supervisors"
                  [style]="'width:250px'"
                ></kendo-combobox>

              </div>
              <div class="search-field" fxLayout="column" fxLayoutAlign="center" fxLayoutGap="2">
                <span class="k-label"><b>Unit Farms</b></span>
                <kendo-combobox #unitFarmId
                                [data]="unitFarmsArray"
                                textField="text"
                                valueField="value"
                                [filterable]="true"
                                (filterChange)="handleUnitFarmFilter($event)"
                                (selectionChange)="handleUnitFarmSelection($event)"
                                placeholder="All unit farms"
                                [style]="'width:200px'"
                ></kendo-combobox>
              </div>
              <div class="search-field" fxLayout="column" fxLayoutAlign="center" fxLayoutGap="5">
                <button kendoButton themeColor="primary" icon="search" (click)="applyFilter()">Search</button>
              </div>
              <div class="commands-bar" fxLayout="column" fxLayoutAlign="center" fxLayoutGap="5">
                <button kendoGridExcelCommand themeColor="secondary" type="button" icon="file-excel" *ngIf="!hiddenForMobile">Export Excel</button>
              </div>
            </div>
          </div>
          <div class="totals-workers">
            <b>Number of workers: {{workersArrayCount}}</b>
          </div>
        </ng-template>
          <kendo-grid-column-group
            title="Workers"
            [locked]="false"
            [headerClass]="'gridcolumn header group-header'"
          >
            <ng-template kendoGridHeaderTemplate let-column="column">
              Workers
            </ng-template>
            <kendo-grid-column
              field="worker.code"
              title="code"
              filter="text"
              [headerClass]="'gridcolumn header'"
              [width]="120"
            >
            </kendo-grid-column>
            <kendo-grid-column
              field="worker.name"
              title="name"
              filter="text"
              [headerClass]="'gridcolumn header'"
              [width]="150"
            >
              <ng-template kendoGridGroupFooterTemplate let-aggregates="aggregates" let-group="group">
                <span class="cell worker totals" style="text-transform: uppercase;">
                  Sum of {{group.field}}:
                </span>
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-column
              field="supervisor.name"
              title="supervisor"
              filter="text"
              [headerClass]="'gridcolumn header'"
              [width]="150"
            >
            </kendo-grid-column>
          </kendo-grid-column-group>
          <kendo-grid-column-group
            title="Tasks"
            [locked]="false"
            [headerClass]="'gridcolumn header group-header'"
          >
            <ng-template kendoGridHeaderTemplate let-column="column">
              Tasks
            </ng-template>
            <kendo-grid-column
              field="task.name"
              title="name"
              filter="text"
              [headerClass]="'gridcolumn header'"
              [width]="300"
            >
              <ng-template kendoGridGroupFooterTemplate let-aggregates="aggregates">
                <span class="cell tasks sum" *ngIf="aggregates['task.name']">
                  Count: {{aggregates["task.name"].count}}
                </span>
              </ng-template>
              <ng-template kendoGridFooterTemplate let-column="column">
                <span class="cell tasks sum total" *ngIf="total && total['task.name']">{{total["task.name"].sum}}</span>
              </ng-template>
            </kendo-grid-column>
          </kendo-grid-column-group>
          <kendo-grid-column-group
            [title]="'Worked time'"
            [width]="500"
            [locked]="false"
            [headerClass]="'gridcolumn header group-header'"
          >
            <ng-template kendoGridHeaderTemplate let-column="column">
              Worked Time<br />
            </ng-template>
            <kendo-grid-column
              field="checkInDate"
              title="Check-In Date"
              [headerClass]="'gridcolumn header'"
              filter="date"
              [width]="130"
            >
              <ng-template kendoGridCellTemplate let-dataItem>
                <span class="cell">{{dataItem.checkIn | date : 'dd-MM-yyyy'}}</span>
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-column *ngIf="!hiddenForMobile"
              field="checkInHours"
              title="Check-In Time"
              [headerClass]="'gridcolumn header'"
              [filterable]="false"
              [width]="130"
            >
              <ng-template kendoGridCellTemplate let-dataItem>
                <span class="cell">{{dataItem.checkInTime}}</span>
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-column
            field="checkOutDate"
            title="Check-Out Date"
            [headerClass]="'gridcolumn header'"
            filter="date"
            [width]="130"
          >
            <ng-template kendoGridCellTemplate let-dataItem>
              <span class="cell">{{dataItem.checkOut | date : 'dd-MM-yyyy'}}</span>
            </ng-template>
          </kendo-grid-column>
            <kendo-grid-column
              field="checkOutHours"
              title="Check-Out Time"
              [headerClass]="'gridcolumn header'"
              [filterable]="false"
              [width]="130"
            >
              <ng-template kendoGridCellTemplate let-dataItem>
                <span class="cell">{{dataItem.checkOutTime}}</span>
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-column
              field="minutes"
              title="Worked hours"
              [headerClass]="'gridcolumn header'"
              [filterable]="false"
              [width]="160"
            >
              <ng-template kendoGridCellTemplate let-dataItem>
                <span class="cell hours">{{formatWorkingTime(dataItem.minutes)}}</span>
              </ng-template>
              <ng-template kendoGridGroupFooterTemplate let-aggregates="aggregates" let-group="group">
                <span class="cell minutes totals" *ngIf="aggregates['minutes']">
                  {{formatWorkingTime(aggregates["minutes"].sum)}}
                </span>
              </ng-template>
              <ng-template kendoGridFooterTemplate let-column="column">
                <span class="cell hours sum" *ngIf="total && total['minutes']">{{formatWorkingTime(total["minutes"].sum)}}</span>
              </ng-template>
            </kendo-grid-column>
          </kendo-grid-column-group>
          <kendo-grid-excel fileName="report.xlsx" [fetchData]="excelExportData">
            <kendo-excelexport-column field="worker.code" title="Code"
              [groupHeaderCellOptions]="{background: '#416A59', color: '#FFFFFF'}"
              [groupFooterCellOptions]="{background: '#cbdfd7', color: '#000000'}"
              [footerCellOptions]="{background: '#6a5941', color: '#FFFFFF'}"
            >
            </kendo-excelexport-column>
            <kendo-excelexport-column field="worker.name" title="Worker"
              [groupHeaderCellOptions]="{background: '#416A59', color: '#FFFFFF'}"
              [groupFooterCellOptions]="{background: '#cbdfd7', color: '#000000'}"
              [footerCellOptions]="{background: '#6a5941', color: '#FFFFFF'}"
            >
              <ng-template kendoExcelExportGroupFooterTemplate let-aggregates>
                Sum of minutes
             </ng-template>
            </kendo-excelexport-column>
            <kendo-excelexport-column field="supervisor.name" title="Supervisor"
              [groupHeaderCellOptions]="{background: '#416A59', color: '#FFFFFF'}"
              [groupFooterCellOptions]="{background: '#cbdfd7', color: '#000000'}"
              [footerCellOptions]="{background: '#6a5941', color: '#FFFFFF'}"
            >
            </kendo-excelexport-column>
            <kendo-excelexport-column field="task.name" title="Task"
              [groupHeaderCellOptions]="{background: '#416A59', color: '#FFFFFF'}"
              [groupFooterCellOptions]="{background: '#cbdfd7', color: '#000000'}"
              [footerCellOptions]="{background: '#6a5941', color: '#FFFFFF'}"
            >
            </kendo-excelexport-column>
            <kendo-excelexport-column field="checkIn" title="Check-in"
              [groupHeaderCellOptions]="{background: '#416A59', color: '#FFFFFF'}"
              [groupFooterCellOptions]="{background: '#cbdfd7', color: '#000000'}"
              [footerCellOptions]="{background: '#6a5941', color: '#FFFFFF'}"
            >
            </kendo-excelexport-column>
            <kendo-excelexport-column field="checkOut" title="Check-out"
              [groupHeaderCellOptions]="{background: '#416A59', color: '#FFFFFF'}"
              [groupFooterCellOptions]="{background: '#cbdfd7', color: '#000000'}"
              [footerCellOptions]="{background: '#6a5941', color: '#FFFFFF'}"
            >
            </kendo-excelexport-column>
            <kendo-excelexport-column field="minutes" title="Worked minutes"
              [groupHeaderCellOptions]="{background: '#416A59', color: '#FFFFFF'}"
              [groupFooterCellOptions]="{background: '#cbdfd7', color: '#000000', textAlign: 'right' }"
              [footerCellOptions]="{background: '#6a5941', color: '#FFFFFF', textAlign: 'right' }"
            >
              <ng-template kendoExcelExportGroupFooterTemplate let-aggregates>
                 {{ aggregates["minutes"].sum }}
              </ng-template>
              <ng-template kendoExcelExportFooterTemplate let-aggregates>
                {{ total["minutes"].sum }}
             </ng-template>
            </kendo-excelexport-column>
          </kendo-grid-excel>
        </kendo-grid>
      </kendo-tilelayout-item>
    </kendo-tilelayout>
  </div>
</div>
