export class WorkerAtCropReport {
  cropId: number;
  cropName: string;
  periodA: {
    dateFrom: string;
    dateTo: string;
    quantityAmount: number;
    quantityPerc: number;
    hoursAmount: number;
    hoursPerc: number;
  };
  periodB: {
    dateFrom: string;
    dateTo: string;
    quantityAmount: number;
    quantityPerc: number;
    hoursAmount: number;
    hoursPerc: number;
  };
  difference : {
    quantityAmount: number;
    quantityPerc: number;
    hoursAmount: number;
    hoursPerc: number;
  };

}


export class CostOfCropReport {
  cropId: number;
  cropName: string;
  periodA: {
    dateFrom: string;
    dateTo: string;
    costAmount: number;
    costPerc: number;
  };
  periodB: {
    dateFrom: string;
    dateTo: string;
    costAmount: number;
    costPerc: number;
  };
  difference : {
    costAmount: number;
    costPerc: number;
  };

}

export class WorkersSalaryReport {
  workerId: number;
  workerName: string;
  month: number;
  year: number;
  monthWorkerSalary: {
    workedHoursAmount: number;
    salaryAmount: number;
  };
  yearWorkerSalary: {
    workedHoursAmount: number;
    salaryAmount: number;
  };
}

export class DailyHoursReport {
  worker : {
    id: number;
    name: string;
  };
  task: {
    id: number;
    name: string;
  };
  unitFarm: {
    id: number;
    name: string;
  }
  date: string;
  checkIn: string;
  checkInDate: string;
  checkInTime: string;
  checkOut: string;
  checkOutDate: string;
  checkOutTime: string;
  minutes: number;
}


export class ComparePicturesReport {
  id: number;
  reportedDateTime: string;
  reportedDate: string;
  reportedTime: string;
  worker : {
    id: number;
    name: string;
  };
  supervisor: {
    id: number;
    name: string;
  };
  picturePath: string;
  comment: string;
  checkInTime: string;
  isHoliday: number;
  latitude: string;
  longitude: string;
}
