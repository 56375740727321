import { HttpParams } from '@angular/common/http';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Params, Router } from '@angular/router';
import {
  DataBindingDirective,
  DataStateChangeEvent,
  GridDataResult,
  MultipleSortSettings
} from '@progress/kendo-angular-grid';
import { PagerPosition, PagerType } from '@progress/kendo-angular-grid/pager/pager-settings';
import { process, State } from '@progress/kendo-data-query';
import { UsersService } from 'src/app/modules/users/users.service';
import { SupervisorAssignments } from 'src/app/shared/models/supervisorAssignments.model';
import { User } from 'src/app/shared/models/user.model';
import { Worker } from 'src/app/shared/models/worker.model';
import { WorkersService } from '../../workers.service';

@Component({
  selector: 'app-worker-detail-assignmentsupervisors',
  templateUrl: './worker-detail-assignmentsupervisors.component.html',
  styleUrls: ['./worker-detail-assignmentsupervisors.component.scss']
})
export class WorkerDetailAssignmentsupervisorsComponent implements OnInit {

  @ViewChild(DataBindingDirective) dataBinding: DataBindingDirective;

  public gridData: GridDataResult;
  public mySelection: string[] = [];
  public assignmentsDataArray: SupervisorAssignments[] = [];
  public filteredData: SupervisorAssignments[] = [];
  public pageCode: string;

  public hiddenForMobile: boolean = false;
  public fixedWidth: number = 150;

  private _varPagerTypes: PagerType = 'numeric';
  private _varPagerPosition: PagerPosition = 'both';

  private _currentUser: User;
  private _worker: Worker;
  public workerName: string;

  public generalManagerId: number;
  public cropManagerId: number;
  public supervisorId: number;
  public workerId: number;

  public cropManagersSource: Array<{ text: string; value: number }> = [];
  public cropManagersArray: Array<{ text: string; value: number }>;

  public selectedAssignedValue: number;
  public selectedCropManagerValue?: number;

  public pagingSettings = {
    pagerTypes: this._varPagerTypes,
    type: 'input',
    // buttonCount: 5,
    info: true,
    previousNext: true,
    position: this._varPagerPosition,
    pageSize: 50,
    responsive: true,
    pageSizes: [
      5,
      10,
      20,
      50,
      100,
    ]
  };

  public sortingSettings: MultipleSortSettings = {
    allowUnsort: true
  };

  public state: State = {
    skip: 0,
    take: this.pagingSettings.pageSize,
    // Initial filter descriptor
  };

  public showHeader = false;

  constructor(private _workersService: WorkersService, private _usersService: UsersService, private _route: ActivatedRoute, private _router: Router) {
    this.hiddenForMobile = (window.innerWidth <= 1024);
    this.fixedWidth = (window.innerWidth <= 768) ? 100 : this.fixedWidth;
    if (window.innerWidth <= 768) {
      this.fixedWidth = 100;
      this.pagingSettings.pageSize = 5;
      this.state.take = 5;
    }
  };

  public ngOnInit(): void {

    const lsLoggedUser = localStorage.getItem('loggedUser')
    if (lsLoggedUser) {
      this._currentUser = JSON.parse(lsLoggedUser);
      this._route.queryParams
      .subscribe(params => {
        this.generalManagerId = params['generalManagerId'];
        this.cropManagerId = params['cropManagerId'];
        this.supervisorId = params['supervisorId'];
        this.workerId = params['workerId'];
        if (this.workerId) {
          this._workersService.getWorkerById(this.workerId).subscribe(result => {
            this._worker = result;
            this.workerName = result.firstName + ' ' + result.lastName;
          });
        }
        if (this.generalManagerId) {
          this._usersService.getCropManagers(this.generalManagerId).subscribe(result => {
            result.forEach(rec => {
              this.cropManagersSource.push({text: rec.firstName + ' ' + rec.lastName, value: rec.id});
            });
          });
          this.cropManagersArray = this.cropManagersSource;
          // console.log('this.cropManagersArray:', this.cropManagersArray)
        }
        this._setGridData();
      });
    }
  }

  private _setGridData() {
    this._workersService.getAssignmentSupervisors(
      this.generalManagerId,
      (this.cropManagerId) ? this.cropManagerId : 0,
      this.workerId
      ).subscribe((result) => {
        // console.log('generalManagerId:', this.generalManagerId);
        // console.log('cropManagerId:', this.cropManagerId);
        // console.log('workerId:', this.workerId);
        this.assignmentsDataArray = result;
        this.filteredData = result;
        this.gridData = process(result, this.state);
      });
  }

  public dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    this.gridData = process(this.filteredData, this.state);
  }

  public onFilter(input: Event): void {
    const inputValue = (input.target as HTMLInputElement).value.trim();
    if (inputValue) {
      this.state.filter =
        {
          logic: "or",
          filters: [
            {
              field: "supervisorFirstName",
              operator: "contains",
              value: inputValue,
            },
            {
              field: "supervisorLastName",
              operator: "contains",
              value: inputValue,
            },
            {
              field: "supervisorEmail",
              operator: "contains",
              value: inputValue,
            },
            {
              field: "supervisorMobile",
              operator: "contains",
              value: inputValue,
            }
          ],
      };
    } else {
      this.state.filter = undefined;
    }
  }

  public handleCropManagerFilter(value: any) {
    this.cropManagersArray = this.cropManagersSource.filter(
      (s) => s.text.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  }

  public handleAssignedValue(value: number) {
    this.selectedAssignedValue = value;
  }

  public handleCropManagerValue(obj: any) {
    if (obj) {
      this.selectedCropManagerValue = obj.value;
    } else {
      this.selectedCropManagerValue = undefined;
    }
  }

  public applyFilter() {
    this.filteredData = this.assignmentsDataArray;
    // apply filter crop manager id
    if (this.selectedCropManagerValue != undefined) {
      this.filteredData = this.filteredData.filter(
        (s) => s.cropManagerId === this.selectedCropManagerValue
      );
    }
    // apply filter assigned
    if ((this.selectedAssignedValue != undefined) && (this.selectedAssignedValue >= 0)) {
      this.filteredData = this.filteredData.filter(
        (s) => s.assigned === this.selectedAssignedValue
      );
    }
    this.state.skip = 0;
    this.gridData = process(this.filteredData, this.state);
  }

  public handleAssignment(record:SupervisorAssignments , actionCode:number) {
    const workerId = (record.workerId) ? record.workerId : this.workerId;
    this._workersService.assignWorkerToSupervisor(workerId, record.supervisorId, actionCode).subscribe(result => {
      if (actionCode === 1) {
        record.workerId = this._worker.id;
        record.workerFirstName = this._worker.firstName;
        record.workerLastName = this._worker.lastName;
        record.assigned = 1;
      } else {
        record.workerId = 0;
        record.workerFirstName = '';
        record.workerLastName = '';
        record.assigned = 0;
      }
    });
  }
  public handleGoBackToDashboard() {
    let params = {};
    if (this.generalManagerId) {
      Object.assign(params, {generalManagerId:this.generalManagerId});
    }
    if (this.cropManagerId) {
      Object.assign(params, {cropManagerId:this.cropManagerId});
    }
    if (this.supervisorId) {
      Object.assign(params, {supervisorId:this.supervisorId});
    }
    if (this.workerId) {
      Object.assign(params, {workerId:this.workerId});
    }
    const navigationExtras: NavigationExtras = {};
    navigationExtras.queryParams = params;
    // console.log('navigationExtras', navigationExtras);
    this._router.navigate(['staff/workers/dashboard'],navigationExtras);
  }

}
