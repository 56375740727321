import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { User } from 'src/app/shared/models/user.model';
import { SharedService } from 'src/app/shared/shared.service';

@Component({
  selector: 'app-crop-manager-detail-data',
  templateUrl: './crop-manager-detail-data.component.html',
  styleUrls: ['./crop-manager-detail-data.component.scss']
})
export class CropManagerDetailDataComponent implements OnInit {

  @Input() userData: User;
  @Input() generalManager: User;

  constructor(
    private _sharedService: SharedService,
    private _router: Router,
  ) {}

  ngOnInit(): void {

  }


  public goToEditPage() {
    if (this.generalManager) {
      this._router.navigate(['staff/cropManagers/update'],{ queryParams: { generalManagerId: this.generalManager.id, cropManagerId: this.userData.id }});
    } else {
      this._router.navigate(['staff/cropManagers/update'],{ queryParams: { cropManagerId: this.userData.id }});
    }

  }

  public getGenderText(genderValue: number): string{
    const genderText = this._sharedService.getGenderText(genderValue);
    return genderText;
  }

}
