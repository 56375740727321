// Custom Messages
export const esCustomMessages = {
    teamEfficiency: 'Eficiencia del equipo',
    teamMembers: 'Miembros del equipo',
    dashboard: 'Tablero',
    planning: 'Planificación',
    info: 'Informacion',
    profile: 'Perfil',
    language: 'Idioma',
    trend: 'Tendencia',
    volume: 'Volumen',
    myTeam: 'Mi equipo',
    allTeams: 'Todos los equipos',
    teamCalendar: 'Calendario del equipo',
    saveChanges: 'Guardar cambios',
    cancel: 'Cancelar',
    warehouse: 'Almacén De Café',
    startFreeTrial: 'Iniciar prueba gratuita',
    buyNow: 'Comprar ahora',
    getSource: 'Obtenga el código fuente en GitHub',
    demoInfo:
        'Esta aplicación de demostración está construida utilizando los siguientes componentes de Kendo UI for Angular.',
    firstName: 'Primer nombre',
    middleName: 'Segundo nombre',
    lastName: 'Apellido',
    email: 'Dirección de correo electrónico',
    phoneNumber: 'Número de teléfono',
    country: 'País',
    biography: 'Biografía corta',
    public: 'Incluir en directorio público',
    team: 'Equipo',
    employee: 'Empleado',
    contactName: 'Nombre de contacto',
    jobTitle: 'Título del trabajo',
    status: 'Estado',
    performance: 'Rendimiento',
    rating: 'Clasificación',
    engagement: 'Compromiso',
    budget: 'Presupuesto',
    contacts: 'Contactos',
    address: 'Dirección',
    phone: 'Teléfono',
    gridSearch: 'Buscar en todas las columnas ...',
    exportExcel: 'Exportar a Excel',
    exportPdf: 'Exportar a PDF'
};

// Kendo Angular Component Messages
export const esComponentMessages = {
    // Grid
    'kendo.grid.groupPanelEmpty': 'Arrastre el título de una columna y suéltelo aquí para agrupar por ese criterio',
    'kendo.grid.noRecords': 'No hay datos disponibles.',
    'kendo.grid.pagerFirstPage': 'Ir a la primera página',
    'kendo.grid.pagerPreviousPage': 'Ir a la página anterior',
    'kendo.grid.pagerNextPage': 'Ir a la página siguiente',
    'kendo.grid.pagerLastPage': 'Ir a la última página',
    'kendo.grid.pagerPage': 'Página',
    'kendo.grid.pagerOf': 'de',
    'kendo.grid.pagerItems': 'ítems',
    'kendo.grid.pagerItemsPerPage': 'ítems por página',
    'kendo.grid.pagerPageNumberInputTitle': 'Número de Página',
    'kendo.grid.filter': 'Filtrar',
    'kendo.grid.filterEqOperator': 'Es igual a',
    'kendo.grid.filterNotEqOperator': 'No es igual a',
    'kendo.grid.filterIsNullOperator': 'Es nulo',
    'kendo.grid.filterIsNotNullOperator': 'No es nulo',
    'kendo.grid.filterIsEmptyOperator': 'Está vacío',
    'kendo.grid.filterIsNotEmptyOperator': 'No está vacío',
    'kendo.grid.filterStartsWithOperator': 'Comienza con',
    'kendo.grid.filterContainsOperator': 'Contiene',
    'kendo.grid.filterNotContainsOperator': 'No contiene',
    'kendo.grid.filterEndsWithOperator': 'Termina en',
    'kendo.grid.filterGteOperator': 'Es mayor o igual que',
    'kendo.grid.filterGtOperator': 'Es mayor que',
    'kendo.grid.filterLteOperator': 'Es menor o igual que',
    'kendo.grid.filterLtOperator': 'Es menor o igual que',
    'kendo.grid.filterIsTrue': 'Sí',
    'kendo.grid.filterIsFalse': 'No',
    'kendo.grid.filterBooleanAll': '(Todas)',
    'kendo.grid.filterAfterOrEqualOperator': 'Es posterior o igual a',
    'kendo.grid.filterAfterOperator': 'Es posterior',
    'kendo.grid.filterBeforeOperator': 'Es anterior',
    'kendo.grid.filterBeforeOrEqualOperator': 'Es anterior o igual a',
    'kendo.grid.filterFilterButton': 'Filtrar',
    'kendo.grid.filterClearButton': 'Limpiar filtros',
    'kendo.grid.filterAndLogic': 'Y',
    'kendo.grid.filterOrLogic': 'O',
    'kendo.grid.filterDateToggle': 'Mostrar calendario',
    'kendo.grid.filterDateToday': 'Hoy',
    'kendo.grid.filterNumericDecrement': 'Disminuir valor',
    'kendo.grid.filterNumericIncrement': 'Incrementar valor',
    'kendo.grid.loading': 'Cargando',
    'kendo.grid.sort': 'Ordenar',
    'kendo.grid.columnMenu': 'Menú Columna',
    'kendo.grid.columns': 'Columnas',
    'kendo.grid.lock': 'Bloquear',
    'kendo.grid.unlock': 'Desbloquear',
    'kendo.grid.stick': 'Stick',
    'kendo.grid.unstick': 'Unstick',
    'kendo.grid.setColumnPosition': 'Set Column Position',
    'kendo.grid.sortAscending': 'Orden ascendente',
    'kendo.grid.sortDescending': 'Orden descendente',
    'kendo.grid.columnsApply': 'Aplicar',
    'kendo.grid.columnsReset': 'Defecto',
    'kendo.grid.sortable': 'Ordenable',
    'kendo.grid.sortedAscending': 'Ordenado ascendentemente',
    'kendo.grid.sortedDescending': 'Ordenado descendentemente',
    'kendo.grid.sortedDefault': 'No ordenado',

    // DropDowns
    'kendo.autocomplete.noDataText': 'No hay datos disponibles',
    'kendo.autocomplete.clearTitle': 'Borrar',
    'kendo.combobox.noDataText': 'No hay datos disponibles',
    'kendo.combobox.clearTitle': 'Borrar',
    'kendo.dropdownlist.noDataText': 'No hay datos disponibles',
    'kendo.dropdownlist.clearTitle': 'Borrar',

    // Calendar
    'kendo.calendar.today': 'Hoy',

    // TextBox
    'kendo.textbox.clearTitle': 'Borrar',

    // DatePicker
    'kendo.datepicker.today': 'Hoy',
    'kendo.datepicker.toggle': 'Alternar calendario',

    // DateInput
    'kendo.dateinput.increment': 'Incrementar valor',
    'kendo.dateinput.decrement': 'Disminuir valor',

    // NumericTextBox
    'kendo.numerictextbox.increment': 'Incrementar valor',
    'kendo.numerictextbox.decrement': 'Disminuir valor',

    // DateTimePicker
    'kendo.datetimepicker.dateTab': 'Fecha',
    'kendo.datetimepicker.dateTabLabel': 'Mostrar fecha',
    'kendo.datetimepicker.timeTab': 'Hora',
    'kendo.datetimepicker.timeTabLabel': 'Mostrar hora',
    'kendo.datetimepicker.toggle': 'Cambiar calendario',
    'kendo.datetimepicker.accept': 'Acceptar',
    'kendo.datetimepicker.acceptLabel': 'Acceptar',
    'kendo.datetimepicker.cancel': 'Cancelar',
    'kendo.datetimepicker.cancelLabel': 'Cancelar',
    'kendo.datetimepicker.now': 'AHORA',
    'kendo.datetimepicker.nowLabel': 'Ahora',
    'kendo.datetimepicker.today': 'HOY',
    'kendo.datetimepicker.prevButtonTitle': 'Navigate to previous view',
    'kendo.datetimepicker.nextButtonTitle': 'Navigate to next view',

    // TimePicker
    'kendo.timepicker.accept': 'Fijar',
    'kendo.timepicker.acceptLabel': 'Fijar',
    'kendo.timepicker.cancel': 'Cancelar',
    'kendo.timepicker.cancelLabel': 'Cancelar',
    'kendo.timepicker.now': 'Ahora',
    'kendo.timepicker.nowLabel': 'Ahora',
    'kendo.timepicker.toggle': 'Alternar lista de tiempo',

    // FileSelect
    'kendo.fileselect.cancel': 'Cancelar',
    'kendo.fileselect.clearSelectedFiles': 'Borrar',
    'kendo.fileselect.dropFilesHere': 'Arrastre los archivos aquí para subirlos',
    'kendo.fileselect.headerStatusUploaded': 'Completado',
    'kendo.fileselect.headerStatusUploading': 'Subiendo...',
    'kendo.fileselect.invalidFileExtension': 'Tipo de fichero no permitido.',
    'kendo.fileselect.invalidMaxFileSize': 'Tamaño fichero demasiado grande.',
    'kendo.fileselect.invalidMinFileSize': 'Tamaño fichero demasiado pequeño.',
    'kendo.fileselect.remove': 'Quitar',
    'kendo.fileselect.retry': 'Reintentar',
    'kendo.fileselect.select': 'Seleccione...',
    'kendo.fileselect.uploadSelectedFiles': 'Subir archivos',
    'kendo.fileselect.externalDropFilesHere': 'Arrastra aqui los ficheros a subir',
    'kendo.fileselect.filesBatchStatus': 'ficheros',
    'kendo.fileselect.filesBatchStatusFailed': 'fallo al subir ficheros.',
    'kendo.fileselect.filesBatchStatusUploaded': 'ficheros subidos correctamente.',
    'kendo.fileselect.fileStatusFailed': 'Fallo al subir fichero.',
    'kendo.fileselect.fileStatusUploaded': 'Fichero subido correctamente.',
    'kendo.fileselect.headerStatusPaused': 'Pausado',

    // Scheduler
    'kendo.scheduler.agendaViewTitle': 'Agenda',
    'kendo.scheduler.allDay': 'todo el día',
    'kendo.scheduler.allEvents': 'Todos los eventos',
    'kendo.scheduler.calendarToday': 'Hoy',
    'kendo.scheduler.cancel': 'Cancelar',
    'kendo.scheduler.save': 'Save',
    'kendo.scheduler.editorEventTitle': 'Título',
    'kendo.scheduler.editorEventStart': 'Inicio',
    'kendo.scheduler.editorEventStartTimeZone': 'Zona horaria de inicio',
    'kendo.scheduler.editorEventEnd': 'Fin',
    'kendo.scheduler.editorEventEndTimeZone': 'Zona horaria final',
    'kendo.scheduler.dateHeader': 'Fecha',
    'kendo.scheduler.dayViewTitle': 'Título',
    'kendo.scheduler.deleteConfirmation': '¿Está seguro que quiere eliminar este evento?',
    'kendo.scheduler.deleteDialogTitle': 'Eliminar evento',
    'kendo.scheduler.deleteOccurrence': 'Delete current occurrence',
    'kendo.scheduler.deleteRecurringConfirmation': 'Do you want to delete only this event occurrence or the whole series?',
    'kendo.scheduler.deleteRecurringDialogTitle': '¿Quiere eliminar esta ocurrencia del evento o la serie completa?',
    'kendo.scheduler.deleteSeries': 'Eliminar la serie',
    'kendo.scheduler.deleteTitle': 'Eliminar evento',
    'kendo.scheduler.destroy': 'Eliminar',
    'kendo.scheduler.editOccurrence': 'Editar ocurrencia actual',
    'kendo.scheduler.editorEventAllDay': 'Todo el día',
    'kendo.scheduler.editorEventDescription': 'Descripción',
    'kendo.scheduler.editorEventSeparateTimeZones': 'Usar zonas horarias separadas para el inicio y el fin',
    'kendo.scheduler.editorEventTimeZone': 'Zona horaria de fin',
    'kendo.scheduler.editorTitle': 'Título',
    'kendo.scheduler.editRecurringConfirmation': '¿Quiere editar esta ocurrencia del evento o la serie completa?',
    'kendo.scheduler.editRecurringDialogTitle': 'Editar elemento recurrente',
    'kendo.scheduler.editSeries': 'Editar la serie',
    'kendo.scheduler.eventHeader': 'Evento',
    'kendo.scheduler.monthViewTitle': 'Mes',
    'kendo.scheduler.multiDayViewTitle': 'Multi-Day',
    'kendo.scheduler.nextTitle': 'Siguiente',
    'kendo.scheduler.previousTitle': 'Anterior',
    'kendo.scheduler.recurrenceEditorDailyInterval': 'día(s)',
    'kendo.scheduler.recurrenceEditorDailyRepeatEvery': 'Repetir cada:',
    'kendo.scheduler.recurrenceEditorEndAfter': 'Después',
    'kendo.scheduler.recurrenceEditorEndLabel': 'Fin',
    'kendo.scheduler.recurrenceEditorEndNever': 'Nunca',
    'kendo.scheduler.recurrenceEditorEndOccurrence': 'ocurrencia(s)',
    'kendo.scheduler.recurrenceEditorEndOn': 'On',
    'kendo.scheduler.recurrenceEditorFrequenciesDaily': 'Diariamente',
    'kendo.scheduler.recurrenceEditorFrequenciesMonthly': 'Mensualmente',
    'kendo.scheduler.recurrenceEditorFrequenciesNever': 'Nunca',
    'kendo.scheduler.recurrenceEditorFrequenciesWeekly': 'Semanalmente',
    'kendo.scheduler.recurrenceEditorFrequenciesYearly': 'Anualmente',
    'kendo.scheduler.recurrenceEditorMonthlyDay': 'Día',
    'kendo.scheduler.recurrenceEditorMonthlyInterval': 'mes(es)',
    'kendo.scheduler.recurrenceEditorMonthlyRepeatEvery': 'Repetir cada:',
    'kendo.scheduler.recurrenceEditorMonthlyRepeatOn': 'Repetir en:',
    'kendo.scheduler.recurrenceEditorOffsetPositionsFirst': 'primero',
    'kendo.scheduler.recurrenceEditorOffsetPositionsFourth': 'cuarto',
    'kendo.scheduler.recurrenceEditorOffsetPositionsLast': 'último',
    'kendo.scheduler.recurrenceEditorOffsetPositionsSecond': 'segundo',
    'kendo.scheduler.recurrenceEditorOffsetPositionsThird': 'tercero',
    'kendo.scheduler.recurrenceEditorRepeat': 'Repetir',
    'kendo.scheduler.recurrenceEditorWeekdaysDay': 'día',
    'kendo.scheduler.recurrenceEditorWeekdaysWeekday': 'día de semana',
    'kendo.scheduler.recurrenceEditorWeekdaysWeekendday': 'Día de fin de semana',
    'kendo.scheduler.recurrenceEditorWeeklyInterval': 'semana(s)',
    'kendo.scheduler.recurrenceEditorWeeklyRepeatEvery': 'Repetir cada:',
    'kendo.scheduler.recurrenceEditorWeeklyRepeatOn': 'Repetir en:',
    'kendo.scheduler.recurrenceEditorYearlyInterval': 'año(s)',
    'kendo.scheduler.recurrenceEditorYearlyOf': 'de',
    'kendo.scheduler.recurrenceEditorYearlyRepeatEvery': 'Repetir cada:',
    'kendo.scheduler.recurrenceEditorYearlyRepeatOn': 'Repetir en:',
    'kendo.scheduler.showFullDay': 'Mostrar día completo',
    'kendo.scheduler.showWorkDay': 'Mostrar horas laborables',
    'kendo.scheduler.timeHeader': 'Hora',
    'kendo.scheduler.timelineMonthViewTitle': 'Timeline Month',
    'kendo.scheduler.timelineViewTitle': 'Línea de tiempo',
    'kendo.scheduler.timelineWeekViewTitle': 'Timeline Week',
    'kendo.scheduler.today': 'Hoy',
    'kendo.scheduler.weekViewTitle': 'Semana',
    'kendo.scheduler.workWeekViewTitle': 'Semana laboral',

    // RecurrenceEditor
    'kendo.recurrenceeditor.dailyInterval': 'día(s)',
    'kendo.recurrenceeditor.dailyRepeatEvery': 'Repetir cada:',
    'kendo.recurrenceeditor.endAfter': 'Después',
    'kendo.recurrenceeditor.endLabel': 'Fin:',
    'kendo.recurrenceeditor.endNever': 'Nunca',
    'kendo.recurrenceeditor.endOccurrence': 'ocurrencia(s)',
    'kendo.recurrenceeditor.endOn': 'En',
    'kendo.recurrenceeditor.frequenciesDaily': 'Diariamente',
    'kendo.recurrenceeditor.frequenciesMonthly': 'Mensualmente',
    'kendo.recurrenceeditor.frequenciesNever': 'Nunca',
    'kendo.recurrenceeditor.frequenciesWeekly': 'Semanalmente',
    'kendo.recurrenceeditor.frequenciesYearly': 'Anualmente',
    'kendo.recurrenceeditor.monthlyDay': 'Día',
    'kendo.recurrenceeditor.monthlyInterval': 'mes(es)',
    'kendo.recurrenceeditor.monthlyRepeatEvery': 'Repetir cada:',
    'kendo.recurrenceeditor.monthlyRepeatOn': 'Repetir en:',
    'kendo.recurrenceeditor.offsetPositionsFourth': 'cuarto',
    'kendo.recurrenceeditor.offsetPositionsLast': 'último',
    'kendo.recurrenceeditor.offsetPositionsSecond': 'segundo',
    'kendo.recurrenceeditor.offsetPositionsThird': 'tercero',
    'kendo.recurrenceeditor.repeat': 'Repetir',
    'kendo.recurrenceeditor.weekdaysDay': 'día',
    'kendo.recurrenceeditor.weekdaysWeekday': 'día de semana',
    'kendo.recurrenceeditor.weekdaysWeekendday': 'día de fin de semana',
    'kendo.recurrenceeditor.weeklyInterval': 'semana(s)',
    'kendo.recurrenceeditor.weeklyRepeatEvery': 'Repetir cada:',
    'kendo.recurrenceeditor.weeklyRepeatOn': 'Repetir en:',
    'kendo.recurrenceeditor.yearlyInterval': 'año(s)',
    'kendo.recurrenceeditor.yearlyOf': 'de',
    'kendo.recurrenceeditor.yearlyRepeatEvery': 'Repetir cada:',
    'kendo.recurrenceeditor.yearlyRepeatOn': 'Repetir en:'
};
