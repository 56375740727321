import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import {
  DataBindingDirective,
  DataStateChangeEvent,
  GridDataResult,
  MultipleSortSettings
} from '@progress/kendo-angular-grid';
import { PagerPosition, PagerType } from '@progress/kendo-angular-grid/pager/pager-settings';
import { NotificationRef, NotificationService } from '@progress/kendo-angular-notification';
import { process, State } from '@progress/kendo-data-query';
import { User } from 'src/app/shared/models/user.model';
import { SharedService } from 'src/app/shared/shared.service';
import { UsersService } from '../../users/users.service';

@Component({
  selector: 'app-general-managers-list',
  templateUrl: './general-managers-list.component.html',
  styleUrls: ['./general-managers-list.component.scss']
})
export class GeneralManagersListComponent implements OnInit {

  @ViewChild(DataBindingDirective) dataBinding: DataBindingDirective;

  public gridData: GridDataResult;
  public mySelection: string[] = [];
  public usersDataArray: User[] = [];
  public pageCode: string;

  public hiddenForMobile: boolean = false;
  public fixedWidth: number = 150;

  private _varPagerTypes: PagerType = 'numeric';
  private _varPagerPosition: PagerPosition = 'both';

  private _currentUser: User;

  public pagingSettings = {
    pagerTypes: this._varPagerTypes,
    type: 'input',
    // buttonCount: 5,
    info: true,
    previousNext: true,
    position: this._varPagerPosition,
    pageSize: 10,
    responsive: true,
    pageSizes: [
      5,
      10,
      20,
      50,
      100,
    ]
  };

  public sortingSettings: MultipleSortSettings = {
    allowUnsort: true
  };

  public state: State = {
    skip: 0,
    take: this.pagingSettings.pageSize,
    // Initial filter descriptor
  };

  public showHeader = false;

  public splitButtonItems = [
    {
      text: "Dashboard",
      icon: "detail-section",
      action: "dashboard"
    },
    {
      text: "Edit",
      icon: "edit",
      action: "update"
    },
    {
      text: "Delete",
      icon: "delete",
      action: "delete"
    }
  ];

  constructor(private _sharedService: SharedService,private _router: Router, private _usersService: UsersService,
    private _notificationService: NotificationService) {
      this.hiddenForMobile = (window.innerWidth <= 1024);
      if (window.innerWidth <= 768) {
        this.fixedWidth = 100;
        this.pagingSettings.pageSize = 5;
        this.state.take = 5;
      }
    };

  public ngOnInit(): void {

    const lsLoggedUser = localStorage.getItem('loggedUser')
    if (lsLoggedUser) {
      this._currentUser = JSON.parse(lsLoggedUser);
    }
    this._setGridData();
  }

  private _setGridData() {
    this._usersService.getGeneralManagers().subscribe((result) => {
      result.forEach(user=> {
        user.genderText = this._sharedService.getGenderText(user.gender);
      } );
      this.usersDataArray = result;
      this.gridData = process(result, this.state);
      this.excelExportData = this.excelExportData.bind(this);
    });
  }

  public dataStateChange(state: DataStateChangeEvent): void {
    if (state && state.group) {
      // state.group.map((group) => (group.aggregates = this.aggregates));
    }
    this.state = state;
    this.gridData = process(this.usersDataArray, this.state);
  }

  public excelExportData(): ExcelExportData {
    const excelState = this.state;
    const excelData = process(this.usersDataArray, {
      group: this.state.group,
      sort: this.state.sort,
      filter: this.state.filter
    });
    const result: ExcelExportData = {
      data: excelData.data,
      group: excelState.group
    };
    return result;
  }

  public onInsertButtonClick(): void {

    this._router.navigate(['staff/generalManagers/insert']);
  }

  public onSplitButtonItemClick(button: { [key: string]: unknown }, dataItem:User): void {
    if (button) {
      switch (button['action']) {
        case 'dashboard':
          this._router.navigate(['staff/generalManagers/dashboard'],{ queryParams: { generalManagerId: dataItem.id } });
          break;
        case 'update':
          this._router.navigate(['staff/generalManagers/update'],{ queryParams: { generalManagerId: dataItem.id } });
          break;
        case 'delete':
          this._deleteRecord(dataItem.id);
          break;
      }
    }
  }

  private _deleteRecord(id: number)
  {
    // console.log('deletedId', id);
    this._usersService.deleteUser(id).subscribe({
      complete:()=> {
        this._setGridData();
        this._notificationService.show({
          content: 'The record has been deleted!',
          animation: { type: 'slide', duration: 500 },
          position: { horizontal: 'center', vertical: 'bottom' },
          type: { style: 'success', icon: true },
          hideAfter: 2000,
        });
      },
      error: (error) => {
        // console.log('error___this._usersService.deleteUser', error)
        const notification: NotificationRef =
          this._notificationService.show({
            content: 'Internal error',
            animation: { type: 'slide', duration: 500 },
            position: { horizontal: 'center', vertical: 'bottom' },
            type: { style: 'error', icon: true },
            hideAfter: 1000,
          });
      }
    });
  }

  public onFilter(input: Event): void {
    const inputValue = (input.target as HTMLInputElement).value;
    if (inputValue) {
      this.state.filter =
        {
          logic: "or",
          filters: [
            {
              field: "firstName",
              operator: "contains",
              value: inputValue,
            },
            {
              field: "lastName",
              operator: "contains",
              value: inputValue,
            },
            {
              field: "code",
              operator: "contains",
              value: inputValue,
            },
            {
              field: "genderText",
              operator: "contains",
              value: inputValue,
            },
            {
              field: "email",
              operator: "contains",
              value: inputValue,
            },
            {
              field: "mobile",
              operator: "contains",
              value: inputValue,
            }
          ],
      };
    } else {
      this.state.filter = undefined;
    }
    this.state.skip = 0;
    this.gridData = process(this.usersDataArray, this.state);
  }
}
