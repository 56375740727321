import { Component, Input, OnInit } from "@angular/core";
import { Worker } from "../../../../shared/models/worker.model";
import { WorkerPictureCompare } from "../../../../shared/models/workerPictureCompare.model";
import { WorkersService } from "../../../workers/workers.service";
import * as moment from "moment";

@Component({
  selector: 'app-compare-pictures-dialog',
  templateUrl: './compare-pictures-dialog.component.html',
  styleUrls: ['./compare-pictures-dialog.component.scss']
})
export class ComparePicturesDialogComponent implements OnInit {
  @Input() workerId: number;
  @Input() locationId: number;
  @Input() reportedDate: string;
  @Input() closeAction: Function;

  public outputWorker: Worker;
  public workerPictureCompare?: WorkerPictureCompare;

  constructor(private _workersService: WorkersService) {
  }

  ngOnInit() {
    this._workersService.getWorkerById(this.workerId).subscribe({
      next: (res) => this.outputWorker = res,
      error: () => console.log('Unable to fetch worker'),
    });
    if (this.locationId) {
      this._workersService.getWorkerPictureByLocationId(this.locationId).subscribe({
        next: (res) => this.workerPictureCompare = res,
        error: () => console.log('Unable to fetch worker picture by location id'),
      });
    } else {
      const date = moment(new Date(this.reportedDate)).format('YYYY-MM-DD');
      this._workersService.getWorkerPictureByDate(date, this.workerId).subscribe({
        next: (res) => this.workerPictureCompare = res,
        error: () => console.log('Unable to fetch worker picture by date or locationId'),
      });
    }
  }

}
