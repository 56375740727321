<div class="profile-page main-content" fxLayout="column" fxLayoutAlign="top center">
  <div *ngIf="(pageName !== 'profile') && (!isSubPage)" class="button-bar" style="width:90vw;margin-bottom:60px; border-bottom:1px solid rgba(0, 0, 0, 0.25); ">
    <button kendoButton themeColor="primary" fillMode="flat" (click)="goToButtonOnClick('listPage')">To the list</button>
    <button kendoButton themeColor="primary" fillMode="flat" *ngIf="pageName === 'update'" (click)="goToButtonOnClick('dashboardPage')">To the dashboard</button>
  </div>
  <div *ngIf="(pageName !== 'profile') && (isSubPage)" class="button-bar" style="width:90vw;margin-bottom:60px; border-bottom:1px solid rgba(0, 0, 0, 0.25); ">
    <button kendoButton themeColor="primary" fillMode="flat" *ngIf="pageName === 'insert' || pageName === 'update'" (click)="goToButtonOnClick('parentDashboardPage')">Dashboard of {{parentUser?.firstName}} {{parentUser?.lastName}}</button>
    <button kendoButton themeColor="primary" fillMode="flat" *ngIf="pageName === 'update'" (click)="goToButtonOnClick('dashboardPage')">Dashboard</button>
  </div>
  <div class="card-container" fxLayout="column" fxLayoutAlign="top center">
      <kendo-card style="max-width: 1024px; width:90vw; padding:30px;">
          <form class="k-form k-form-horizontal" [formGroup]="formGroup">
              <fieldset class="k-form-fieldset">
                  <div class="form-title">
                      <h2>{{mainTitle}}</h2>
                  </div>
                  <div class="row form-section">
                    <div class="row form-row">
                        <div class="col-12 section-title no-border">
                            <h3>Personal data</h3>
                        </div>
                    </div>
                    <div class="row form-row">
                        <div class="col-6 col-md-12">
                            <kendo-formfield orientation="horizontal">
                                <kendo-label class="k-label" [for]="firstName" [text]="'First Name'"> </kendo-label>
                                <kendo-textbox formControlName="firstName" [clearButton]="true" #firstName> </kendo-textbox>
                                <kendo-formerror *ngIf="formGroup.controls['firstName'].hasError('required')"> <i> Error: This field is required </i></kendo-formerror>
                                <kendo-formerror *ngIf="formGroup.controls['firstName'].hasError('maxlength')"> <i> Error: The max allowed length is {{ user.firstName.maxLength }} characters </i></kendo-formerror>
                            </kendo-formfield>
                        </div>
                        <div class="col-6 col-md-12">
                            <kendo-formfield orientation="horizontal">
                                <kendo-label class="k-label" [for]="lastName" [text]="'Last Name'"> </kendo-label>
                                <kendo-textbox formControlName="lastName" [clearButton]="true" #lastName> </kendo-textbox>
                                <kendo-formerror *ngIf="formGroup.controls['lastName'].hasError('required')"> <i> Error: This field is required </i></kendo-formerror>
                                <kendo-formerror *ngIf="formGroup.controls['lastName'].hasError('maxlength')"> <i> Error: The max allowed length is {{ user.lastName.maxLength }} characters </i></kendo-formerror>
                            </kendo-formfield>
                        </div>
                    </div>
                    <div class="row form-row">
                        <div class="col-6 col-md-12">
                            <kendo-formfield orientation="horizontal">
                                <kendo-label class="k-label" [for]="email" [text]="'E-mail'"> </kendo-label>
                                <kendo-textbox formControlName="email" [clearButton]="true" #email> </kendo-textbox>
                                <kendo-formerror *ngIf="formGroup.controls['email'].hasError('maxlength')"> <i> Error: The max allowed length is {{ user.firstName.maxLength }} characters </i></kendo-formerror>
                                <kendo-formerror *ngIf="formGroup.controls['email'].hasError('email')"> <i>Error: Please enter a valid email</i></kendo-formerror>
                            </kendo-formfield>
                        </div>
                        <div class="col-6 col-md-12">
                            <kendo-formfield orientation="horizontal">
                              <kendo-label class="k-label" [for]="mobile" [text]="'Mobile'"> </kendo-label>
                              <kendo-textbox formControlName="mobile" [clearButton]="true" #mobile> </kendo-textbox>
                              <kendo-formerror *ngIf="formGroup.controls['mobile'].hasError('maxlength')"> <i> Error: The max allowed length is {{ user.mobile.maxLength }} characters </i></kendo-formerror>
                            </kendo-formfield>
                        </div>
                    </div>
                    <div class="row form-row">
                        <div class="col-6 col-md-12">
                            <kendo-formfield orientation="horizontal">
                              <label class="k-label"> Gender </label>
                              <ul class="k-radio-list k-list-horizontal">
                                  <li class="k-radio-item">
                                      <input type="radio" #genderMale [value]="1" kendoRadioButton formControlName="gender" />
                                      <kendo-label class="k-radio-label" [for]="genderMale" text="Male"> </kendo-label>
                                  </li>
                                  <li class="k-radio-item">
                                      <input type="radio" #genderFemale [value]="2" kendoRadioButton formControlName="gender" />
                                      <kendo-label class="k-radio-label" [for]="genderFemale" text="Female"> </kendo-label>
                                  </li>
                              </ul>
                            </kendo-formfield>
                        </div>
                    </div>
                  </div>
                  <div class="form-section">
                    <div class="row form-row">
                      <div class="col-12 section-title no-border">
                          <h3>User data</h3>
                      </div>
                    </div>
                    <div class="row form-row">
                      <div class="col-6 col-md-12">
                          <kendo-formfield orientation="horizontal">
                              <kendo-label class="k-label" [for]="username" [text]="'Username'"> </kendo-label>
                              <kendo-textbox formControlName="username" [clearButton]="true" #username> </kendo-textbox>
                              <kendo-formerror *ngIf="formGroup.controls['username'].hasError('required')"> <i> Error: This field is required </i></kendo-formerror>
                              <kendo-formerror *ngIf="formGroup.controls['username'].hasError('maxlength')"> <i> Error: The max allowed length is {{ user.username.maxLength }} characters </i></kendo-formerror>
                          </kendo-formfield>
                      </div>
                      <div class="col-6 col-md-12">
                          <kendo-formfield orientation="horizontal">
                              <kendo-label class="k-label" [for]="password" text="Password"> </kendo-label>
                              <kendo-textbox formControlName="password" [clearButton]="true" #password >
                                <ng-template kendoTextBoxSuffixTemplate *ngIf="pageName==='insert'">
                                  <button
                                    kendoButton
                                    look="clear"
                                    icon="eye"
                                    (click)="toggleVisibility()"
                                  ></button>
                                </ng-template>
                              </kendo-textbox>
                              <kendo-formerror *ngIf="formGroup.controls['password'].hasError('required')"> <i> Error: This field is required </i></kendo-formerror>
                              <kendo-formerror *ngIf="formGroup.controls['password'].hasError('maxlength')"> <i> Error: The max allowed length is {{ user.password.maxLength }} characters </i></kendo-formerror>
                          </kendo-formfield>
                      </div>
                    </div>
                  </div>
                  <div class="form-section">
                    <div class="row form-row">
                      <div class="col-12 section-title no-border">
                          <h3>User data</h3>
                      </div>
                    </div>
                    <div class="row form-row">
                      <div class="col-6 col-md-12">
                          <kendo-formfield orientation="horizontal">
                              <kendo-label class="k-label" [for]="cost" [text]="'Cost'"> </kendo-label>
                              <kendo-textbox formControlName="cost" [clearButton]="true" #cost> </kendo-textbox>
                          </kendo-formfield>
                      </div>
                      <div class="col-6 col-md-12">
                          <kendo-formfield orientation="horizontal">
                              <kendo-label class="k-label" [for]="nfcTag" [text]="'Tag NFC'"> </kendo-label>
                              <kendo-textbox formControlName="nfcTag" [clearButton]="true" #nfcTag> </kendo-textbox>
                              <kendo-formerror *ngIf="formGroup.controls['nfcTag'].hasError('maxlength')"> <i> Error: The max allowed length is {{ user.nfcTag.maxLength }} characters </i></kendo-formerror>
                          </kendo-formfield>
                    </div>
                  </div>
                </div>
                <div class="buttons-section">
                    <div class="row">
                        <div class="col-12 align right">
                            <button kendoButton (click)="cancelChanges()">
                                Reset
                            </button>
                            <button kendoButton [primary]="true" (click)="saveChanges()">
                                Save
                            </button>
                        </div>
                    </div>
                </div>
              </fieldset>
          </form>
      </kendo-card>
  </div>
</div>
