// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The All file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  test: false,
  appSessionUniqueId: 'CFF75D1AD9996',
  userSessionUniqueId: 'FA25815678937',
  serverUrl: 'https://frielbackend.rrsolutionstest.it/api/v1/frontend/',
  //serverUrl: 'http://192.168.1.5:2597/api/v1/frontend/',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
