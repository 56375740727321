import { Component, Input, OnInit, ViewChild} from '@angular/core';
import { SeriesLabelsContentArgs } from '@progress/kendo-angular-charts';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { DataBindingDirective, DataStateChangeEvent, GridDataResult } from '@progress/kendo-angular-grid';
import { IntlService } from '@progress/kendo-angular-intl';
import { aggregateBy, AggregateDescriptor, process, State } from '@progress/kendo-data-query';
import * as moment from 'moment';
import { CostOfCropReport } from 'src/app/shared/models/report.model';
import { ReportsService } from '../reports.service';

@Component({
  selector: 'app-cost-of-crop-report',
  templateUrl: './cost-of-crop-report.component.html',
  styleUrls: ['./cost-of-crop-report.component.scss']
})
export class CostOfCropReportComponent implements OnInit {

  @ViewChild(DataBindingDirective) dataBinding: DataBindingDirective;

  @Input() generalManagerId: number;
  @Input() cropManagerId: number;
  @Input() supervisorId: number;

  public gridData: GridDataResult;
  public mySelection: string[] = [];
  public costOfCropArray: CostOfCropReport[] = [];
  public pageCode: string;

  public hiddenForMobile: boolean = false;
  public fixedWidth: number = 150;

  public state: State = {
    skip: 0
  };

  public showHeader = false;

  public aggregates: AggregateDescriptor[] = [
    { field: 'periodA.costAmount', aggregate: 'sum' },
    { field: 'periodA.costPerc', aggregate: 'sum' },
    { field: 'periodB.costAmount', aggregate: 'sum' },
    { field: 'periodB.costPerc', aggregate: 'sum' },
    { field: 'difference.costAmount', aggregate: 'sum' },
    { field: 'difference.costPerc', aggregate: 'sum' }
  ];
  public total: any;

  public pieDataPeriodA: any[] = [];
  public pieDataPeriodB: any[] = [];

  public dateFromMax: Date = new Date();
  public dateToMax: Date = new Date();

  public selectedShowChartsValue: number;
  public showCharts: boolean = true;

  public dateFrom : Date = new Date(new Date().getFullYear(), 0, 1);
  public dateTo : Date = new Date();
  public labelPeriodA: string;
  public labelPeriodB: string;

  constructor(private _reportsService: ReportsService, private intl: IntlService) {
      this.hiddenForMobile = (window.innerWidth <= 1024);
      if (window.innerWidth <= 768) {
        this.fixedWidth = 100;
      }
    };

  public ngOnInit(): void {

    const lsLoggedUser = localStorage.getItem('loggedUser')
    if (lsLoggedUser) {
      //this._currentUser = JSON.parse(lsLoggedUser);
    }
    this._setGridData();
  }

  private _setGridData() {
    this.costOfCropArray = this._reportsService.costOfCropReportExample();
    if (this.costOfCropArray) {
      // console.log(this.costOfCropArray);
      this.gridData = process(this.costOfCropArray, this.state);
      this.total = aggregateBy(this.costOfCropArray, this.aggregates);

      let tempFrom = moment(new Date(this.dateFrom.getFullYear()-1, this.dateFrom.getMonth(), this.dateFrom.getDate())).format('DD-MM-YYYY');
      let tempTo = moment(new Date(this.dateTo.getFullYear()-1, this.dateTo.getMonth(), this.dateTo.getDate())).format('DD-MM-YYYY');
      this.labelPeriodA = tempFrom + ' | ' + tempTo;

      tempFrom = moment(this.dateFrom).format('DD-MM-YYYY');
      tempTo = moment(this.dateTo).format('DD-MM-YYYY');
      this.labelPeriodB = tempFrom + ' | ' + tempTo;

      this.pieDataPeriodA = [];
      this.pieDataPeriodB = [];
      this.costOfCropArray.forEach(rec => {
        this.pieDataPeriodA.push({ category: rec.cropName, value: rec.periodA.costAmount, percentage: rec.periodA.costPerc });
        this.pieDataPeriodB.push({ category: rec.cropName, value: rec.periodB.costAmount, percentage: rec.periodB.costPerc });
      });
      this.labelContent = this.labelContent.bind(this);
      this.excelExportData = this.excelExportData.bind(this);
    }
  }

  public dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    this.gridData = process(this.costOfCropArray, this.state);
  }

  public excelExportData(): ExcelExportData {
    const excelState = this.state;
    const excelData = process(this.costOfCropArray, {
      group: this.state.group,
      sort: this.state.sort,
      filter: this.state.filter
    });
    const result: ExcelExportData = {
      data: excelData.data,
      group: excelState.group
    };
    return result;
  }

  public labelContent(e: SeriesLabelsContentArgs): any {
    const myLabel = e.category + ': ' + e.value + ' / ' +  (e.percentage ? this.intl.formatNumber(e.percentage,'p2') : '');
    return myLabel;
  }

  public handleShowChartsValue(value: number) {
    this.selectedShowChartsValue = value;
  }

  public applyFilter() {

    // apply dateFrom and dateTo filters
    if ((this.dateFrom != undefined) &&  (this.dateTo != undefined)) {

      if (this.dateFrom > this.dateTo) {
        this.dateFrom = this.dateTo;
      }
      this._setGridData();
    }

    // apply filter assigned
    if (this.selectedShowChartsValue != undefined)  {
      this.showCharts = (this.selectedShowChartsValue === 1);
    }
  }

}
