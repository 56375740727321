<div class="user-dashboard-page main-content" fxLayout="column" fxLayoutAlign="top center">
  <div *ngIf="!isSubPage" class="button-bar" style="width:90vw;">
    <button kendoButton themeColor="primary" fillMode="outline" icon="chevron-left" (click)="goToButtonOnClick('list')">All general managers</button>
  </div>
  <div class="detail-page dashboard" style="width:90vw;">
    <!-- LAYOUT OF DASHBOARD FOR GENERAL MANAGERS-->
    <div class="title-container">
      <h2 *ngIf="outputUser">Dashboard of general manager {{outputUser.firstName}} {{outputUser.lastName}} </h2>
    </div>
    <kendo-tilelayout class="tile" [columns]="12" [rowHeight]="10" *ngIf="outputUser">
      <!-- Own data -->
      <kendo-tilelayout-item [col]="responsiveWrappers.generalManagerData.col" [colSpan]="responsiveWrappers.generalManagerData.colspan" [rowSpan]="responsiveWrappers.generalManagerData.rowspan" >
        <kendo-tilelayout-item-header class="title">
          <h3>General manager data</h3>
        </kendo-tilelayout-item-header>
        <kendo-tilelayout-item-body>
          <div class="content">
            <app-general-manager-detail-data [userData] = "outputUser"></app-general-manager-detail-data>
          </div>
        </kendo-tilelayout-item-body>
      </kendo-tilelayout-item>
      <!-- Own crop managers -->
      <kendo-tilelayout-item [col]="responsiveWrappers.listOfCropManagers.col" [colSpan]="responsiveWrappers.listOfCropManagers.colspan" [rowSpan]="responsiveWrappers.listOfCropManagers.rowspan">
        <kendo-tilelayout-item-header class="list-subpage title" fxLayout="row" fxLayoutAlign="none">
          <div class="main-title" fxFlex="50">
            <h3>
              Own crop managers
            </h3>
          </div>
          <div class="commands-bar" fxFlex="50">
            <button kendoButton icon="plus-circle" (click)="onInsertButtonClick()">Insert</button>
          </div>
        </kendo-tilelayout-item-header>
        <kendo-tilelayout-item-body>
          <div class="content">
            <app-general-manager-detail-related [userData] = "outputUser"></app-general-manager-detail-related>
          </div>
        </kendo-tilelayout-item-body>
      </kendo-tilelayout-item>
    </kendo-tilelayout>
  </div>
</div>
