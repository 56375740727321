import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { User } from 'src/app/shared/models/user.model';
import { SharedService } from 'src/app/shared/shared.service';

@Component({
  selector: 'app-supervisor-detail-data',
  templateUrl: './supervisor-detail-data.component.html',
  styleUrls: ['./supervisor-detail-data.component.scss']
})
export class SupervisorDetailDataComponent implements OnInit {

  @Input() userData: User;
  @Input() generalManager: User;
  @Input() cropManager: User;
  @Input() showEditButton: boolean;
  @Input() showDashboardButton: boolean;
  @Input() showCost: boolean;
  @Input() showNfcTag: boolean;

  constructor(
    private _sharedService: SharedService,
    private _router: Router,
  ) {}

  ngOnInit(): void {
    if (this.showEditButton === undefined) {
      this.showEditButton = true;
    }
    if (this.showCost === undefined) {
      this.showCost = true;
    }
    if (this.showNfcTag === undefined) {
      this.showNfcTag = true;
    }
  }


  public goToEditPage() {
    if (this.generalManager) {
      this._router.navigate(['staff/supervisors/update'],{ queryParams: { generalManagerId: this.generalManager.id, cropManagerId: this.cropManager.id, supervisorId: this.userData.id }});
    } else if (this.cropManager) {
      this._router.navigate(['staff/supervisors/update'],{ queryParams: { cropManagerId: this.cropManager.id, supervisorId: this.userData.id }});
    } else if (this.userData) {
      this._router.navigate(['staff/supervisors/update'],{ queryParams: { supervisorId: this.userData.id }});
    }
  }

  public getGenderText(genderValue: number): string{
    const genderText = this._sharedService.getGenderText(genderValue);
    return genderText;
  }

}
