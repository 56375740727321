import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TextBoxComponent } from '@progress/kendo-angular-inputs';
import { MessageService } from '@progress/kendo-angular-l10n';
import { NotificationRef, NotificationService } from '@progress/kendo-angular-notification';
import * as moment from 'moment';
import { CustomMessagesService } from 'src/app/shared/custom-messages.service';
import { Page } from 'src/app/shared/models/page.model';
import { User, UserDb } from 'src/app/shared/models/user.model';
import { Worker, WorkerDb } from 'src/app/shared/models/worker.model';
import { UsersService } from '../../users/users.service';
import { WorkersService } from '../workers.service';

@Component({
  selector: 'worker-edit',
  templateUrl: './worker-edit.component.html',
  styleUrls: ['./worker-edit.component.scss'],
})
export class WorkerEditComponent implements OnInit {

  public mainTitleInsert: string = 'New Worker';
  public mainTitleUpdate: string = 'Detail of worker %userFullName%';

  public formGroup: FormGroup;
  public parentUserId: number;
  public parentUser: User | null;
  public parentPage: string;
  public userId: number;
  public userTypeId: number;
  public workerDb: WorkerDb;
  public generalManagerId: number;
  public generalManager: User;
  public cropManagerId: number;
  public cropManager: User;
  public cropManagerName: string;
  public supervisorId: number;
  public supervisor: User;
  public supervisorName: string;
  public workerId: number;
  public worker: Worker;
  public pageName: string;
  public listPage: Page;
  public detailPage: Page;
  public dashboardPage: Page;
  public mainTitle: string;

  public workerTypesSource: Array<{ text: string; value: number }> = [];
  public workerTypesArray: Array<{ text: string; value: number }>;

  public parentUsersSource: Array<{ text: string; value: number }> = [];
  public parentUsersArray: Array<{ text: string; value: number }>;

  public unitFarmsSource: Array<{ name: string; id: number }> = [];
  public unitFarmsArray: Array<{ name: string; id: number }>;

  public campsSource: Array<{ name: string; id: number }> = [];
  public campsArray: Array<{ name: string; id: number }>;

  public supervisorsSource: Array<{ name: string; id: number }> = [];
  public supervisorsArray: Array<{ name: string; id: number }> = [];

  public imageDialogOpened = false;

  @ViewChild("username")
  public usernameField!: TextBoxComponent;

  @ViewChild("password")
  public passwordField!: TextBoxComponent;

  public customMsgService: CustomMessagesService;

  constructor(public msgService: MessageService, private _notificationService: NotificationService,
    private _route: ActivatedRoute, private _router: Router, private _usersService: UsersService,  private _workersService: WorkersService, private _formBuilder: FormBuilder) {

      this.customMsgService = this.msgService as CustomMessagesService;
      this.userTypeId = 4;
      this.formGroup = this._formBuilder.group({
        id: { value: null, disabled: true },
        firstName: ['', [Validators.required]],
        lastName: ['', [Validators.required]],
        motherName: [''],
        grandfatherName: [''],
        email: [null, [Validators.email]],
        mobile: [null],
        gender: [1, [Validators.required]],
        code: [null, [Validators.required]],
        jobTitle: [null],
        address: [''],
        city: ['', [Validators.required]],
        dateOfBirth: [null, [Validators.required]],
        workerTypeId: [null, [Validators.required]],
        unitFarmId: [null, [Validators.required]],
        issueDate: [new Date(), [Validators.required]],
        expiryDate: [null, [Validators.required]],
        employmentDate: [null, [Validators.required]],
        agencyName: [''],
        campId: [null],
        supervisorId: [null, [Validators.required]]
      });


  }

  ngOnInit(): void {

    this.pageName = this._route.snapshot.url[2].path;
    const lsWorkerTypes = localStorage.getItem('workerTypes')
    if (lsWorkerTypes) {
      this.workerTypesSource = JSON.parse(lsWorkerTypes);
      this.workerTypesArray = this.workerTypesSource.slice();
      // console.log(this.workerTypesArray);
    }
    const lsUnitFarms = localStorage.getItem('unitFarms')
    if (lsUnitFarms) {
      this.unitFarmsSource = JSON.parse(lsUnitFarms);
      this.unitFarmsArray = this.unitFarmsSource.slice();
      // console.log('this.unitFarmsArray', this.unitFarmsArray);
    }
    const lsCamps = localStorage.getItem('camps')
    if (lsCamps) {
      this.campsSource = JSON.parse(lsCamps);
      this.campsArray = this.campsSource.slice();
      // console.log('this.campsArray', this.campsArray);
    }
    this._usersService.getSupervisors(0).subscribe(supervisors => {
      supervisors.forEach(sv => {
        this.supervisorsSource.push({name: sv.firstName + ' '+ sv.lastName, id: sv.id});
        this.supervisorsArray.push({name: sv.firstName + ' '+ sv.lastName, id: sv.id});
      });
    });
    if (this.pageName === 'update') {
      this._route.queryParams
      .subscribe(params => {
        this.userId = params['workerId'];
        this.generalManagerId = params['generalManagerId'];
        this.cropManagerId = params['cropManagerId'];
        this.supervisorId = params['supervisorId'];
        if (this.supervisorId) {
          this._usersService.getUserById(this.supervisorId).subscribe(user => {
            this.supervisor = user;
          });
          this.formGroup.removeControl('parentUserId');
          this._getUserAndSetForm();
        } else {
          this.formGroup.removeControl('parentUserId');
          this._getUserAndSetForm();
        }
      });
    }
    if (this.pageName === 'insert') {
      this.userId = 0;
      this._route.queryParams
      .subscribe(params => {
        this.generalManagerId = params['generalManagerId'];
        if (this.generalManagerId) {
          this._usersService.getUserById(this.generalManagerId).subscribe(user => {
            this.generalManager = user;
          });
        }
        this.cropManagerId = params['cropManagerId'];
        if (this.cropManagerId) {
          this._usersService.getUserById(this.cropManagerId).subscribe(user => {
            this.cropManager = user;
          });
        }
        this.supervisorId = params['supervisorId'];
        if (this.supervisorId) {
          this._usersService.getUserById(this.supervisorId).subscribe(user => {
            this.supervisor = user;
            this._usersService.getSupervisors(this.cropManagerId).subscribe(result => {
              if (!this.supervisorId) {
                result.forEach(rec => {
                  this.parentUsersSource.push({text: rec.firstName + ' ' + rec.lastName, value: rec.id});
                });
              } else {
                this.formGroup.removeControl('parentUserId');
              }
              this._getUserAndSetForm();
            });
          });
        }
      });
      this.mainTitle = this.mainTitleInsert;
    }
  }

  ngAfterViewInit(): void {

  }

  private _getUserAndSetForm() {
    if (this.pageName === 'update') {
      this._workersService.getWorkerById(this.userId).subscribe(worker => {
        // console.log('myWorker', worker);
        this.workerDb = new WorkerDb(
          worker.id,
          worker.status,
          worker.firstName,
          worker.lastName,
          worker.gender,
          worker.cost,
          worker.email,
          worker.mobile,
          worker.nfcTag,
          worker.code,
          worker.motherName,
          worker.grandfatherName,
          worker.jobTitle,
          worker.address,
          worker.city,
          worker.agencyName,
          worker.dateOfBirth,
          worker.workerTypeId,
          worker.unitFarmId,
          worker.campId,
          worker.supervisorId,
          worker.path,
          worker.userTypeId,
          worker.workerCardDateId,
          worker.issueDate,
          worker.expiryDate,
          worker.employmentDate,
          worker.printedDateTime,
        )
        console.log('workerDb:',this.workerDb);
        this._patchValueForm( this.workerDb);
        this.mainTitle = this.mainTitleUpdate.replace('%userFullName%', this.workerDb.firstName.value + ' '+ this.workerDb.lastName.value);
        this.formGroup.controls['issueDate'].disable();
        this.formGroup.controls['expiryDate'].disable();
        this.formGroup.controls['employmentDate'].disable();
        this.formGroup.controls['supervisorId'].disable();
      });
    }
    if (this.pageName === 'insert') {
      this._patchValueForm( this.workerDb);
    }
  }

  private _patchValueForm(workerDb: WorkerDb) {
    this.formGroup.controls['firstName'].setValue(workerDb.firstName.value);
    this.formGroup.controls['lastName'].setValue(workerDb.lastName.value);
    this.formGroup.controls['motherName'].setValue(workerDb.motherName.value);
    this.formGroup.controls['grandfatherName'].setValue(workerDb.grandfatherName.value);
    this.formGroup.controls['email'].setValue(workerDb.email.value);
    this.formGroup.controls['mobile'].setValue(workerDb.mobile.value);
    this.formGroup.controls['gender'].setValue(workerDb.gender.value);
    this.formGroup.controls['jobTitle'].setValue(workerDb.jobTitle.value);
    this.formGroup.controls['address'].setValue(workerDb.address.value);
    this.formGroup.controls['city'].setValue(workerDb.city.value);
    this.formGroup.controls['agencyName'].setValue(workerDb.agencyName.value);
    this.formGroup.controls['code'].setValue(workerDb.code.value);

    if (workerDb.workerTypeId.value) {
      this.formGroup.controls['workerTypeId'].setValue({text:workerDb.workerTypeId.value === 1 ? 'Permanent User': 'Temporary User',value:workerDb.workerTypeId.value});
    }
    if (workerDb.workerTypeId.value) {
      this.formGroup.controls['unitFarmId'].setValue({id:workerDb.unitFarmId.value});
    }
    if (workerDb.campId.value) {
      this.formGroup.controls['campId'].setValue({id:workerDb.campId.value});
    }
    if (workerDb.supervisorId.value) {
      this.formGroup.controls['supervisorId'].setValue({id:workerDb.supervisorId.value});
    }
    if (workerDb.dateOfBirth.value) {
      this.formGroup.controls['dateOfBirth'].setValue(new Date(workerDb.dateOfBirth.value));
    }
    if (workerDb.issueDate.value) {
      this.formGroup.controls['issueDate'].setValue(new Date(workerDb.issueDate.value));
    }
    if (workerDb.expiryDate.value) {
      this.formGroup.controls['expiryDate'].setValue(new Date(workerDb.expiryDate.value));
    }
    if (workerDb.employmentDate.value) {
      this.formGroup.controls['employmentDate'].setValue(new Date(workerDb.employmentDate.value));
    }

    this._setDefaultValidators(this.formGroup, this.workerDb);

  }

  private _setDefaultValidators(formGroup: FormGroup, workerDb: WorkerDb) {
    Object.keys(formGroup.controls).forEach(formControlKey => {
      const control = formGroup.controls[formControlKey];
      Object.keys(workerDb).forEach(propertyKey => {
        if (formControlKey === propertyKey) {
          const property = Object.getOwnPropertyDescriptor(workerDb,propertyKey);
          if (property?.value.maxLength) {
            if (property.value.maxLength > 0) {
              control.addValidators([Validators.maxLength(property.value.maxLength)]);
            }
          }
          return;
        }
      });
    });
  }

  public saveChanges(): void {
    this.formGroup.markAllAsTouched();
    if (this.formGroup.valid) {
      let worker = this.formGroup.getRawValue() as Worker;
      // console.log('userObject1',worker);
      //custom set fields
      worker.id = this.userId;
      worker.userTypeId = this.userTypeId;
      if (worker.dateOfBirth) worker.dateOfBirth = (moment(new Date(worker.dateOfBirth))).format('YYYY-MM-DD');
      if (worker.issueDate) worker.issueDate = (moment(new Date(worker.issueDate))).format('YYYY-MM-DD');
      if (worker.expiryDate) worker.expiryDate = (moment(new Date(worker.expiryDate))).format('YYYY-MM-DD');
      if (worker.employmentDate) worker.employmentDate = (moment(new Date(worker.employmentDate))).format('YYYY-MM-DD');
      // console.log('workerType', this.formGroup.controls['workerTypeId'].value);
      const workerTypeObj = this.formGroup.controls['workerTypeId'].value;
      worker.workerTypeId = workerTypeObj.value;
      // console.log('unitFarmId', this.formGroup.controls['unitFarmId'].value);
      const unitFarmObj = this.formGroup.controls['unitFarmId'].value;
      worker.unitFarmId = unitFarmObj.id;
      // console.log('campId', this.formGroup.controls['campId'].value);
      const campObj = this.formGroup.controls['campId'].value;
      if (campObj) {
        worker.campId = campObj.id;
      }
      // console.log('supervisorId', this.formGroup.controls['supervisorId'].value);
      const supervisorObj = this.formGroup.controls['supervisorId'].value;
      if (supervisorObj) {
        worker.supervisorId = supervisorObj.id;
      }
      // console.log('userObject2',worker);
      if (this.pageName === 'update') {
        this._workersService.updateWorker(worker).subscribe({
          complete:()=> {
            this._notificationService.show({
              content: 'Record has been saved.',
              animation: { type: 'slide', duration: 500 },
              position: { horizontal: 'center', vertical: 'bottom' },
              type: { style: 'success', icon: true },
              hideAfter: 2000,
            });

            this._route.queryParams
            .subscribe(params => {
              // it remains on the same page
              this.workerDb.firstName.value = worker.firstName;
              this.workerDb.lastName.value = worker.lastName;
              this.mainTitle = this.mainTitleUpdate.replace('%userFullName%', worker.firstName + ' '+ worker.lastName);
            });
          },
          error: (error) => {
            // console.log('error___this._usersService.updateUser', error)
            const notification: NotificationRef =
              this._notificationService.show({
                content: 'Internal error',
                animation: { type: 'slide', duration: 500 },
                position: { horizontal: 'center', vertical: 'bottom' },
                type: { style: 'error', icon: true },
                hideAfter: 1000,
              });

            notification.afterHide?.subscribe(()=> {
              //this._router.navigate(['/staff/supervisors/list']);
            });
          }
        });
      }
      if (this.pageName === 'insert') {
        this._workersService.insertWorker(worker).subscribe({
          complete:()=> {
            this._notificationService.show({
              content: 'Record has been saved.',
              animation: { type: 'slide', duration: 500 },
              position: { horizontal: 'center', vertical: 'bottom' },
              type: { style: 'success', icon: true },
              hideAfter: 2000,
            });

            this._route.queryParams
            .subscribe(params => {
              const cropManagerId = params['supervisorId'];
              if (cropManagerId) {
                // go to crop manager dashboard
                this.goToButtonOnClick('supervisorDashboard');
              } else {
                this.goToButtonOnClick('list');
              }
            });
          },
          error: (error) => {
            // console.log('error___this._workersService.insertWorker', error)
            const notification: NotificationRef =
              this._notificationService.show({
                content: 'Internal error',
                animation: { type: 'slide', duration: 500 },
                position: { horizontal: 'center', vertical: 'bottom' },
                type: { style: 'error', icon: true },
                hideAfter: 1000,
              });

            notification.afterHide?.subscribe(()=> {
              //this._router.navigate(['/staff/workers/list']);
            });
          }
        });
      }
    };
  }

  public cancelChanges(): void {
      this._patchValueForm(this.workerDb);
  }

  public goToButtonOnClick (pageCode : string): void {
    if (pageCode==='list') {
      this._router.navigate(['/staff/workers/list']);
    }
    if (pageCode==='deletedList') {
      this._router.navigate(['/staff/workers/deleted']);
    }
    if (pageCode==='dashboard') {
      this._router.navigate(['staff/workers/dashboard'],{ queryParams: { generalManagerId: this.generalManagerId, cropManagerId: this.cropManagerId, supervisorId: this.supervisorId, workerId: this.userId } });
    }
    if (pageCode==='supervisorDashboard') {
      this._router.navigate(['staff/supervisors/dashboard'],{ queryParams: { generalManagerId: this.generalManagerId, cropManagerId: this.cropManagerId, supervisorId: this.supervisorId } });
    }
  }

  public handleWorkerTypeFilter(value: any) {
    this.workerTypesArray = this.workerTypesSource.filter(
      (s) => s.text.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  }

  public handleUnitFarmFilter(value: any) {
    this.unitFarmsArray = this.unitFarmsSource.filter(
      (s) => s.name.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  }

  public handleCampFilter(value: any) {
    this.campsArray = this.campsSource.filter(
      (s) => s.name.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  }

  public handleSupervisorFilter(value: any) {
    this.supervisorsArray = this.supervisorsSource.filter(
      (s) => s.name.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  }

  public handleParentUserFilter(value: any) {
    this.parentUsersArray = this.parentUsersSource.filter(
      (s) => s.text.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  }

  public openCloseImageDialog(status: boolean): void {
    this.imageDialogOpened = status;
  }

}
