<mat-toolbar class="app-toolbar mat-elevation-z6 no-print">
  <button mat-icon-button (click)="onToggleSidenav()" class="mat-icon-button">
    <mat-icon>{{menuIconName}}</mat-icon>
  </button>
  <span class="spacer"></span>
  <div fxFlex fxLayout fxLayoutAlign="flex-end">
    <ul fxLayout fxLayoutGap="10px" style="list-style: none;">
      <li class="account_info"><span>Welcome {{ currentUser.firstName }} {{ currentUser.lastName }}</span></li>
      <li>
        <button mat-icon-button (click)="viewProfile()" class="mat-icon-button">
          <mat-icon>account_circle</mat-icon>
        </button>
      </li>
      <li>
        <button mat-icon-button (click)="doUserLogout()" class="mat-icon-button">
          <mat-icon>logout</mat-icon>
        </button>
      </li>
    </ul>
  </div>
</mat-toolbar>
