import { Component, Input, OnInit } from '@angular/core';
import { latLng, marker, tileLayer } from 'leaflet';
import { WorkerPictureCompare } from 'src/app/shared/models/workerPictureCompare.model';

@Component({
  selector: 'app-worker-location-map',
  templateUrl: './worker-location-map.component.html',
  styleUrls: ['./worker-location-map.component.scss']
})
export class WorkerLocationMapComponent implements OnInit {

  @Input() workerPictureCompare: WorkerPictureCompare;

  public options:any = {};
  public layers: any;

  constructor() { }

  ngOnInit(): void {

    const latitude = parseFloat(this.workerPictureCompare.latitude);
    const longitude = parseFloat(this.workerPictureCompare.longitude);

    this.options = {
      layers: [
        tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', { maxZoom: 18, attribution: '...' }),
        marker([latitude, longitude]).bindPopup('<b>Latitude</b>:' + this.workerPictureCompare.latitude + '<br /><b>Longitude</b>:' + this.workerPictureCompare.longitude)
      ],
      zoom: 15,
      center: latLng(latitude, longitude)
    }
  }

}
