import { WorkerType } from "./worker-type.model";

type cString = {
  name: string;
  value?: string | null;
  defaultValue: string | null;
  isNullable: boolean;
  maxLength: number;
};

type cNumber = {
  name: string;
  value?: number | null;
  defaultValue: number | null;
  isNullable: boolean;
};

export class User {
  id: number;
  parentUserId: number | null;
  firstName: string;
  lastName: string;
  gender: number;
  genderText: string;
  username: string;
  password: string;
  email: string;
  mobile: string;
  dateOfBirth: string;
  cost: number;
  nfcTag: string;
  code: string;
  jobTitle: string;
  address: string;
  workerTypeId: number;
  workerType: WorkerType;
  userTypeId: number;
  userParent: User;
  userCardDateId: number;
  issueDate: string;
  expiryDate: string;
  employmentDate: string;
  printedDateTime: string;
}

export class UserDb {
  public id: cNumber = {
    name: 'id',
    defaultValue: 0,
    isNullable: false,
  };

  public parentUserId: cNumber = {
    name: 'parentUserId',
    defaultValue: 0,
    isNullable: false,
  };

  public firstName: cString = {
    name: 'firstName',
    defaultValue: '',
    isNullable: false,
    maxLength: 255,
  };

  public lastName: cString = {
    name: 'lastName',
    defaultValue: '',
    isNullable: false,
    maxLength: 255,
  };

  public gender: cNumber = {
    name: 'gender',
    defaultValue: 0, //0 = Not specified, 1 = Male, 2 = Female
    isNullable: false,
  };

  public genderText: cString = {
    name: 'genderText',
    defaultValue: '',
    isNullable: true,
    maxLength: 255,
  };

  public username: cString = {
    name: 'username',
    defaultValue: '',
    isNullable: false,
    maxLength: 255,
  };

  public password: cString = {
    name: 'password',
    defaultValue: '',
    isNullable: false,
    maxLength: 255,
  };

  public email: cString = {
    name: 'email',
    defaultValue: '',
    isNullable: true,
    maxLength: 255,
  };

  public dateOfBirth: cString = {
    name: 'dateOfBirth',
    defaultValue: '',
    isNullable: true,
    maxLength: 10,
  };

  public mobile: cString = {
    name: 'mobile',
    defaultValue: '',
    isNullable: true,
    maxLength: 255,
  };

  public cost: cNumber = {
    name: 'cost',
    defaultValue: 0,
    isNullable: false,
  };

  public nfcTag: cString = {
    name: 'nfcTag',
    value: '',
    defaultValue: null,
    isNullable: true,
    maxLength: 255,
  };

  public userTypeId: cNumber = {
    name: 'userTypeId',
    defaultValue: 0,
    isNullable: false,
  };

  public code: cString = {
    name: 'code',
    value: '',
    defaultValue: null,
    isNullable: true,
    maxLength: 255,
  };

  public jobTitle: cString = {
    name: 'jobTitle',
    value: '',
    defaultValue: null,
    isNullable: true,
    maxLength: 255,
  };

  public address: cString = {
    name: 'address',
    value: '',
    defaultValue: null,
    isNullable: true,
    maxLength: 512,
  };

  public workerTypeId: cNumber = {
    name: 'workerTypeId',
    defaultValue: null,
    isNullable: true,
  };

  public userCardDateId: cNumber = {
    name: 'userCardDateId',
    defaultValue: null,
    isNullable: true,
  };

  public issueDate: cString = {
    name: 'issueDate',
    value: null,
    defaultValue: null,
    isNullable: true,
    maxLength: 10,
  };

  public expiryDate: cString = {
    name: 'expiryDate',
    value: null,
    defaultValue: null,
    isNullable: true,
    maxLength: 10,
  };

  public employmentDate: cString = {
    name: 'employmentDate',
    value: null,
    defaultValue: null,
    isNullable: true,
    maxLength: 10,
  };

  public printedDateTime: cString = {
    name: 'printedDateTime',
    value: null,
    defaultValue: null,
    isNullable: true,
    maxLength: 20,
  };

  constructor(
    idValue: number,
    userTypeId: number,
    firstNameValue: string,
    lastNameValue: string,
    genderValue: number,
    usernameValue: string,
    passwordValue: string,
    costValue: number,
    emailValue?: string,
    mobileValue?: string,
    nfcTagValue?: string,
    codeValue? :string,
    jobTitleValue? :string,
    addressValue? :string,
    dateOfBirthValue?: string,
    workerTypeIdValue? :number,
    userCardDateIdValue? :number,
    issueDateValue? :string,
    expiryDateValue? :string,
    employmentDateValue? :string,
    printedDateTimeValue? :string,
    parentUserIdValue?: number | null
  ) {
    this.id.value = idValue;
    this.firstName.value = firstNameValue;
    this.lastName.value = lastNameValue;
    this.gender.value = genderValue;
    this.username.value = usernameValue;
    this.password.value = passwordValue;
    this.email.value = emailValue;
    this.mobile.value = mobileValue;
    this.cost.value = costValue;
    this.nfcTag.value = nfcTagValue;
    this.userTypeId.value = userTypeId;
    this.code.value = codeValue;
    this.jobTitle.value = jobTitleValue;
    this.address.value = addressValue;
    this.dateOfBirth.value = dateOfBirthValue;
    this.workerTypeId.value = workerTypeIdValue;
    this.userCardDateId.value = userCardDateIdValue;
    this.issueDate.value = issueDateValue;
    this.expiryDate.value = expiryDateValue;
    this.employmentDate.value = employmentDateValue;
    this.printedDateTime.value = printedDateTimeValue;
    this.parentUserId.value = parentUserIdValue;
  }
}
