<div class="profile-page main-content">
  <div class="button-bar" style="width:100%">
    <button kendoButton themeColor="primary" fillMode="outline" icon="chevron-left" *ngIf="workerName && !paramLocationId" (click)="handleGoBackToDashboard()">Dashboard of worker <b>{{workerName}}</b></button>
    <button kendoButton themeColor="primary" fillMode="outline" icon="chevron-left" *ngIf="paramLocationId" (click)="back()">Go back</button>

  </div>
  <div class="detail-page dashboard">
    <div class="search-bar" fxLayout="row" fxLayoutAlign="none" fxLayoutGap="30" *ngIf="!paramLocationId" style="padding-top:10px !important;">
      <div class="search-field">
        <span class="k-label"><b>Select date: </b></span>
        <kendo-datepicker
          name="searchDate"
          [(value)]="searchDate"
          [max]="maxDate"
          [placeholder]="''"
          [style]="'width:180px'"
          [format]="'dd-MM-yyyy'"
        ></kendo-datepicker>
      </div>
      <div class="search-field" fxLayout="row" fxLayoutAlign=" none" fxLayoutGap="10">
        <button kendoButton themeColor="primary" icon="search" (click)="applyFilter()">Search</button>
      </div>
    </div>
    <div class="title-container" *ngIf="workerName">
      <h2>Worker {{workerName}} - Compare pictures of {{currentDate | date: 'dd-MM-yyyy'}}</h2>
      <h4 *ngIf="!workerPictureCompare" style="margin-top:100px; text-align: center;">No data to show!</h4>
    </div>
    <kendo-tilelayout class="tile" [columns]="12" [rowHeight]="10" *ngIf="workerPictureCompare">
      <!-- Own data -->
      <kendo-tilelayout-item [row]="1" [col]="1" [colSpan]="3" [rowSpan]="11">
        <kendo-tilelayout-item-header class="title">
          <h3>Worker data</h3>
        </kendo-tilelayout-item-header>
        <kendo-tilelayout-item-body>
          <div class="content" *ngIf="outputWorker">
            <app-worker-detail-data [workerData] = "outputWorker" [generalManager] = "outputGeneralManager" [cropManager] = "outputCropManager" [supervisor] = "outputSupervisor" [showEditButton] = false></app-worker-detail-data>
          </div>
        </kendo-tilelayout-item-body>
      </kendo-tilelayout-item>
      <!-- Supervisor data -->
      <kendo-tilelayout-item [row]="1" [col]="4" [colSpan]="3" [rowSpan]="11">
        <kendo-tilelayout-item-header class="title">
          <h3>Supervisor data (who took picture)</h3>
        </kendo-tilelayout-item-header>
        <kendo-tilelayout-item-body>
          <div class="content" *ngIf="reporter">
            <app-supervisor-detail-data [userData] = "reporter" [generalManager] = "outputGeneralManager" [cropManager] = "outputCropManager" [showEditButton] = false></app-supervisor-detail-data>
          </div>
        </kendo-tilelayout-item-body>
      </kendo-tilelayout-item>
      <!-- Card picture -->
      <kendo-tilelayout-item [row]="12" [col]="1" [colSpan]="3" [rowSpan]="15">
        <kendo-tilelayout-item-header class="title">
          <h3>Card picture </h3>
        </kendo-tilelayout-item-header>
        <kendo-tilelayout-item-body>
          <div class="content" *ngIf="outputWorker">
            <app-worker-detail-picture [workerData] = "outputWorker"></app-worker-detail-picture>
          </div>
        </kendo-tilelayout-item-body>
      </kendo-tilelayout-item>
      <!-- Picture -->
      <kendo-tilelayout-item [row]="12" [col]="4" [colSpan]="3" [rowSpan]="15">
        <kendo-tilelayout-item-header class="title">
          <h3>Picture of {{currentDate | date:'dd-MM-yyyy'}}</h3>
        </kendo-tilelayout-item-header>
        <kendo-tilelayout-item-body>
          <div class="content">
            <div class="picture-wrapper" *ngIf="workerPictureCompare && workerPictureCompare.picturePath">
              <img [src]="workerPictureCompare.picturePath" [alt]="workerPictureCompare.workerLastName + ' ' + workerPictureCompare.workerFirstName">
            </div>
          </div>
        </kendo-tilelayout-item-body>
      </kendo-tilelayout-item>
      <!-- Comments -->
      <kendo-tilelayout-item [row]="27" [col]="1" [colSpan]="12" [rowSpan]="9">
        <kendo-tilelayout-item-header class="title">
          <h3>Location infos of {{currentDate | date:'dd-MM-yyyy'}}</h3>
        </kendo-tilelayout-item-header>
        <kendo-tilelayout-item-body>
          <div class="content">
            <div class="comment-wrapper" *ngIf="workerPictureCompare">
              <span class="holiday" *ngIf="workerPictureCompare.isHoliday">The worker is in holiday</span>
              <span class="comment" *ngIf="workerPictureCompare.comment"><b>Comment:</b> {{workerPictureCompare.comment}}</span>
            </div>
          </div>
        </kendo-tilelayout-item-body>
      </kendo-tilelayout-item>
      <!-- Location -->
      <kendo-tilelayout-item [row]="1" [col]="7" [colSpan]="6" [rowSpan]="26">
        <kendo-tilelayout-item-header class="title">
          <h3>Location map of {{currentDate | date:'dd-MM-yyyy'}}</h3>
        </kendo-tilelayout-item-header>
        <kendo-tilelayout-item-body>
          <div class="content">
            <div class="map-container" *ngIf="workerPictureCompare" style="width:100%; height:100%;">
              <app-worker-location-map tyle="width:100%; height:100%;" [workerPictureCompare] = workerPictureCompare ></app-worker-location-map>
            </div>
          </div>
        </kendo-tilelayout-item-body>
      </kendo-tilelayout-item>
    </kendo-tilelayout>
  </div>
</div>
