import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TextBoxComponent } from '@progress/kendo-angular-inputs';
import { MessageService } from '@progress/kendo-angular-l10n';
import { NotificationRef, NotificationService } from '@progress/kendo-angular-notification';
import * as moment from 'moment';
import { CustomMessagesService } from 'src/app/shared/custom-messages.service';
import { Page } from 'src/app/shared/models/page.model';
import { User, UserDb } from 'src/app/shared/models/user.model';
import { UsersService } from '../../users/users.service';

@Component({
  selector: 'app-general-manager-edit',
  templateUrl: './general-manager-edit.component.html',
  styleUrls: ['./general-manager-edit.component.scss'],
})
export class GeneralManagerEditComponent implements OnInit {

  public mainTitleInsert: string = 'New General Manager';
  public mainTitleUpdate: string = 'General Manager: %userFullName%';

  public formGroup: FormGroup;
  public parentUserId: number;
  public parentUser: User | null;
  public parentPage: string;
  public userId: number;
  public userTypeId: number;
  public user: UserDb;
  public pageName: string;
  public listPage: Page;
  public detailPage: Page;
  public dashboardPage: Page;
  public mainTitle: string;

  public workerTypesSource: Array<{ text: string; value: number }> = [];
  public workerTypesArray: Array<{ text: string; value: number }>;

  public parentUsersSource: Array<{ text: string; value: number }> = [];
  public parentUsersArray: Array<{ text: string; value: number }>;

  @ViewChild("username")
  public usernameField!: TextBoxComponent;

  @ViewChild("password")
  public passwordField!: TextBoxComponent;

  public customMsgService: CustomMessagesService;

  constructor(public msgService: MessageService, private _notificationService: NotificationService,
    private _route: ActivatedRoute, private _router: Router, private _usersService: UsersService, private _formBuilder: FormBuilder) {
      this.customMsgService = this.msgService as CustomMessagesService;
      this.userTypeId = 1;
      this.formGroup = this._formBuilder.group({
        id: { value: null, disabled: true },
        firstName: ['', [Validators.required]],
        lastName: ['', [Validators.required]],
        email: [null, [Validators.email]],
        mobile: [null],
        gender: [1],
        username: ['', [Validators.required]],
        password: ['', [Validators.required]],
        /*hidden*/
        /*
        cost: [null],
        nfcTag: [null],
        */
        code: [null],
        jobTitle: [null],
        address: [''],
        dateOfBirth: [null],
        workerTypeId: [null, [Validators.required]],
        issueDate: [null],
        expiryDate: [null],
        employmentDate: [null],
      });
  }

  ngOnInit(): void {
    this.pageName = this._route.snapshot.url[2].path;
    const lsWorkerTypes = localStorage.getItem('workerTypes')
    if (lsWorkerTypes) {
      this.workerTypesSource = JSON.parse(lsWorkerTypes);
      this.workerTypesArray = this.workerTypesSource.slice();
    }
    if (this.pageName === 'update') {
      this._route.queryParams
      .subscribe(params => {
        this.userId = params['generalManagerId'];
      });
    }
    if (this.pageName === 'insert') {
      this.userId = 0;
      this.mainTitle = this.mainTitleInsert;
      this.formGroup.controls['username'].enable();
      this.formGroup.controls['password'].enable();
    }
    this._getUserAndSetForm();
  }

  ngAfterViewInit(): void {

    this.toggleVisibility();
  }

  private _getUserAndSetForm() {
    if (this.pageName === 'update') {
      this._usersService.getUserById(this.userId).subscribe(user => {
        this.user = new UserDb(
          user.id,
          user.userTypeId,
          user.firstName,
          user.lastName,
          user.gender,
          user.username,
          'defaultPassword',
          user.cost,
          user.email,
          user.mobile,
          user.nfcTag,
          user.code,
          user.jobTitle,
          user.address,
          user.dateOfBirth,
          user.workerTypeId,
          user.userCardDateId,
          user.issueDate,
          user.expiryDate,
          user.employmentDate,
          user.printedDateTime,
          user.parentUserId
        );
        // console.log('userAPI:',user);
        this._patchValueForm( this.user);
        this.mainTitle = this.mainTitleUpdate.replace('%userFullName%', this.user.firstName.value + ' '+ this.user.lastName.value);
      });
    }
    if (this.pageName === 'insert') {
      this.user = new UserDb(
        0,
        1, //general manager
        '',
        '',
        1,
        '',
        '',
        0,
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        1, // permanent user
        0,
        '',
        '',
        '',
        '',
        null //parentId
      );
    }
    // console.log('user:',this.user);
    this._patchValueForm( this.user);
    this.mainTitle = '';
  }

  private _patchValueForm(user: UserDb) {
    if (user) {
      this.formGroup.controls['firstName'].setValue(user.firstName.value);
      this.formGroup.controls['lastName'].setValue(user.lastName.value);
      this.formGroup.controls['email'].setValue(user.email.value);
      this.formGroup.controls['mobile'].setValue(user.mobile.value);
      this.formGroup.controls['gender'].setValue(user.gender.value);
      this.formGroup.controls['username'].setValue(user.username.value);
      this.formGroup.controls['password'].setValue(user.username.value);
      this.formGroup.controls['jobTitle'].setValue(user.jobTitle.value);
      this.formGroup.controls['address'].setValue(user.address.value);
      this.formGroup.controls['code'].setValue(user.code.value);
      if (user.workerTypeId.value) {
        this.formGroup.controls['workerTypeId'].setValue({text:user.workerTypeId.value === 1 ? 'Permanent User': 'Temporary User',value:user.workerTypeId.value});
      }
      if (user.dateOfBirth.value) {
        this.formGroup.controls['dateOfBirth'].setValue(new Date(user.dateOfBirth.value));
      }
      if (user.issueDate.value) {
        this.formGroup.controls['issueDate'].setValue(new Date(user.issueDate.value));
      }
      if (user.expiryDate.value) {
        this.formGroup.controls['expiryDate'].setValue(new Date(user.expiryDate.value));
      }
      if (user.employmentDate.value) {
        this.formGroup.controls['employmentDate'].setValue(new Date(user.employmentDate.value));
      }
      if (this.pageName !== 'insert') {
        this.formGroup.controls['username'].disable();
        this.formGroup.controls['password'].disable();
        this._setDefaultValidators(this.formGroup, this.user);
      }
    }
  }

  private _setDefaultValidators(formGroup: FormGroup, userDb: UserDb) {
    Object.keys(formGroup.controls).forEach(formControlKey => {
      const control = formGroup.controls[formControlKey];
      Object.keys(userDb).forEach(propertyKey => {
        if (formControlKey === propertyKey) {
          const property = Object.getOwnPropertyDescriptor(userDb,propertyKey);
          if (property?.value.maxLength) {
            if (property.value.maxLength > 0) {
              control.addValidators([Validators.maxLength(property.value.maxLength)]);
            }
          }
          return;
        }
      });
    });
  }

  public saveChanges(): void {
    this.formGroup.markAllAsTouched();
    if ( this.formGroup.valid) {
      let user = this.formGroup.getRawValue() as User;
      // console.log('userObject1',user);
      //custom set fields
      user.id = this.userId;
      user.userTypeId = this.userTypeId;
      user.parentUserId = null;
      if (user.dateOfBirth) user.dateOfBirth = (moment(new Date(user.dateOfBirth))).format('YYYY-MM-DD');
      if (user.issueDate) user.issueDate = (moment(new Date(user.issueDate))).format('YYYY-MM-DD');
      if (user.expiryDate) user.expiryDate = (moment(new Date(user.expiryDate))).format('YYYY-MM-DD');
      if (user.employmentDate) user.employmentDate = (moment(new Date(user.employmentDate))).format('YYYY-MM-DD');
      // console.log('workerType', this.formGroup.controls['workerTypeId'].value);
      const workerTypeObj = this.formGroup.controls['workerTypeId'].value
      user.workerTypeId = workerTypeObj.value;
      user.parentUserId = null;
      // console.log('userObject2',user);
      if (this.pageName === 'update') {
        this._usersService.updateUser(user).subscribe({
          complete:()=> {
            this._notificationService.show({
              content: 'Record has been saved.',
              animation: { type: 'slide', duration: 500 },
              position: { horizontal: 'center', vertical: 'bottom' },
              type: { style: 'success', icon: true },
              hideAfter: 2000,
            });

            this._route.queryParams
              .subscribe(params => {
                const generalManagerId = params['generalManagerId'];
                if (generalManagerId) {
                  // it remains on the same page
                  this.user.firstName.value = user.firstName;
                  this.user.lastName.value = user.lastName;
                  this.mainTitle = this.mainTitleUpdate.replace('%userFullName%', user.firstName + ' '+ user.lastName);
                } else {
                  this._router.navigate(['/staff/generalManagers/list']);
                }
              });
          },
          error: (error) => {
            // console.log('error___this._usersService.updateUser', error)
            const notification: NotificationRef =
              this._notificationService.show({
                content: 'Internal error',
                animation: { type: 'slide', duration: 500 },
                position: { horizontal: 'center', vertical: 'bottom' },
                type: { style: 'error', icon: true },
                hideAfter: 1000,
              });

            notification.afterHide?.subscribe(()=> {
              //this._router.navigate(['/staff/generalManager/list']);
            });
          }
        });
      }
      if (this.pageName === 'insert') {
        // console.log('userToSave', user);
        this._usersService.insertUser(user).subscribe({
          complete:()=> {
            this._notificationService.show({
              content: 'Record has been saved.',
              animation: { type: 'slide', duration: 500 },
              position: { horizontal: 'center', vertical: 'bottom' },
              type: { style: 'success', icon: true },
              hideAfter: 2000,
            });
            this._router.navigate(['/staff/generalManagers/list']);
          },
          error: (error) => {
            // console.log('error___this._usersService.insertUser', error)
            const notification: NotificationRef =
              this._notificationService.show({
                content: 'Internal error',
                animation: { type: 'slide', duration: 500 },
                position: { horizontal: 'center', vertical: 'bottom' },
                type: { style: 'error', icon: true },
                hideAfter: 1000,
              });

            notification.afterHide?.subscribe(()=> {
              //this._router.navigate(['/staff/generalManager/list']);
            });
          }
        });
      }
    };
  }

  public cancelChanges(): void {
      this._patchValueForm(this.user);
  }

  public toggleVisibility(): void {
    const passwordEl =this.passwordField.input.nativeElement;
    passwordEl.type = passwordEl.type === "password" ? "text" : "password";
  }

  public goToButtonOnClick (pageCode : string): void {
    if (pageCode==='list') {
      this._router.navigate(['/staff/generalManagers/list']);
    }
    if (pageCode==='dashboard') {
      this._router.navigate(['staff/generalManagers/dashboard'],{ queryParams: { generalManagerId: this.userId } });
    }
  }

  public handleWorkerTypeFilter(value: any) {
    this.workerTypesArray = this.workerTypesSource.filter(
      (s) => s.text.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  }
}
