import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import {
  DataBindingDirective,
  DataStateChangeEvent,
  GridDataResult,
  MultipleSortSettings,
  PagerPosition
} from '@progress/kendo-angular-grid';
import { PagerType } from "@progress/kendo-angular-grid/pager/pager-settings";
import { process, State } from '@progress/kendo-data-query';
import { User } from 'src/app/shared/models/user.model';
import { Worker } from 'src/app/shared/models/worker.model';
import { SharedService } from 'src/app/shared/shared.service';
import { WorkersService } from 'src/app/modules/workers/workers.service';

@Component({
  selector: 'app-supervisor-detail-related',
  templateUrl: './supervisor-detail-related.component.html',
  styleUrls: ['./supervisor-detail-related.component.scss']
})

export class SupervisorDetailRelatedComponent implements OnInit {

  @ViewChild(DataBindingDirective) dataBinding: DataBindingDirective;

  public pageCode: string;

  @Input() userData: User;
  @Input() generalManager: User;
  @Input() cropManager: User;

  public hiddenForMobile: boolean = false;
  public fixedWidth: number = 150;

  public relatedUsers: Worker[];

  public gridData: GridDataResult;
  public mySelection: string[] = [];

  private _varPagerTypes: PagerType = 'numeric';
  private _varPagerPosition: PagerPosition = 'bottom';

  public pagingSettings = {
    pagerTypes: this._varPagerTypes,
    type: 'input',
    // buttonCount: 5,
    info: true,
    previousNext: true,
    position: this._varPagerPosition,
    pageSize: 5,
    responsive: true,
    pageSizes: [
      5,
      10,
      20
    ]
  };

  public sortingSettings: MultipleSortSettings = {
    allowUnsort: true
  };

  public state: State = {
    skip: 0,
    take: this.pagingSettings.pageSize,
    // Initial filter descriptor
  };

  public showHeader = false;

  public splitButtonItems = [
    {
      text: "Dashboard",
      icon: "detail-section",
      action: "dashboard"
    },
    {
      text: "Update",
      icon: "edit",
      action: "update"
    }
    /* use in the future
    {
      text: "Delete",
      icon: "delete",
      action: "delete"
    }
    */
  ];

  constructor(private _sharedService: SharedService,private _router: Router, private _workersService: WorkersService) {
    this.hiddenForMobile = (window.innerWidth <= 1024);
    this.fixedWidth = (window.innerWidth <= 768) ? 100 : this.fixedWidth;
  };

  public ngOnInit(): void {
    this._workersService.getWorkers(this.userData.id).subscribe(result => {
      result = result.filter((w) => w.status === 1);
      result.forEach(user=> {
        user.genderText = this._sharedService.getGenderText(user.gender);
      } );
      this.relatedUsers = result;
      // console.log('this.relatedUsers',this.relatedUsers);
      this._setGridData();
    });
  }

  private _setGridData() {
      this.gridData = process(this.relatedUsers, this.state);
  }

  public dataStateChange(state: DataStateChangeEvent): void {
    if (state && state.group) {
      // state.group.map((group) => (group.aggregates = this.aggregates));
    }
    this.state = state;
    this.gridData = process(this.relatedUsers, this.state);
  }

  public onSplitButtonItemClick(button: { [key: string]: unknown }, dataItem:User): void {
    if (button) {
      switch (button['action']) {
        case 'dashboard':
          if (this.generalManager) {
            this._router.navigate(['staff/workers/dashboard'],{ queryParams: { generalManagerId: this.generalManager.id, cropManagerId: this.cropManager.id, supervisorId: this.userData.id, workerId: dataItem.id  } });
          } else if (this.cropManager) {
            this._router.navigate(['staff/workers/dashboard'],{ queryParams: { cropManagerId: this.cropManager.id, supervisorId: this.userData.id, workerId: dataItem.id } });
          } else {
            this._router.navigate(['staff/workers/dashboard'],{ queryParams: { supervisorId: this.userData.id, workerId: dataItem.id } });
          }
          break;
        case 'update':
          if (this.generalManager) {
            this._router.navigate(['staff/workers/update'],{ queryParams: { generalManagerId: this.generalManager.id, cropManagerId: this.cropManager.id, supervisorId: this.userData.id, workerId: dataItem.id  } });
          } else if (this.cropManager) {
            this._router.navigate(['staff/workers/update'],{ queryParams: { cropManagerId: this.cropManager.id, supervisorId: this.userData.id, workerId: dataItem.id } });
          } else {
            this._router.navigate(['staff/workers/update'],{ queryParams: { supervisorId: this.userData.id, workerId: dataItem.id } });
          }
          break;
      }
    }
  }

}

