import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, throwError } from 'rxjs';
import { ComparePicturesReport, CostOfCropReport, DailyHoursReport, WorkerAtCropReport, WorkersSalaryReport } from 'src/app/shared/models/report.model';
import { environment } from 'src/environments/environment';

enum EndpointRoutes {
  REPORTS_WORKERS_AT_CROP = 'reports/workers_at_crop',
  REPORTS_DAILY_HOURS = 'reports/dailyHoursReport/get',
  REPORTS_COMPARE_PICTURES = 'reports/comparePicturesReport/get'
}

@Injectable({
  providedIn: 'root'
})
export class ReportsService {

  constructor(private _http: HttpClient) { }

  public workerAtCropReportExample(): WorkerAtCropReport[] {

    let workerAtCropReport: WorkerAtCropReport[] = [];
    let rec1 = new WorkerAtCropReport();
    //rec banana
    rec1.cropId = 1;
    rec1.cropName = 'Banana';
    rec1.periodA = {
      dateFrom: '2021-01-01',
      dateTo: '2021-12-31',
      quantityAmount: this._randomValue(50,1),
      quantityPerc: 0,
      hoursAmount: this._randomValue(1000,100),
      hoursPerc: 0
    };
    rec1.periodB = {
      dateFrom: '2022-01-01',
      dateTo: '2022-12-31',
      quantityAmount: this._randomValue(50,1),
      quantityPerc: 0,
      hoursAmount: this._randomValue(1000,100),
      hoursPerc: 0
    };
    rec1.difference = {
      quantityAmount: rec1.periodB.quantityAmount - rec1.periodA.quantityAmount,
      quantityPerc: 0,
      hoursAmount: rec1.periodB.hoursAmount - rec1.periodA.hoursAmount,
      hoursPerc: 0
    };
    workerAtCropReport.push(rec1);

    //rec mango
    let rec2 = new WorkerAtCropReport();
    rec2.cropId = 2;
    rec2.cropName = 'Mango';
    rec2.periodA = {
      dateFrom: '2021-01-01',
      dateTo: '2021-12-31',
      quantityAmount: this._randomValue(50,1),
      quantityPerc: 0,
      hoursAmount: this._randomValue(1000,100),
      hoursPerc: 0
    };
    rec2.periodB = {
      dateFrom: '2022-01-01',
      dateTo: '2022-12-31',
      quantityAmount: this._randomValue(50,1),
      quantityPerc: 0,
      hoursAmount: this._randomValue(1000,100),
      hoursPerc: 0
    };
    rec2.difference = {
      quantityAmount: rec2.periodB.quantityAmount - rec2.periodA.quantityAmount,
      quantityPerc: 0,
      hoursAmount: rec2.periodB.hoursAmount - rec2.periodA.hoursAmount,
      hoursPerc: 0
    };
    workerAtCropReport.push(rec2);

    //rec cotton
    let rec3 = new WorkerAtCropReport();
    rec3.cropId = 3;
    rec3.cropName = 'Cotton';
    rec3.periodA = {
      dateFrom: '2021-01-01',
      dateTo: '2021-12-31',
      quantityAmount: this._randomValue(50,1),
      quantityPerc: 0,
      hoursAmount: this._randomValue(1000,100),
      hoursPerc: 0
    };
    rec3.periodB = {
      dateFrom: '2022-01-01',
      dateTo: '2022-12-31',
      quantityAmount: this._randomValue(50,1),
      quantityPerc: 0,
      hoursAmount: this._randomValue(1000,100),
      hoursPerc: 0
    };
    rec3.difference = {
      quantityAmount: rec3.periodB.quantityAmount - rec3.periodA.quantityAmount,
      quantityPerc: 0,
      hoursAmount: rec3.periodB.hoursAmount - rec3.periodA.hoursAmount,
      hoursPerc: 0
    };
    workerAtCropReport.push(rec3);

    //rec kiwi
    let rec4 = new WorkerAtCropReport();
    rec4.cropId = 4;
    rec4.cropName = 'Kiwi';
    rec4.periodA = {
      dateFrom: '2021-01-01',
      dateTo: '2021-12-31',
      quantityAmount: this._randomValue(50,1),
      quantityPerc: 0,
      hoursAmount: this._randomValue(1000,100),
      hoursPerc: 0
    };
    rec4.periodB = {
      dateFrom: '2022-01-01',
      dateTo: '2022-12-31',
      quantityAmount: this._randomValue(50,1),
      quantityPerc: 0,
      hoursAmount: this._randomValue(1000,100),
      hoursPerc: 0
    };
    rec4.difference = {
      quantityAmount: rec4.periodB.quantityAmount - rec4.periodA.quantityAmount,
      quantityPerc: 0,
      hoursAmount: rec4.periodB.hoursAmount - rec4.periodA.hoursAmount,
      hoursPerc: 0
    };
    workerAtCropReport.push(rec4);

    //rec ginger
    let rec5 = new WorkerAtCropReport();
    rec5.cropId = 5;
    rec5.cropName = 'Ginger';
    rec5.periodA = {
      dateFrom: '2021-01-01',
      dateTo: '2021-12-31',
      quantityAmount: this._randomValue(50,1),
      quantityPerc: 0,
      hoursAmount: this._randomValue(1000,100),
      hoursPerc: 0
    };
    rec5.periodB = {
      dateFrom: '2022-01-01',
      dateTo: '2022-12-31',
      quantityAmount: this._randomValue(50,1),
      quantityPerc: 0,
      hoursAmount: this._randomValue(1000,100),
      hoursPerc: 0
    };
    rec5.difference = {
      quantityAmount: rec5.periodB.quantityAmount - rec5.periodA.quantityAmount,
      quantityPerc: 0,
      hoursAmount: rec5.periodB.hoursAmount - rec5.periodA.hoursAmount,
      hoursPerc: 0
    };
    workerAtCropReport.push(rec5);

    let sumPeriodAQuantityAmount = 0;
    let sumPeriodAHoursAmount = 0;
    let sumPeriodBQuantityAmount = 0;
    let sumPeriodBHoursAmount = 0;
    let sumDifferenceQuantityAmount = 0;
    let sumDifferenceHoursAmount = 0;
    workerAtCropReport.forEach(rec => {
      sumPeriodAQuantityAmount += rec.periodA.quantityAmount;
      sumPeriodAHoursAmount += rec.periodA.hoursAmount;
      sumPeriodBQuantityAmount += rec.periodB.quantityAmount;
      sumPeriodBHoursAmount += rec.periodB.hoursAmount;
      sumDifferenceQuantityAmount += rec.difference.quantityAmount;
      sumDifferenceHoursAmount += rec.difference.hoursAmount;

    });
    workerAtCropReport.forEach(rec => {
      rec.periodA.quantityPerc = rec.periodA.quantityAmount * 100 / sumPeriodAQuantityAmount
      rec.periodA.hoursPerc = rec.periodA.hoursAmount * 100 / sumPeriodAHoursAmount
      rec.periodB.quantityPerc = rec.periodB.quantityAmount * 100 / sumPeriodBQuantityAmount
      rec.periodB.hoursPerc = rec.periodB.hoursAmount * 100 / sumPeriodBHoursAmount
      rec.difference.quantityPerc = rec.difference.quantityAmount / rec.periodA.quantityAmount * 100
      rec.difference.hoursPerc = rec.difference.hoursAmount / rec.periodA.hoursAmount * 100

    });

    return workerAtCropReport;
  }

  public costOfCropReportExample(): CostOfCropReport[] {

    let costOfCropReport: CostOfCropReport[] = [];
    let rec1 = new CostOfCropReport();
    //rec banana
    rec1.cropId = 1;
    rec1.cropName = 'Banana';
    rec1.periodA = {
      dateFrom: '2021-01-01',
      dateTo: '2021-12-31',
      costAmount: this._randomValue(100000,100),
      costPerc: 0,
    };
    rec1.periodB = {
      dateFrom: '2022-01-01',
      dateTo: '2022-12-31',
      costAmount: this._randomValue(100000,100),
      costPerc: 0
    };
    rec1.difference = {
      costAmount: rec1.periodB.costAmount - rec1.periodA.costAmount,
      costPerc: 0
    };
    costOfCropReport.push(rec1);

    //rec mango
    let rec2 = new CostOfCropReport();
    rec2.cropId = 2;
    rec2.cropName = 'Mango';
    rec2.periodA = {
      dateFrom: '2021-01-01',
      dateTo: '2021-12-31',
      costAmount: this._randomValue(100000,100),
      costPerc: 0
    };
    rec2.periodB = {
      dateFrom: '2022-01-01',
      dateTo: '2022-12-31',
      costAmount: this._randomValue(100000,100),
      costPerc: 0
    };
    rec2.difference = {
      costAmount: rec2.periodB.costAmount - rec2.periodA.costAmount,
      costPerc: 0
    };
    costOfCropReport.push(rec2);

    //rec cotton
    let rec3 = new CostOfCropReport();
    rec3.cropId = 3;
    rec3.cropName = 'Cotton';
    rec3.periodA = {
      dateFrom: '2021-01-01',
      dateTo: '2021-12-31',
      costAmount: this._randomValue(100000,100),
      costPerc: 0
    };
    rec3.periodB = {
      dateFrom: '2022-01-01',
      dateTo: '2022-12-31',
      costAmount: this._randomValue(100000,100),
      costPerc: 0
    };
    rec3.difference = {
      costAmount: rec3.periodB.costAmount - rec3.periodA.costAmount,
      costPerc: 0
    };
    costOfCropReport.push(rec3);

    //rec kiwi
    let rec4 = new CostOfCropReport();
    rec4.cropId = 4;
    rec4.cropName = 'Kiwi';
    rec4.periodA = {
      dateFrom: '2021-01-01',
      dateTo: '2021-12-31',
      costAmount: this._randomValue(100000,100),
      costPerc: 0
    };
    rec4.periodB = {
      dateFrom: '2022-01-01',
      dateTo: '2022-12-31',
      costAmount: this._randomValue(100000,100),
      costPerc: 0
    };
    rec4.difference = {
      costAmount: rec4.periodB.costAmount - rec4.periodA.costAmount,
      costPerc: 0
    };
    costOfCropReport.push(rec4);

    //rec ginger
    let rec5 = new CostOfCropReport();
    rec5.cropId = 5;
    rec5.cropName = 'Ginger';
    rec5.periodA = {
      dateFrom: '2021-01-01',
      dateTo: '2021-12-31',
      costAmount: this._randomValue(100000,100),
      costPerc: 0
    };
    rec5.periodB = {
      dateFrom: '2022-01-01',
      dateTo: '2022-12-31',
      costAmount: this._randomValue(100000,100),
      costPerc: 0
    };
    rec5.difference = {
      costAmount: rec5.periodB.costAmount - rec5.periodA.costAmount,
      costPerc: 0
    };
    costOfCropReport.push(rec5);

    let sumPeriodACostAmount = 0;
    let sumPeriodBCostAmount = 0;
    costOfCropReport.forEach(rec => {
      sumPeriodACostAmount += rec.periodA.costAmount;
      sumPeriodBCostAmount += rec.periodB.costAmount;

    });
    costOfCropReport.forEach(rec => {
      rec.periodA.costPerc = rec.periodA.costAmount * 100 / sumPeriodACostAmount
      rec.periodB.costPerc = rec.periodB.costAmount * 100 / sumPeriodBCostAmount
      rec.difference.costPerc = rec.difference.costAmount / rec.periodA.costAmount * 100
    });

    return costOfCropReport;
  }


  public workersSalaryReportExample(): WorkersSalaryReport[] {

    let workersSalaryReport: WorkersSalaryReport[] = [];

    //rec Ivan Mattiato
    let rec1 = new WorkersSalaryReport();
    rec1.workerId = 1;
    rec1.workerName = 'Ivan Mattiato';
    rec1.month = 1;
    rec1.year = 2022;
    rec1.monthWorkerSalary = {
      workedHoursAmount: this._randomValue(1000,1),
      salaryAmount: this._randomValue(100000,100),
    };
    rec1.yearWorkerSalary = {
      workedHoursAmount: this._randomValue(1000,1),
      salaryAmount: this._randomValue(100000,100),
    };
    workersSalaryReport.push(rec1);

    //rec Hassan Mushtaq
    let rec2 = new WorkersSalaryReport();
    rec2.workerId = 2;
    rec2.workerName = 'Hassan Mushtaq';
    rec2.month = 1;
    rec2.year = 2022;
    rec2.monthWorkerSalary = {
      workedHoursAmount: this._randomValue(1000,1),
      salaryAmount: this._randomValue(100000,100),
    };
    rec2.yearWorkerSalary = {
      workedHoursAmount: this._randomValue(1000,1),
      salaryAmount: this._randomValue(100000,100),
    };
    workersSalaryReport.push(rec2);

    //rec Evelyn Hofer
    let rec3 = new WorkersSalaryReport();
    rec3.workerId = 3;
    rec3.workerName = 'Evelyn Hofer';
    rec3.month = 1;
    rec3.year = 2022;
    rec3.monthWorkerSalary = {
      workedHoursAmount: this._randomValue(1000,1),
      salaryAmount: this._randomValue(100000,100),
    };
    rec3.yearWorkerSalary = {
      workedHoursAmount: this._randomValue(1000,1),
      salaryAmount: this._randomValue(100000,100),
    };
    workersSalaryReport.push(rec3);

    //rec Rudolf Rienzner
    let rec4 = new WorkersSalaryReport();
    rec4.workerId = 4;
    rec4.workerName = 'Rudolf Rienzner';
    rec4.month = 1;
    rec4.year = 2022;
    rec4.monthWorkerSalary = {
      workedHoursAmount: this._randomValue(1000,1),
      salaryAmount: this._randomValue(100000,100),
    };
    rec4.yearWorkerSalary = {
      workedHoursAmount: this._randomValue(1000,1),
      salaryAmount: this._randomValue(100000,100),
    };
    workersSalaryReport.push(rec4);


    //rec Aarya
    let rec5 = new WorkersSalaryReport();
    rec5.workerId = 5;
    rec5.workerName = 'Aarya Manandhar';
    rec5.month = 1;
    rec5.year = 2022;
    rec5.monthWorkerSalary = {
      workedHoursAmount: this._randomValue(1000,1),
      salaryAmount: this._randomValue(100000,100),
    };
    rec5.yearWorkerSalary = {
      workedHoursAmount: this._randomValue(1000,1),
      salaryAmount: this._randomValue(100000,100),
    };
    workersSalaryReport.push(rec5);

    return workersSalaryReport;
  }

  public dailyHoursReportExample(): DailyHoursReport[] {

    let dailyHoursReport: DailyHoursReport[] = [];

    //rec Hassan Mushtaq
    let rec1 = new DailyHoursReport();
    rec1.worker = {
      id: 1,
      name: 'Ivan Mattiato'
    };
    rec1.task = {
      id: 1,
      name: 'Irrigation of cotton'
    };
    rec1.date = '2022-09-01'
    rec1.checkIn = '10:40';
    rec1.checkOut = '11:52';
    rec1.minutes = 72;
    dailyHoursReport.push(rec1);

    let rec2 = new DailyHoursReport();
    rec2.worker = {
      id: 2,
      name: 'Hassan Mushtaq'
    };
    rec2.task = {
      id: 3,
      name: 'cut of pineapple'
    };
    rec2.date = '2022-09-01'
    rec2.checkIn = '10:40';
    rec2.checkOut = '15:18';
    rec2.minutes = 158;
    dailyHoursReport.push(rec2);


    let rec3 = new DailyHoursReport();
    rec3.worker = {
      id: 3,
      name: 'Aarya Mahandhar'
    };
    rec3.task = {
      id: 3,
      name: 'cut of grass'
    };
    rec3.date = '2022-09-02'
    rec3.checkIn = '10:40';
    rec3.checkOut = '11:25';
    rec3.minutes = 45;
    dailyHoursReport.push(rec3);

    let rec4 = new DailyHoursReport();
    rec4.worker = {
      id: 3,
      name: 'Aarya Mahandhar'
    };
    rec4.task = {
      id: 2,
      name: 'cut of pineapple'
    };
    rec4.date = '2022-09-01'
    rec4.checkIn = '10:40';
    rec4.checkOut = '15:18';
    rec4.minutes = 158;
    dailyHoursReport.push(rec4);

    return dailyHoursReport;
  }

  public workerAtCropReport(generalManagerId:number, cropManagerId:number, supervisorid:number): Observable<WorkerAtCropReport[]> {
    let params = new HttpParams();
    params = params.append('generalManagerId', generalManagerId);
    params = params.append('cropManagerId', cropManagerId);
    params = params.append('supervisorid', supervisorid);
    return this._http.get<WorkerAtCropReport[]>(environment.serverUrl + EndpointRoutes.REPORTS_WORKERS_AT_CROP, { params: params }).pipe(
      map(responseData => {
        return responseData;
      }),
      catchError(errorRes => {
        return throwError(() => errorRes);
      })
    );
  }

  public dailyHoursReport(dateFrom:string, dateTo:string, supervisorId?:number, unitFarmId?: number): Observable<DailyHoursReport[]> {
    let params = new HttpParams();
    params = params.append('dateFrom', dateFrom);
    params = params.append('dateTo', dateTo);
    if (supervisorId) {
      params = params.append('supervisorId', supervisorId);
    }
    if (unitFarmId) {
      params = params.append('unitFarmId', unitFarmId);
    }
    return this._http.get<DailyHoursReport[]>(environment.serverUrl + EndpointRoutes.REPORTS_DAILY_HOURS, { params: params }).pipe(
      map(responseData => {
        return responseData;
      }),
      catchError(errorRes => {
        return throwError(() => errorRes);
      })
    );
  }

  public comparePicturesReport(dateFrom:string, dateTo:string, supervisorId?:number, unitFarmId?: number): Observable<ComparePicturesReport[]> {
    let params = new HttpParams();
    params = params.append('dateFrom', dateFrom);
    params = params.append('dateTo', dateTo);
    if (supervisorId) {
      params = params.append('supervisorId', supervisorId);
    }
    if (unitFarmId) {
      params = params.append('unitFarmId', unitFarmId);
    }
    return this._http.get<ComparePicturesReport[]>(environment.serverUrl + EndpointRoutes.REPORTS_COMPARE_PICTURES, { params: params }).pipe(
      map(responseData => {
        return responseData;
      }),
      catchError(errorRes => {
        return throwError(() => errorRes);
      })
    );
  }

  private _randomValue(maxValue: number, precision: number) {  // precision 100 = 2 decimals
    const randomnum = Math.floor(Math.random() * (maxValue * precision - 1 * precision) + 1 * precision) / (1*precision);
    return randomnum;
  }

}
