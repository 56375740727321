<div class="statistic-page main-content">
  <div class="button-bar" style="width:100%"> Report - Workers salary statistic</div>
  <div class="card-container">
    <kendo-tilelayout class="tile" [columns]="12" [rowHeight]="10">
      <kendo-tilelayout-item [col]="1" [colSpan]="12" [rowSpan]="3" >
        <div class="search-bar" fxLayout="row" fxLayoutAlign="none" fxLayoutGap="30">
          <div class="search-field" fxLayout="row" fxLayoutAlign=" none" fxLayoutGap="10">
            <span class="k-label"><b>Month</b></span>
            <kendo-datepicker
              #shortDate
              format="MMMM yyyy"
              [(value)]="datePickerValue"
              activeView="year"
              bottomView="year"
            >
            </kendo-datepicker>
          </div>
          <div class="search-field" fxLayout="row" fxLayoutAlign=" none" fxLayoutGap="10">
            <button kendoButton themeColor="primary" icon="search" (click)="applyFilter()">Search</button>
          </div>
        </div>
      </kendo-tilelayout-item>
      <!-- Own data -->
      <kendo-tilelayout-item [col]="1" [colSpan]="12" [rowSpan]="20" >
        <!-- grid -->
        <kendo-grid
          [data]="gridData"
          [skip]="state.skip"
          (dataStateChange)="dataStateChange($event)"
          style="max-height: 500px; margin-top: 10px !important"
        >
        <ng-template kendoGridToolbarTemplate class="grid-toolbar">
          <div style="display:flex; width:100%;">
            <kendo-grid-spacer></kendo-grid-spacer>
            <div class="maintitle"><h2>Workers salary statistic</h2></div>
            <kendo-grid-spacer></kendo-grid-spacer>
            <div class="commands-bar">
              <button kendoGridExcelCommand type="button" icon="file-excel" *ngIf="!hiddenForMobile">
                Export Excel</button
              >
            </div>
          </div>
        </ng-template>
          <kendo-grid-column-group *ngIf="!hiddenForMobile"
            title="Workers"
            [width]="200"
            [locked]="false"
            [headerClass]="'gridcolumn header group-header'"
          >
            <kendo-grid-column *ngIf="!hiddenForMobile"
              field="workerId"
              title="id"
              filter="numeric"
              [headerClass]="'gridcolumn header'"
            >
              <ng-template kendoGridGroupFooterTemplate  let-aggregates="aggregates">
                0
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-column *ngIf="!hiddenForMobile"
              field="workerName"
              title="name"
              filter="text"
              [headerClass]="'gridcolumn header'"
            >
            <ng-template kendoGridFooterTemplate let-column="column">Sum</ng-template></kendo-grid-column>
          </kendo-grid-column-group>
          <kendo-grid-column-group *ngIf="!hiddenForMobile"
            [title]="labelMonth"
            [width]="500"
            [locked]="false"
            [headerClass]="'gridcolumn header group-header'"
          >
            <ng-template kendoGridHeaderTemplate let-column="column">
              Month salary<br />{{labelMonth}}
            </ng-template>
            <kendo-grid-column *ngIf="!hiddenForMobile"
              field="monthWorkerSalary.workedHoursAmount"
              title="Worked hours"
              [headerClass]="'gridcolumn header align-right'"
              [headerStyle]="{'text-align': 'right'}"
              [footerStyle]="{'text-align': 'right'}"
            >
              <ng-template kendoGridCellTemplate let-dataItem>
                <span class="cell periodA amount">{{ formatWorkingTime(dataItem.monthWorkerSalary.workedHoursAmount) }}</span>
              </ng-template>
              <ng-template kendoGridFooterTemplate let-column="column">
                <span class="cell periodA amount sum">{{ formatWorkingTime(total["monthWorkerSalary.workedHoursAmount"].sum) }}</span>
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-column *ngIf="!hiddenForMobile"
              field="monthWorkerSalary.salaryAmount"
              title="Salary amount"
              [headerClass]="'gridcolumn header align-right'"
              [headerStyle]="{'text-align': 'right'}"
              [footerStyle]="{'text-align': 'right'}"
            >
              <ng-template kendoGridCellTemplate let-dataItem>
                <span class="cell periodA amount">{{dataItem.monthWorkerSalary.salaryAmount | number: '1.2-2'}} Br</span>
              </ng-template>
              <ng-template kendoGridFooterTemplate let-column="column">
                <span class="cell periodA amount sum">{{(total["monthWorkerSalary.salaryAmount"].sum) | number: '1.2-2'}} Br</span>
              </ng-template>
            </kendo-grid-column>
          </kendo-grid-column-group>
          <kendo-grid-column-group *ngIf="!hiddenForMobile"
            [title]="labelYear"
            [width]="500"
            [locked]="false"
            [headerClass]="'gridcolumn header group-header'"
          >
            <ng-template kendoGridHeaderTemplate let-column="column">
              Year salary<br />{{labelYear}}
            </ng-template>
            <kendo-grid-column *ngIf="!hiddenForMobile"
              field="yearWorkerSalary.workedHoursAmount"
              title="Worked hours"
              [headerClass]="'gridcolumn header align-right'"
              [headerStyle]="{'text-align': 'right'}"
              [footerStyle]="{'text-align': 'right'}"
            >
            <ng-template kendoGridCellTemplate let-dataItem>
              <span class="cell periodB amount">{{ formatWorkingTime(dataItem.yearWorkerSalary.workedHoursAmount) }}</span>
            </ng-template>
            <ng-template kendoGridFooterTemplate let-column="column">
              <span class="cell periodB amount sum">{{ formatWorkingTime(total["yearWorkerSalary.workedHoursAmount"].sum) }}</span>
            </ng-template>
            </kendo-grid-column>
            <kendo-grid-column *ngIf="!hiddenForMobile"
              field="yearWorkerSalary.salaryAmount"
              title="Salary amount"
              [headerClass]="'gridcolumn header align-right'"
              [headerStyle]="{'text-align': 'right'}"
              [footerStyle]="{'text-align': 'right'}"
            >
              <ng-template kendoGridCellTemplate let-dataItem>
                <span class="cell periodA amount">{{dataItem.yearWorkerSalary.salaryAmount | number: '1.2-2'}} Br</span>
              </ng-template>
              <ng-template kendoGridFooterTemplate let-column="column">
                <span class="cell periodA amount sum">{{(total["yearWorkerSalary.salaryAmount"].sum) | number: '1.2-2'}} Br</span>
              </ng-template>
            </kendo-grid-column>
          </kendo-grid-column-group>
          <kendo-grid-excel fileName="report.xlsx" [fetchData]="excelExportData">
          </kendo-grid-excel>
        </kendo-grid>
      </kendo-tilelayout-item>
    </kendo-tilelayout>
  </div>
</div>

