<ng-container *ngIf="dashboardType === 'menu'">
  <div class="dashboard" >
    <div class="dashboard-title">
      <img src="/assets/media/images/logo_friel.png" alt="logo friel" class="logo" style="width:100%; max-width:420px; height:auto;">
    </div>
    <kendo-tilelayout
      class="tile"
      [columns]="columns"
      [reorderable]="false">
      <kendo-tilelayout-item [col]="i<columns-1?i+1:columns" *ngFor="let menu of menus; let i = index">
        <ng-container *ngIf="!menu.hidden">
          <kendo-tilelayout-item-header class="title">
            <h2><span class="k-icon icon {{ menu.icon }}"></span>{{ menu.name }}</h2>
          </kendo-tilelayout-item-header>
          <kendo-tilelayout-item-body>
            <div class="content">
              <ul>
                <ng-container *ngFor="let child of menu.children">
                  <li *ngIf="!child.hidden">
                    <button kendoButton (click)="onButtonClick(child)" themeColor="primary" fillMode="outline" icon="chevron-left">{{ child.name }}</button>
                  </li>
                </ng-container>
              </ul>
            </div>
          </kendo-tilelayout-item-body>
        </ng-container>
      </kendo-tilelayout-item>
    </kendo-tilelayout>
  </div>
</ng-container>
