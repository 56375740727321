import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { Validators, FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { TextBoxComponent } from '@progress/kendo-angular-inputs';
import { MessageService } from '@progress/kendo-angular-l10n';
import { NotificationRef, NotificationService } from '@progress/kendo-angular-notification';
import { CustomMessagesService } from 'src/app/shared/custom-messages.service';
import { User, UserDb } from 'src/app/shared/models/user.model';
import { ActivatedRoute, Router } from '@angular/router';
import { UsersService } from '../users.service';
import { Page } from 'src/app/shared/models/page.model';
import { PageNavigationService } from 'src/app/shared/page-navigation.service';

@Component({
  selector: 'app-user-edit',
  templateUrl: './user-edit.component.html',
  styleUrls: ['./user-edit.component.scss']
})

export class UserEditComponent implements OnInit, AfterViewInit {

    @Input() mainTitleInsert: string;
    @Input() mainTitleUpdate: string;


    public mainTitle = '';
    public formGroup: FormGroup;
    public parentUserId: number;
    public parentUser: User | null;
    public parentPage: string;
    public userId: number;
    public userTypeId: number;
    public user: UserDb;
    public pageName: string;
    public isSubPage: boolean;
    private _loggedUser: User;
    public listPage: Page;
    public detailPage: Page;
    public dashboardPage: Page;


    @ViewChild("username")
    public usernameField!: TextBoxComponent;

    @ViewChild("password")
    public passwordField!: TextBoxComponent;


    public customMsgService: CustomMessagesService;

    constructor(public msgService: MessageService, private _notificationService: NotificationService, private _pageNavigationService: PageNavigationService,
      private _route: ActivatedRoute, private _router: Router, private _usersService: UsersService, private _formBuilder: FormBuilder) {
        this.customMsgService = this.msgService as CustomMessagesService;

        this.formGroup = this._formBuilder.group({
          id: { value: null, disabled: true },
          firstName: ['', [Validators.required]],
          lastName: ['', [Validators.required]],
          email: [null, [Validators.email]],
          mobile: [null],
          gender: [1],
          username: ['', [Validators.required]],
          password: ['', [Validators.required]],
          cost: [null],
          nfcTag: [null],
          code: [null],
          jobTitle: [null],
          address: [null],
          workerTypeId: [null],
          parentUserId: [null],
        });
    }

    ngOnInit(): void {
      this._pageNavigationService.importFromLocalStorage();
      this.pageName = this._route.snapshot.url[2].path;
      if (this.pageName === 'profile') {
        const lsLoggedUser = localStorage.getItem('loggedUser')
        if (lsLoggedUser) {
          const lsParsedUser:User = JSON.parse(lsLoggedUser);
          this.userId = lsParsedUser.id;
          this._loggedUser = lsParsedUser;
          this._getUserAndSetForm();
        }
        else {
          this._router.navigate(['/logout']);
        }
      } else if (this.pageName === 'update') {
        if (this._pageNavigationService.dataItemId) {
          this.userId = this._pageNavigationService.dataItemId;
          this._getUserAndSetForm();
        } else {
          this._router.navigate([this._pageNavigationService.listPageUrl]);
        }
      } else if (this.pageName === 'insert') {
        this.userId = 0;
        this.mainTitle = this.mainTitleInsert;
        this.formGroup.controls['username'].enable();
        this.formGroup.controls['password'].enable();
      }



    }

    ngAfterViewInit(): void {
      this.toggleVisibility();
    }

    private _getUserAndSetForm() {
      this._usersService.getUserById(this.userId).subscribe(user => {
        this.user = new UserDb(
          user.id,
          user.userTypeId,
          user.firstName,
          user.lastName,
          user.gender,
          user.username,
          'defaultPassword',
          user.cost,
          user.email,
          user.mobile,
          user.nfcTag
        );
        // console.log('userAPI:',user);
        this._patchValueForm( this.user);

        if (this.pageName === 'update') {
          this.mainTitle = this.mainTitleUpdate.replace('%userFullName%', this.user.firstName.value + ' '+ this.user.lastName.value);
        }
      });
    }

    private _patchValueForm(user: UserDb) {
      if (user) {
        //this.formGroup.patchValue(this.user);
        this.formGroup.controls['firstName'].setValue(user.firstName.value);
        this.formGroup.controls['lastName'].setValue(user.lastName.value);
        this.formGroup.controls['email'].setValue(user.email.value);
        this.formGroup.controls['mobile'].setValue(user.mobile.value);
        this.formGroup.controls['gender'].setValue(user.gender.value);
        this.formGroup.controls['username'].setValue(user.username.value);
        this.formGroup.controls['password'].setValue(user.username.value);
        this.formGroup.controls['cost'].setValue(user.cost.value);
        this.formGroup.controls['nfcTag'].setValue(user.nfcTag.value);
        if (this.pageName !== 'insert') {
          this.formGroup.controls['username'].disable();
          this.formGroup.controls['password'].disable();
        }
        this._setDefaultValidators(this.formGroup, this.user);
      }
    }

    private _setDefaultValidators(formGroup: FormGroup, userDb: UserDb) {
      Object.keys(formGroup.controls).forEach(formControlKey => {
        const control = formGroup.controls[formControlKey];
        Object.keys(userDb).forEach(propertyKey => {
          if (formControlKey === propertyKey) {
            const property = Object.getOwnPropertyDescriptor(userDb,propertyKey);
            if (property?.value.maxLength) {
              if (property.value.maxLength > 0) {
                control.addValidators([Validators.maxLength(property.value.maxLength)]);
                control.markAsTouched();
              }
            }
            return;
          }
        });
      });
    }

    public saveChanges(): void {
        this.formGroup.markAllAsTouched();
        if (!this.formGroup.errors) {

          let user = this.formGroup.getRawValue() as User;
          user.id = this.userId;
          user.userTypeId = this.userTypeId;
          if (this.pageName === 'profile') {
            if (this.user.userTypeId.value) {
              user.userTypeId = this.user.userTypeId.value;
            }
          }
          // console.log('userObject:',user);
          if ((this.pageName === 'update') || (this.pageName === 'profile')) {
            this._usersService.updateUser(user).subscribe({
              complete:()=> {
                this.user.id.value = this.userId;
                this.user.userTypeId.value = user.userTypeId;
                this.user.firstName.value = user.firstName;
                this.user.lastName.value = user.lastName;
                this.user.email.value = user.email;
                this.user.mobile.value = user.mobile;
                this.user.gender.value = user.gender;
                this.user.username.value = user.username;
                this.user.password.value = user.password;
                this.user.cost.value = user.cost;
                this.user.nfcTag.value = user.nfcTag;

                this._notificationService.show({
                  content: 'Record has been saved.',
                  animation: { type: 'slide', duration: 500 },
                  position: { horizontal: 'center', vertical: 'bottom' },
                  type: { style: 'success', icon: true },
                  hideAfter: 2000,
                });

                if (this.pageName !== 'profile') {
                  this._router.navigate([this._pageNavigationService.listPageUrl]);
                }
              },
              error: (error) => {
                // console.log('error___this._usersService.updateUser', error)
                const notification: NotificationRef =
                  this._notificationService.show({
                    content: 'Internal error',
                    animation: { type: 'slide', duration: 500 },
                    position: { horizontal: 'center', vertical: 'bottom' },
                    type: { style: 'error', icon: true },
                    hideAfter: 1000,
                  });

                notification.afterHide?.subscribe(()=> {
                  //if (this.pageName === 'profile') {
                  //  this._router.navigate(['/settings/users/list']);
                  //}
                });
              }
            });
          }
          if ((this.pageName === 'insert')) {
            this._usersService.insertUser(user).subscribe({
              complete:()=> {
                this._notificationService.show({
                  content: 'Record has been saved.',
                  animation: { type: 'slide', duration: 500 },
                  position: { horizontal: 'center', vertical: 'bottom' },
                  type: { style: 'success', icon: true },
                  hideAfter: 2000,
                });

                if (this.pageName !== 'profile') {
                  this._router.navigate([this._pageNavigationService.listPageUrl]);
                }
              },
              error: (error) => {
                // console.log('error___this._usersService.updateUser', error)
                const notification: NotificationRef =
                  this._notificationService.show({
                    content: 'Internal error',
                    animation: { type: 'slide', duration: 500 },
                    position: { horizontal: 'center', vertical: 'bottom' },
                    type: { style: 'error', icon: true },
                    hideAfter: 1000,
                  });

                notification.afterHide?.subscribe(()=> {
                  //if (this.pageName === 'profile') {
                  //  this._router.navigate(['/settings/users/list']);
                  //}
                });
              }
            });
          }

        };
    }

    public cancelChanges(): void {
        this._patchValueForm(this.user);
    }

    public toggleVisibility(): void {
      const passwordEl =this.passwordField.input.nativeElement;
      passwordEl.type = passwordEl.type === "password" ? "text" : "password";
    }

    public goToButtonOnClick (pageCode : string): void {
      if (pageCode.toLowerCase()==='listpage') {
        this._router.navigate([this._pageNavigationService.listPageUrl]);
      }
      if (pageCode.toLowerCase()==='dashboardpage') {
        this._router.navigate([this._pageNavigationService.dashboardPageUrl]);
      }
      if (pageCode.toLowerCase()==='parentdashboardpage') {
        this._router.navigate([this._pageNavigationService.parentDashboardPageUrl]);
      }
    }
}
