import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Menu, MenuChild } from './models/menu.model';
import { User } from './models/user.model';

class AppLocalStorageObject {
  lang?: string;
}

export class UserLocalStorageObject {
  id: number;
  sessionToken: string;
  constructor(id: number, sessionToken: string) {
    this.id = id;
    this.sessionToken = sessionToken;
  }
}

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  public userLocalStorageInserted = new BehaviorSubject(this.getUserLocalStorageObject());

  constructor() { }

  private _getLocalStorage(uniqueId: string) {
    return localStorage.getItem(uniqueId);
  }

  private _setLocalStorage(key: string, value: string) {
    localStorage.setItem(key, value);
  }

  /**
   * USER LOCAL STORAGE
   */
  public userLocalStorageExist(): boolean {
    return (this._getLocalStorage(environment.userSessionUniqueId) ? true : false);
  }

  public getUserLocalStorageObject(): UserLocalStorageObject {
    const currentLocalStorage = this._getLocalStorage(environment.userSessionUniqueId);
    // console.log('getUserLocalStorageObject', currentLocalStorage);
    return JSON.parse(currentLocalStorage || '{}');
  }

  public setUserLocalStorage<T extends keyof UserLocalStorageObject>(key: T, value: any) {
    const localStorageObject: UserLocalStorageObject = JSON.parse(this._getLocalStorage(environment.userSessionUniqueId) || '{}');
    // console.log('localStorageObject', localStorageObject);
    localStorageObject[key] = value;
    this._setLocalStorage(environment.userSessionUniqueId, JSON.stringify(localStorageObject));
    this.userLocalStorageInserted.next(localStorageObject);
  }

  /**
   * APP LOCAL STORAGE
   */
  public appLocalStorageExist(): boolean {
    return (this._getLocalStorage(environment.appSessionUniqueId) ? true : false);
  }

  public getAppLocalStorageObject(): AppLocalStorageObject {
    const currentLocalStorage = this._getLocalStorage(environment.appSessionUniqueId);
    const object = JSON.parse(currentLocalStorage || '{}');
    return object;
  }

  public setAppLocalStorage<T extends keyof AppLocalStorageObject>(key: T, value: any) {
    let localStorageObject = new AppLocalStorageObject();
    if (this.appLocalStorageExist()) {
      localStorageObject = JSON.parse(this._getLocalStorage(environment.appSessionUniqueId) || '{}');
    }
    localStorageObject[key] = value;
    this._setLocalStorage(environment.appSessionUniqueId, JSON.stringify(localStorageObject));
  }

  public getActualMenuLs(userType: number, hideDashboard: boolean = false) : Menu[] {
    const menuStr = localStorage.getItem('menus');
    // console.log('menuStr',menuStr);
    //const menuObj: Menu[] = menuStr !== null ? JSON.parse(menuStr) : new Menu();
    const menus: Menu[] = JSON.parse(menuStr || '{}');
    menus.forEach((menuL1: Menu, indexL1: number) => {
      menuL1.hidden = false;
      if ((menuL1.code === 'dashboard') && (hideDashboard)) {
        menus.splice(indexL1,1);
      }
    });
    menus.forEach((menuL1: Menu, indexL1: number) => {
      if (menuL1.hasChildren) {
        menuL1.children.forEach((menuL2: MenuChild) => {
          let toDelete = false;
            switch(userType) {
              case 2: //crop manager
                if (menuL2.code === 'generalManagers') {
                  toDelete = true
                }
                if (menuL2.code === 'cropManagers') {
                  toDelete = true;
                }
                break;
              case 3: //supervisor
                if (menuL2.code === 'generalManagers') {
                  toDelete = true;
                }
                if (menuL2.code === 'cropManagers') {
                  toDelete = true;
                }
                if (menuL2.code === 'supervisors') {
                  toDelete = true;
                }
                break;
            }
            menuL2.hidden = false;
            if (toDelete) {
              menuL2.hidden = true;
            }
        });
      }
    });
    return menus;
  }

}
