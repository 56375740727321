import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {
  DataBindingDirective,
  DataStateChangeEvent,
  GridDataResult,
  MultipleSortSettings,
  PagerPosition
} from '@progress/kendo-angular-grid';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { PagerType } from "@progress/kendo-angular-grid/pager/pager-settings";
import { MessageService } from '@progress/kendo-angular-l10n';
import { process, State } from '@progress/kendo-data-query';
import { User } from 'src/app/shared/models/user.model';
import { SharedService } from 'src/app/shared/shared.service';
import { UsersService } from '../users.service';
import { NotificationRef, NotificationService } from '@progress/kendo-angular-notification';
import { Page } from 'src/app/shared/models/page.model';
import { PageNavigationService } from 'src/app/shared/page-navigation.service';

@Component({
  selector: 'app-users-list',
  templateUrl: './users-list.component.html',
  styleUrls: ['./users-list.component.scss']
})

export class UsersListComponent implements OnInit {

  @ViewChild(DataBindingDirective) dataBinding: DataBindingDirective;

  @Input() mainTitle: string;

  public gridData: GridDataResult;
  public mySelection: string[] = [];
  public usersDataArray: User[] = [];
  public pageCode: string;

  private _varPagerTypes: PagerType = 'numeric';
  private _varPagerPosition: PagerPosition = 'both';

  private _currentUser: User;

  public pagingSettings = {
    pagerTypes: this._varPagerTypes,
    type: 'input',
    // buttonCount: 5,
    info: true,
    previousNext: true,
    position: this._varPagerPosition,
    pageSize: 10,
    responsive: true,
    pageSizes: [
      10,
      20,
      50,
      100,
    ]
  };

  public sortingSettings: MultipleSortSettings = {
    allowUnsort: true
  };

  public state: State = {
    skip: 0,
    take: this.pagingSettings.pageSize,
    // Initial filter descriptor
  };

  public showHeader = false;

  public splitButtonItems = [
    {
      text: "Detail",
      icon: "detail-section",
      action: "detail"
    },
    {
      text: "Edit",
      icon: "edit",
      action: "edit"
    },
    {
      text: "Delete",
      icon: "delete",
      action: "delete"
    }
  ];



  constructor(private _sharedService: SharedService,private _router: Router, private _translate: TranslateService, private messages: MessageService, private _usersService: UsersService,
    private _notificationService: NotificationService, private _pageNavigationService: PageNavigationService) {};

  public ngOnInit(): void {

    const lsLoggedUser = localStorage.getItem('loggedUser')
    if (lsLoggedUser) {
      this._currentUser = JSON.parse(lsLoggedUser);
      this.pageCode = this._pageNavigationService.pageCode;
    }

    this._setGridData();
  }

  private _setGridData() {

    let functionGet;
    switch (this.pageCode.toLowerCase()) {
      case 'generalmanagers':
        functionGet = this._usersService.getGeneralManagers();
        break;
      case 'cropmanagers':
        functionGet = this._usersService.getCropManagers(this._currentUser.id);
        break;
    }
    if (functionGet) {
      functionGet.subscribe((result) => {
        result.forEach(user=> {
          user.genderText = this._sharedService.getGenderText(user.gender);
        } );
        this.usersDataArray = result;
        this.gridData = process(result, this.state);
        this.excelExportData = this.excelExportData.bind(this);
      });
    }
  }

  public dataStateChange(state: DataStateChangeEvent): void {
    if (state && state.group) {
      // state.group.map((group) => (group.aggregates = this.aggregates));
    }
    this.state = state;
    this.gridData = process(this.usersDataArray, this.state);
  }

  public excelExportData(): ExcelExportData {
    const excelState = this.state;
    const excelData = process(this.usersDataArray, {
      group: this.state.group,
      sort: this.state.sort,
      filter: this.state.filter
    });
    const result: ExcelExportData = {
      data: excelData.data,
      group: excelState.group
    };
    return result;
  }

  public onSplitButtonClick(): void {
    // nothing
  }


  public onInsertButtonClick(): void {

    this._router.navigate([this._pageNavigationService.insertPageUrl]);
  }

  public onSplitButtonItemClick(button: { [key: string]: unknown }, dataItem:User): void {
    if (button) {
      this._pageNavigationService.dataItemId = dataItem.id;
      switch (button['action']) {
        case 'detail':
          this._router.navigate([this._pageNavigationService.dashboardPageUrl]);
          break;
        case 'edit':
          this._router.navigate([this._pageNavigationService.updatePageUrl]);
          break;
        case 'delete':
          this._deleteRecord(dataItem.id);
          break;
      }
    }
  }

  private _deleteRecord(id: number)
  {
    // console.log('deletedId', id);
    this._usersService.deleteUser(id).subscribe({
      complete:()=> {
        this._setGridData();
        this._notificationService.show({
          content: 'The record has been deleted!',
          animation: { type: 'slide', duration: 500 },
          position: { horizontal: 'center', vertical: 'bottom' },
          type: { style: 'success', icon: true },
          hideAfter: 2000,
        });
      },
      error: (error) => {
        // console.log('error___this._usersService.deleteUser', error)
        const notification: NotificationRef =
          this._notificationService.show({
            content: 'Internal error',
            animation: { type: 'slide', duration: 500 },
            position: { horizontal: 'center', vertical: 'bottom' },
            type: { style: 'error', icon: true },
            hideAfter: 1000,
          });
      }
    });
  }
}

