
<div class="data-row" fxLayout="row" fxLayoutAlign="none">
  <div class="label">First name</div>
  <div class="input">{{userData.firstName}}</div>
</div>
<div class="data-row"  fxLayoutAlign="none">
  <div class="label">Last name</div>
  <div class="input">{{userData.lastName}}</div>
</div>
<div class="data-row" fxLayout="row" fxLayoutAlign="none">
  <div class="label">Gender</div>
  <div class="input">{{getGenderText(userData.gender)}}</div>
</div>
<div class="data-row" fxLayout="row" fxLayoutAlign="none">
  <div class="label">Email</div>
  <div class="input"><a *ngIf="userData.email" href="mailto:{{userData.email}}" target="_blank">{{userData.email}}</a></div>
</div>
<div class="data-row" fxLayout="row" fxLayoutAlign="none">
  <div class="label">Mobile</div>
  <div class="input"><a  *ngIf="userData.mobile" href="tel:{{userData.mobile}}" target="_blank">{{userData.mobile}}</a></div>
</div>
<div class="data-row" fxLayout="row" fxLayoutAlign="none">
  <div class="label">Cost</div>
  <div class="input">{{userData.cost}}</div>
</div>
<div class="data-row" fxLayout="row" fxLayoutAlign="none">
  <div class="label">Nfc tag</div>
  <div class="input">{{userData.nfcTag}}</div>
</div>
<div class="button-row">
  <button kendoButton [primary]="false" [icon]="'edit'" (click)="goToEditPage()">Edit</button>
</div>
