import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import {
  DataBindingDirective,
  DataStateChangeEvent,
  GridDataResult,
  MultipleSortSettings,
} from '@progress/kendo-angular-grid';
import { PagerPosition, PagerType } from '@progress/kendo-angular-grid/pager/pager-settings';
import { process, State } from '@progress/kendo-data-query';
import { User } from 'src/app/shared/models/user.model';
import { Worker } from 'src/app/shared/models/worker.model';
import { SharedService } from 'src/app/shared/shared.service';
import { WorkersService } from '../workers.service';

@Component({
  selector: 'app-workers-deleted-list',
  templateUrl: './workers-deleted-list.component.html',
  styleUrls: ['./workers-deleted-list.component.scss']
})
export class WorkersDeletedListComponent implements OnInit {

  @ViewChild(DataBindingDirective) dataBinding: DataBindingDirective;

  public gridData: GridDataResult;
  public mySelection: string[] = [];
  public workersDataArray: Worker[] = [];
  public pageCode: string;

  public hiddenForMobile: boolean = false;
  public fixedWidth: number = 150;

  private _varPagerTypes: PagerType = 'numeric';
  private _varPagerPosition: PagerPosition = 'both';

  private _currentUser: User;

  public pagingSettings = {
    pagerTypes: this._varPagerTypes,
    type: 'input',
    // buttonCount: 5,
    info: true,
    previousNext: true,
    position: this._varPagerPosition,
    pageSize: 10,
    responsive: true,
    pageSizes: [
      5,
      10,
      20,
      50,
      100,
    ]
  };

  public sortingSettings: MultipleSortSettings = {
    allowUnsort: true
  };

  public splitButtonItems = [
    {
      text: "Edit",
      icon: "edit",
      action: "update"
    },
    {
      text: "Dashboard",
      icon: "detail-section",
      action: "dashboard"
    }

  ];

  public state: State = {
    skip: 0,
    take: this.pagingSettings.pageSize,
    // Initial filter descriptor
  };

  public showHeader = false;

  constructor(private _sharedService: SharedService,private _router: Router, private _workersService: WorkersService) {
    this.hiddenForMobile = (window.innerWidth <= 1024);
    this.fixedWidth = (window.innerWidth <= 768) ? 100 : this.fixedWidth;
    if (window.innerWidth <= 768) {
      this.fixedWidth = 100;
      this.pagingSettings.pageSize = 5;
      this.state.take = 5;
    }
  };

  public ngOnInit(): void {

    const lsLoggedUser = localStorage.getItem('loggedUser')
    if (lsLoggedUser) {
      this._currentUser = JSON.parse(lsLoggedUser);
    }
    this._setGridData();
  }

  private _setGridData() {
    this._workersService.getWorkers(
      this._currentUser.id, 'allWorkers'
      ).subscribe((result) => {
        result = result.filter((w) => w.status === 0);
      result.forEach(user=> {
        user.genderText = this._sharedService.getGenderText(user.gender);
      } );
      this.workersDataArray = result;
      this.gridData = process(result, this.state);
      this.excelExportData = this.excelExportData.bind(this);
    });
  }

  public dataStateChange(state: DataStateChangeEvent): void {
    if (state && state.group) {
      // state.group.map((group) => (group.aggregates = this.aggregates));
    }
    this.state = state;
    this.gridData = process(this.workersDataArray, this.state);
  }

  public excelExportData(): ExcelExportData {
    const excelState = this.state;
    const excelData = process(this.workersDataArray, {
      group: this.state.group,
      sort: this.state.sort,
      filter: this.state.filter
    });
    const result: ExcelExportData = {
      data: excelData.data,
      group: excelState.group
    };
    return result;
  }


  public onFilter(input: Event): void {
    const inputValue = (input.target as HTMLInputElement).value;
    if (inputValue) {
      this.state.filter =
        {
          logic: "or",
          filters: [
            "firstName", "lastName", "code", "genderText", "city", "workerTypeName", "unitFarmName", "supervisorFullName",
            "deletedReason"
          ].map((field) => ({
              field: field,
              operator: "contains",
              value: inputValue
          })),
      };
    } else {
      this.state.filter = undefined;
    }
    this.state.skip = 0;
    this.gridData = process(this.workersDataArray, this.state);
  }

  public onSplitButtonItemClick(button: { [key: string]: unknown }, dataItem:Worker): void {
    if (button) {
      switch (button['action']) {
        case 'dashboard':
          this._router.navigate(['staff/workers/dashboard'],{ queryParams: { workerId: dataItem.id } });
          break;
        case 'update':
          this._router.navigate(['staff/workers/update'],{ queryParams: { workerId: dataItem.id } });
          break;
      }
    }
  }

}
