<div class="user-dashboard-page main-content" fxLayout="column" fxLayoutAlign="top center">
  <div class="button-bar" style="width:90vw;">
    <button *ngIf="!isSubPage"  kendoButton themeColor="primary" fillMode="outline" icon="chevron-left" (click)="goToButtonOnClick('list')">All supervisors</button>
    <button *ngIf="isSubPage && cropManager" kendoButton themeColor="primary" fillMode="outline" icon="chevron-left" (click)="goToButtonOnClick('generalManagerDashboard')">Dashboard of crop manager <b>{{cropManager.firstName}} {{cropManager.lastName}}</b></button>
    <button *ngIf="isSubPage && !cropManager" kendoButton themeColor="primary" fillMode="outline" icon="chevron-left" (click)="goToButtonOnClick('list')">All supervisors</button>
  </div>
  <div class="detail-page dashboard" style="width:90vw;">
    <!-- LAYOUT OF DASHBOARD FOR SUPERVISORS-->
    <div class="title-container">
      <h2 *ngIf="outputUser">Dashboard of supervisor {{outputUser.firstName}} {{outputUser.lastName}} </h2>
    </div>
    <kendo-tilelayout class="tile" [columns]="12" [rowHeight]="10" *ngIf="outputUser">
      <!-- Own data -->
      <kendo-tilelayout-item  [col]="responsiveWrappers.supervisorData.col" [colSpan]="responsiveWrappers.supervisorData.colspan" [rowSpan]="responsiveWrappers.supervisorData.rowspan" [classList]="'detail-owndata-box'">
        <kendo-tilelayout-item-header class="title">
          <h3>Supervisor data</h3>
        </kendo-tilelayout-item-header>
        <kendo-tilelayout-item-body>
          <div class="content">
            <app-supervisor-detail-data [userData] = "outputUser" [generalManager] = "outputGeneralManager" [cropManager] = "outputCropManager"></app-supervisor-detail-data>
          </div>
        </kendo-tilelayout-item-body>
      </kendo-tilelayout-item>
      <!-- Own crop managers -->
      <kendo-tilelayout-item [col]="responsiveWrappers.listOfCropManagers.col" [colSpan]="responsiveWrappers.listOfCropManagers.colspan" [rowSpan]="responsiveWrappers.listOfCropManagers.rowspan" [classList]="'list-related-box'">
        <kendo-tilelayout-item-header class="list-subpage title" fxLayout="row" fxLayoutAlign="none">
          <div class="main-title" fxFlex="50">
            <h3>
              Own workers
            </h3>
          </div>
          <div class="commands-bar" fxFlex="50">
            <!--<button kendoButton [primary]="true" icon="plus-circle" (click)="onInsertButtonClick()">Insert</button>-->
          </div>
        </kendo-tilelayout-item-header>
        <kendo-tilelayout-item-body>
          <div class="content">
            <app-supervisor-detail-related [userData] = "outputUser" [generalManager] = "outputGeneralManager" [cropManager] = "outputCropManager"></app-supervisor-detail-related>
          </div>
        </kendo-tilelayout-item-body>
      </kendo-tilelayout-item>
    </kendo-tilelayout>
  </div>
</div>
