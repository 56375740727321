<kendo-grid
  [data]="gridData"
  [pageSize]="state.take"
  [skip]="state.skip"
  [sort]="state.sort"
  [filter]="state.filter"
  [group]="state.group"
  [sortable]="sortingSettings"
  [pageable]="{
    info: pagingSettings.info,
    type: pagingSettings.pagerTypes,
    pageSizes: pagingSettings.pageSizes,
    previousNext: pagingSettings.previousNext,
    position: pagingSettings.position,
    responsive: pagingSettings.responsive
  }"
  [groupable]="true"
  [reorderable]="true"
  [resizable]="true"
  filterable="menu"
  [columnMenu]="{ filter: true }"
  (dataStateChange)="dataStateChange($event)"
  style="max-height: 800px; margin-top: 10px !important"
>
  <ng-template kendoGridToolbarTemplate>
    <kendo-grid-spacer></kendo-grid-spacer>
    <div class="maintitle"><h2>{{mainTitle}}</h2></div>
    <kendo-grid-spacer></kendo-grid-spacer>
    <div class="commands-bar">
      <button kendoButton [primary]="true" icon="plus-circle" (click)="onInsertButtonClick()">Insert</button
      >&nbsp;
      <button kendoGridExcelCommand type="button" icon="file-excel">
        Export Excel</button
      >&nbsp;
    </div>
  </ng-template>
  <kendo-grid-column
    field="firstName"
    title="First Name"
    filter="text"
    [width]="200"
    [headerClass]="'gridcolumn header'"
  >
  </kendo-grid-column>
  <kendo-grid-column
    field="lastName"
    title="Last Name"
    filter="text"
    [width]="200"
    [headerClass]="'gridcolumn header'"
  >
  </kendo-grid-column>
  <kendo-grid-column
    field="genderText"
    title="Gender"
    filter="text"
    [width]="150"
    [headerClass]="'gridcolumn header'"
  >
  </kendo-grid-column>
  <kendo-grid-column
    field="username"
    title="Username"
    filter="text"
    [width]="200"
    [headerClass]="'gridcolumn header'"
  >
  </kendo-grid-column>
  <kendo-grid-column
    field="email"
    title="Email"
    filter="text"
    [width]="200"
    [headerClass]="'gridcolumn header'"
  >
  </kendo-grid-column>
  <kendo-grid-column
    field="mobile"
    title="Mobile"
    filter="text"
    [width]="200"
    [headerClass]="'gridcolumn header'"
  >
  </kendo-grid-column>
  <kendo-grid-column
    field="cost"
    title="Cost"
    filter="text"
    [width]="120"
    [headerClass]="'gridcolumn header'"
  >
  </kendo-grid-column>
  <kendo-grid-column
    field="nfcTag"
    title="NFC Tag"
    filter="text"
    [width]="120"
    [headerClass]="'gridcolumn header'"
  >
  </kendo-grid-column>
  <kendo-grid-column
    [headerClass]="{ 'text-center': true }"
    [class]="{ 'text-center': true }"
    [width]="150"
    [columnMenu]="false"
  >
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
      <kendo-splitbutton
        [data]="splitButtonItems"
        (buttonClick)="onSplitButtonClick()"
        (itemClick)="onSplitButtonItemClick($event, dataItem)"
      >
        Actions
      </kendo-splitbutton>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-excel fileName="Users.xlsx" [fetchData]="excelExportData">
  </kendo-grid-excel>
</kendo-grid>
