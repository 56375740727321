<div class="profile-page main-content">
  <div class="button-bar" style="width:100%">
    <button kendoButton themeColor="primary" fillMode="outline" icon="chevron-left" *ngIf="workerName" (click)="handleGoBackToDashboard()">Dashboard of worker <b>{{workerName}}</b></button>
  </div>
  <div class="card-container">
    <div class="search-bar" fxLayout="row" fxLayoutAlign="none" fxLayoutGap="30">
      <div class="search-field">
        <input
          [classList]="'opensearch'"
          placeholder="Search in all columns..."
          kendoTextBox
          (input)="onFilter($event)"
        />
      </div>
      <div class="search-field" fxLayout="row" fxLayoutAlign=" none" fxLayoutGap="10">
        <span class="k-label">Assigned</span>
        <ul class="k-radio-list k-list-horizontal">
          <li class="k-radio-item">
            <input type="radio" name="assigned" #assignedAll [value]="-1" kendoRadioButton checked (change)="handleAssignedValue(-1)" />
            <kendo-label class="k-radio-label" [for]="assignedAll" text="All"> </kendo-label>
          </li>
          <li class="k-radio-item" >
            <input type="radio" name="assigned" #assignedTrue [value]="1" kendoRadioButton (change)="handleAssignedValue(1)" />
            <kendo-label class="k-radio-label" [for]="assignedTrue" text="Yes"> </kendo-label>
          </li>
          <li class="k-radio-item">
            <input type="radio" name="assigned" #assignedFalse [value]="0" kendoRadioButton (change)="handleAssignedValue(0)" />
            <kendo-label class="k-radio-label" [for]="assignedFalse" text="No"> </kendo-label>
          </li>
        </ul>
      </div>
      <div class="search-field" fxLayout="row" fxLayoutAlign=" none" fxLayoutGap="10" *ngIf="this.cropManagerId">
        <span class="k-label">Select crop manager</span>
        <kendo-combobox [style.width.px]="300" textField="text" valueField="value" [data]="cropManagersArray" [filterable]="true" (filterChange)="handleCropManagerFilter($event)" (valueChange)="handleCropManagerValue($event)" placeholder="Crop manager" #cropManagerId></kendo-combobox>
      </div>
      <div class="search-field" fxLayout="row" fxLayoutAlign=" none" fxLayoutGap="10">
        <button kendoButton themeColor="primary" icon="search" (click)="applyFilter()">Search</button>
      </div>
    </div>
    <kendo-grid
      [data]="gridData"
      [pageSize]="state.take"
      [skip]="state.skip"
      [sort]="state.sort"
      [filter]="state.filter"
      [sortable]="sortingSettings"
      [pageable]="{
        info: pagingSettings.info,
        type: pagingSettings.pagerTypes,
        pageSizes: pagingSettings.pageSizes,
        previousNext: pagingSettings.previousNext,
        position: pagingSettings.position,
        responsive: pagingSettings.responsive
      }"
      filterable="menu"
      [columnMenu]="{ filter: true }"
      (dataStateChange)="dataStateChange($event)"
      style="max-height: 800px; margin-top: 10px !important; width:100%;"
    >
      <kendo-grid-column *ngIf="hiddenForMobile"
        title="Supervisor assignment"
        [columnMenu]="false"
        [headerClass]="'gridcolumn header'"
      >
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <span class="responsive-row"><b>{{dataItem.supervisorFirstName}} {{dataItem.supervisorLastName}}</b></span>
            <span class="responsive-row"><b>Email:</b> {{dataItem.supervisorEmail}}</span>
            <span class="responsive-row"><b>Mobile:</b> {{dataItem.supervisorMobile}}</span>
            <span class="responsive-row">
              <button kendoButton themeColor="success" icon="plus-circle" *ngIf="dataItem.assigned === 0" [style]="'width:100%;'" (click)="handleAssignment(dataItem, 1)">Assign</button>
              <button kendoButton themeColor="error" icon="minus-circle" *ngIf="dataItem.assigned === 1" [style]="'width:100%;'" (click)="handleAssignment(dataItem, 0)">Unassign</button>
            </span>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column-group *ngIf="!hiddenForMobile"
        title="Supervisors"
        [width]="720"
        [locked]="false"
        [headerClass]="'gridcolumn header group-header'"
      >
        <kendo-grid-column *ngIf="!hiddenForMobile"
          field="supervisorId"
          title="Id"
          filter="numeric"
          [headerClass]="'gridcolumn header'"
          [width]="80"
          [hidden]="true"
        >
        </kendo-grid-column>
        <kendo-grid-column *ngIf="!hiddenForMobile"
          field="supervisorFirstName"
          title="First name"
          filter="text"
          [headerClass]="'gridcolumn header'"
          [width]="160"
        >
        </kendo-grid-column>
        <kendo-grid-column *ngIf="!hiddenForMobile"
          field="supervisorLastName"
          title="Last name"
          filter="text"
          [headerClass]="'gridcolumn header'"
          [width]="160"
        >
        </kendo-grid-column>
        <kendo-grid-column *ngIf="!hiddenForMobile"
          field="supervisorEmail"
          title="Email"
          filter="text"
          [headerClass]="'gridcolumn header'"
          [width]="160"
        >
        </kendo-grid-column>
        <kendo-grid-column *ngIf="!hiddenForMobile"
          field="supervisorMobile"
          title="Mobile"
          filter="text"
          [headerClass]="'gridcolumn header'"
          [width]="160"
        >
        </kendo-grid-column>
      </kendo-grid-column-group>
      <kendo-grid-column-group *ngIf="!hiddenForMobile"
        title="Workers"
        [width]="400"
        [locked]="false"
        [headerClass]="'gridcolumn header group-header'"
      >
        <kendo-grid-column *ngIf="!hiddenForMobile"
          field="workerId"
          title="Id"
          filter="numeric"
          [headerClass]="'gridcolumn header'"
          [width]="80"
          [hidden]="true"
        >
          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <ng-container *ngIf="dataItem.workerId else unassignedTemplate">{{dataItem.workerId}}</ng-container>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column *ngIf="!hiddenForMobile"
          field="workerFirstName"
          title="First name"
          filter="text"
          [headerClass]="'gridcolumn header'"
          [width]="160"
        >
          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <ng-container *ngIf="dataItem.workerId else unassignedTemplate">{{dataItem.workerFirstName}}</ng-container>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column *ngIf="!hiddenForMobile"
          field="workerLastName"
          title="Last Name"
          filter="text"
          [headerClass]="'gridcolumn header'"
          [width]="160"
        >
          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <ng-container *ngIf="dataItem.workerId else unassignedTemplate">{{dataItem.workerLastName}}</ng-container>
          </ng-template>
        </kendo-grid-column>
      </kendo-grid-column-group>
      <kendo-grid-column-group  *ngIf="!hiddenForMobile"
        title="Action"
        [width]="160"
        [locked]="false"
        [headerClass]="'gridcolumn header group-header'"
      >
        <kendo-grid-column *ngIf="!hiddenForMobile"
          field="assigned"
          title="Action"
          filter="boolean"
          [headerClass]="'gridcolumn header'"
          [width]="160"
        >
          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <button kendoButton themeColor="success" icon="plus-circle" *ngIf="dataItem.assigned === 0" [style]="'width:100%;'" (click)="handleAssignment(dataItem, 1)">Assign</button>
            <button kendoButton themeColor="error" icon="minus-circle" *ngIf="dataItem.assigned === 1" [style]="'width:100%;'" (click)="handleAssignment(dataItem, 0)">Unassign</button>
          </ng-template>
        </kendo-grid-column>
      </kendo-grid-column-group>
    </kendo-grid>
    <ng-template #unassignedTemplate><span style="color:red; line-height:11px; font-size: 12px;display: block;"><span class="k-icon k-i-close-circle"></span>&nbsp;<b>Not assigned</b></span></ng-template>
  </div>
</div>

