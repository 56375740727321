import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { DataBindingDirective, GridDataResult } from '@progress/kendo-angular-grid';
import { UsersService } from 'src/app/modules/users/users.service';
import { SupervisorAssignments } from 'src/app/shared/models/supervisorAssignments.model';
import { User } from 'src/app/shared/models/user.model';
import { Worker } from 'src/app/shared/models/worker.model';
import { WorkerPictureCompare } from 'src/app/shared/models/workerPictureCompare.model';
import { WorkersService } from '../../workers.service';
import * as moment from 'moment';
import { Location } from '@angular/common'
/*import 'leaflet/dist/leaflet.css';*/


@Component({
  selector: 'app-worker-detail-compare-pictures',
  templateUrl: './worker-detail-compare-pictures.component.html',
  styleUrls: ['./worker-detail-compare-pictures.component.scss']
})
export class WorkerDetailComparePicturesComponent implements OnInit  {


  @ViewChild(DataBindingDirective) dataBinding: DataBindingDirective;

  public gridData: GridDataResult;
  public mySelection: string[] = [];
  public assignmentsDataArray: SupervisorAssignments[] = [];
  public filteredData: SupervisorAssignments[] = [];
  public pageCode: string;

  public hiddenForMobile: boolean = false;
  public fixedWidth: number = 150;

  private _currentUser: User;
  public workerName: string;

  public searchDate: Date = new Date();
  public currentDate: Date = new Date();
  public maxDate: Date = new Date();

  public paramDate: string;
  public paramLocationId: number;

  //currentUser
  public currentUser: User;

  //currentWorker
  public workerId: number;
  public worker: Worker;

  //parent general manager
  public generalManagerId: number;
  public generalManager: User;

  //parent crop manager
  public cropManagerId: number;
  public cropManager: User;

  //parent supervisor
  public supervisorId: number;
  public supervisor: User;

  // output
  public outputWorker: Worker;
  public outputGeneralManager: User;
  public outputCropManager: User;
  public outputSupervisor: User;

  // reporter
  public reporter: User;

  public workerPictureCompare?: WorkerPictureCompare;
  public imageDialogOpened: boolean = false;
  public showHeader = false;

  constructor(private _workersService: WorkersService, private _usersService: UsersService, private _route: ActivatedRoute, private _router: Router, private _location: Location) {
      this.hiddenForMobile = (window.innerWidth <= 1024);
      this.fixedWidth = (window.innerWidth <= 768) ? 100 : this.fixedWidth;
      if (window.innerWidth <= 768) {
        this.fixedWidth = 100;
    }
  };

  public ngOnInit(): void {
    const lsLoggedUser = localStorage.getItem('loggedUser')
    if (lsLoggedUser) {
      this._currentUser = JSON.parse(lsLoggedUser);
      this._route.queryParams
      .subscribe(params => {
        this.workerId = params['workerId'];
        this.generalManagerId = params['generalManagerId'];
        this.cropManagerId = params['cropManagerId'];
        this.supervisorId = params['supervisorId'];
        this.paramLocationId = params['locationId'];
        this.paramDate = params['date'];

        if (this.paramDate && this.paramLocationId) {
          this.searchDate = new Date(this.paramDate);
          this.currentDate = this.searchDate;
          this._workersService.getWorkerById(this.workerId).subscribe(worker => {
            this.worker = worker;
            this.outputWorker = worker;
            this.workerName = worker.firstName + ' ' + worker.lastName;
            this._getWorkerPictureByLocationId(this.paramLocationId);
          });
        } else if (this.workerId) {
          this._workersService.getWorkerById(this.workerId).subscribe(worker => {
            this.worker = worker;
            this.outputWorker = worker;
            this.workerName = worker.firstName + ' ' + worker.lastName;
            this._getWorkerPictureByDate(moment(this.currentDate).format('YYYY-MM-DD'), this.workerId);
          });
        }
        if (this.generalManagerId) {
          this._usersService.getUserById(this.generalManagerId).subscribe(user => {
            this.generalManager = user;
            this.outputGeneralManager = user;
          });
        }
        if (this.cropManagerId) {
          this._usersService.getUserById(this.cropManagerId).subscribe(user => {
            this.cropManager = user;
            this.outputCropManager = user;
          });
        }
        if (this.supervisorId) {
          this._usersService.getUserById(this.supervisorId).subscribe(user => {
            this.supervisor = user;
            this.outputSupervisor = user;
          });
        }

      });
    }

  }

  public applyFilter() {
    this.currentDate = this.searchDate;
    this._getWorkerPictureByDate(moment(this.currentDate).format('YYYY-MM-DD'), this.workerId);
  }

  private _getWorkerPictureByDate(currentDate: string, workerId: number) {
    this.workerPictureCompare = undefined;
    this._workersService.getWorkerPictureByDate(currentDate, workerId).subscribe(rec => {
      if (rec) {
        this.workerPictureCompare = rec;
        this._usersService.getUserById(rec.reportedUserId).subscribe(user => {
          this.reporter = user;
        });
      }
    });
  }

  private _getWorkerPictureByLocationId(locationId: number) {
    this.workerPictureCompare = undefined;
    this._workersService.getWorkerPictureByLocationId(locationId).subscribe(rec => {
      if (rec) {
        this.workerPictureCompare = rec;
        this._usersService.getUserById(rec.reportedUserId).subscribe(user => {
          this.reporter = user;
        });
      }
    });
  }

  public handleGoBackToDashboard() {
    let params = {};
    if (this.generalManagerId) {
      Object.assign(params, {generalManagerId:this.generalManagerId});
    }
    if (this.cropManagerId) {
      Object.assign(params, {cropManagerId:this.cropManagerId});
    }
    if (this.supervisorId) {
      Object.assign(params, {supervisorId:this.supervisorId});
    }
    if (this.workerId) {
      Object.assign(params, {workerId:this.workerId});
    }
    const navigationExtras: NavigationExtras = {};
    navigationExtras.queryParams = params;
    this._router.navigate(['staff/workers/dashboard'],navigationExtras);
  }

  public back(): void {
    this._location.back()
  }

}
