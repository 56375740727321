<div class="user-dashboard-page main-content" fxLayout="column" fxLayoutAlign="top center">
  <div class="button-bar" style="width:90vw;">
    <button *ngIf="!isSubPage"  kendoButton themeColor="primary" fillMode="outline" icon="chevron-left" (click)="goToButtonOnClick('list')">All crop managers</button>
    <button *ngIf="isSubPage && generalManager" kendoButton themeColor="primary" fillMode="outline" icon="chevron-left" (click)="goToButtonOnClick('generalManagerDashboard')">Dashboard of general manager <b>{{generalManager.firstName}} {{generalManager.lastName}}</b></button>
    <button *ngIf="isSubPage && !generalManager" kendoButton themeColor="primary" fillMode="outline" icon="chevron-left" (click)="goToButtonOnClick('list')">All crop managers</button>
  </div>
  <div class="detail-page dashboard" style="width:90vw;">
    <!-- LAYOUT OF DASHBOARD FOR CROP MANAGERS-->
    <div class="title-container">
      <h2 *ngIf="outputUser">Dashboard of crop manager {{outputUser.firstName}} {{outputUser.lastName}} </h2>
    </div>
    <kendo-tilelayout class="tile" [columns]="12" [rowHeight]="10" *ngIf="outputUser">
      <!-- Own data -->
      <kendo-tilelayout-item [col]="responsiveWrappers.cropManagerData.col" [colSpan]="responsiveWrappers.cropManagerData.colspan" [rowSpan]="responsiveWrappers.cropManagerData.rowspan" [classList]>
        <kendo-tilelayout-item-header class="title">
          <h3>Crop manager data</h3>
        </kendo-tilelayout-item-header>
        <kendo-tilelayout-item-body>
          <div class="content">
            <app-crop-manager-detail-data [userData] = "outputUser" [generalManager] = "outputGeneralManager"></app-crop-manager-detail-data>
          </div>
        </kendo-tilelayout-item-body>
      </kendo-tilelayout-item>
      <!-- Own crop managers -->
      <kendo-tilelayout-item [col]="responsiveWrappers.listOfSupervisors.col" [colSpan]="responsiveWrappers.listOfSupervisors.colspan" [rowSpan]="responsiveWrappers.listOfSupervisors.rowspan">
        <kendo-tilelayout-item-header class="list-subpage title" fxLayout="row" fxLayoutAlign="none">
          <div class="main-title" fxFlex="50">
            <h3>
              Own supervisors
            </h3>
          </div>
          <div class="commands-bar" fxFlex="50">
            <button kendoButton icon="plus-circle" (click)="onInsertButtonClick()">Insert</button>
          </div>
        </kendo-tilelayout-item-header>
        <kendo-tilelayout-item-body>
          <div class="content">
            <app-crop-manager-detail-related [userData] = "outputUser" [generalManager] = "outputGeneralManager"></app-crop-manager-detail-related>
          </div>
        </kendo-tilelayout-item-body>
      </kendo-tilelayout-item>
    </kendo-tilelayout>
  </div>
</div>
