<div class="wrapper">
  <kendo-expansionpanel
    *ngFor="let item of supervisors; index as i"
    [title]="item.firstName + ' ' + item.lastName"
    (action)="onAction(i)"
    [expanded]="(i === 0) && (recordCount === 1)"
  >
    <div class="content">
        <app-supervisor-detail-data [userData] = "item" [showEditButton] = "false" [showCost] = "false" [showNfcTag] = "false"></app-supervisor-detail-data>
    </div>
  </kendo-expansionpanel>
</div>
