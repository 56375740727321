<div class="login-container" fxLayout="column" fxLayoutAlign="center center" style="min-height: 100vh;">
  <div class="login-box">
    <div class="logo-container">
      <img src="/assets/media/images/logo_friel.png" alt="logo friel" class="logo" style="width:100%; max-width:420px; height:auto; opacity: 0.7;">
    </div>
    <div class="title-container">
      <div kendoTypography variant="h5" textAlign="center" [margin]="{ top: 0, bottom: 0}" themeColor="light">Account Login</div>
    </div>
    <div class="form-container">
      <form class="k-form"  #loginForm="ngForm" (ngSubmit)="onLoginFormSubmit()" [formGroup]="loginFormGroup">
        <fieldset class="k-form-fieldset">
          <legend class="k-form-legend">Your account</legend>
          <kendo-formfield>
            <kendo-label [for]="username" text="Username"></kendo-label>
            <kendo-textbox
              formControlName="username"
              #username
              required
              [clearButton]="true"
            ></kendo-textbox>
            <kendo-formhint>Your Username</kendo-formhint>
            <kendo-formerror>Error: Username is required</kendo-formerror>
          </kendo-formfield>
          <kendo-formfield>
            <kendo-label [for]="password" text="Password"> </kendo-label>
            <kendo-textbox
              formControlName="password"
              #password
              required
              [clearButton]="true"
              type="password"
            >
              <ng-template kendoTextBoxSuffixTemplate>
                <button type="button"
                  kendoButton
                  look="clear"
                  icon="eye"
                  (click)="toggleVisibility()"
                ></button>
              </ng-template>
            </kendo-textbox>
            <kendo-formhint>Your password</kendo-formhint>
            <kendo-formerror>Error: Password is required</kendo-formerror>
          </kendo-formfield>
          <div class="form-buttons">
            <div class="login-button-row">
              <button kendoButton themeColor="primary" type="submit" class="button-primary">Login</button>
              <button kendoButton (click)="clearForm()" type="button" class="button-secondary">Clear</button>
            </div>
            <!--
            <div class="resetpwd-button-row">
              <button kendoButton fillMode="link" class="button-link" (click)="clearForm()">Reset account</button>
            </div>
            -->
          </div>
        </fieldset>
      </form>
    </div>
    <div class="footer-container">
      <div kendoTypography variant="p" fontSize="sm" textAlign="center" class="poweredby-footer">
        <span><b>Powered by RR Solutions</b></span><br/>
        <span>Via Brennero 9a, 39100 Bolzano</span><br/>
        <span>Brennerstrasse 9/a 39100 Bozen</span><br/>
        <span><a href="mailto:info@rrsolutions.it" target="_blank">info&#64;rrsolutions.it</a></span> |
        <span><a href="//www.rrsolutions.it" target="_blank">www.rrsolutions.it</a></span>
        <div class="poweredby-logo">
          <img src="assets/media/images/Logox1.png" />
        </div>
      </div>

    </div>
  </div>
</div>
