import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, throwError } from 'rxjs';
import { SupervisorAssignments } from 'src/app/shared/models/supervisorAssignments.model';
import { User } from 'src/app/shared/models/user.model';
import { Worker} from 'src/app/shared/models/worker.model';
import { WorkerPictureCompare } from 'src/app/shared/models/workerPictureCompare.model';
import { environment } from 'src/environments/environment';

enum EndpointRoutes {
  GET_WORKERS = 'workers/getByType',
  GET_WORKER = 'worker/get',
  UPDATE_WORKER = 'worker/update',
  INSERT_WORKER = 'worker/create',
  DELETE_WORKER = 'worker/delete2',
  GET_SUPERVISORS_BY_WORKER_ID = 'worker/getRelatedSupervisors',
  GET_ASSIGNMENT_SUPERVISORS = 'worker/getAssignmentSupervisors',
  ASSIGN_WORKER_TO_SUPERVISOR = 'worker/assignWorkerToSupervisor',
  GET_WORKER_PICTURE_BY_DATE = 'worker/getWorkerPictureByDate',
  GET_WORKER_PICTURE_BY_LOCATION_ID = 'worker/getWorkerPictureByLocationId'
}

@Injectable({
  providedIn: 'root'
})


export class WorkersService {

  constructor(private _http: HttpClient) { }

  public getWorkers(id:number, type:string = 'user'): Observable<Worker[]> {
    let params = new HttpParams();
    params = params.append('id', id);
    params = params.append('type', type);
    return this._http.get<Worker[]>(environment.serverUrl + EndpointRoutes.GET_WORKERS, { params: params }).pipe(
      map(responseData => {
        return responseData;
      }),
      catchError(errorRes => {
        return throwError(() => errorRes);
      })
    );
  }

  public getWorkerById(workerId: number): Observable<Worker> {
    let params = new HttpParams();
    params = params.append('id', workerId.toString());
    return this._http.get<Worker>(environment.serverUrl + EndpointRoutes.GET_WORKER, { params: params }).pipe(
      map(responseData => {
        return responseData;
      }),
      catchError(errorRes => {
        return throwError(() => errorRes);
      })
    );
  }

  public updateWorker(workerObject: Worker): Observable<Worker> {
    return this._http.post<Worker>(environment.serverUrl + EndpointRoutes.UPDATE_WORKER, { worker: workerObject }).pipe(
      map(responseData => {
        return responseData;
      }),
      catchError(errorRes => {
        return throwError(errorRes);
      })
    );
  }

  public insertWorker(workerObject: Worker): Observable<Worker> {
    return this._http.put<Worker>(environment.serverUrl + EndpointRoutes.INSERT_WORKER, { worker: workerObject }).pipe(
      map(responseData => {
        return responseData;
      }),
      catchError(errorRes => {
        return throwError(errorRes);
      })
    );
  }

  public getSupervisorsByWorkerId(workerId:number): Observable<User[]> {
    let params = new HttpParams();
    params = params.append('workerId', workerId);
    return this._http.get<User[]>(environment.serverUrl + EndpointRoutes.GET_SUPERVISORS_BY_WORKER_ID, { params: params }).pipe(
      map(responseData => {
        return responseData;
      }),
      catchError(errorRes => {
        return throwError(() => errorRes);
      })
    );
  }

  public getAssignmentSupervisors(generalManagerId:number, cropManagerId:number, workerId:number): Observable<SupervisorAssignments[]> {
    let params = new HttpParams();
    params = params.append('generalManagerId', generalManagerId);
    params = params.append('cropManagerId', cropManagerId);
    params = params.append('workerId', workerId);
    return this._http.get<SupervisorAssignments[]>(environment.serverUrl + EndpointRoutes.GET_ASSIGNMENT_SUPERVISORS, { params: params }).pipe(
      map(responseData => {
        return responseData;
      }),
      catchError(errorRes => {
        return throwError(() => errorRes);
      })
    );
  }

  public assignWorkerToSupervisor(workerId: number, supervisorId: number, assign: number): Observable<any> {
    return this._http.post<any>(environment.serverUrl + EndpointRoutes.ASSIGN_WORKER_TO_SUPERVISOR, { workerId: workerId, supervisorId: supervisorId, assign: assign }).pipe(
      map(responseData => {
        return responseData;
      }),
      catchError(errorRes => {
        return throwError(errorRes);
      })
    );
  }


  public getWorkerPictureByDate(currentDate: string, workerId: number): Observable<WorkerPictureCompare> {
    let params = new HttpParams();
    params = params.append('workerId', workerId);
    params = params.append('currentDate', currentDate);
    return this._http.get<WorkerPictureCompare>(environment.serverUrl + EndpointRoutes.GET_WORKER_PICTURE_BY_DATE, { params: params }).pipe(
      map(responseData => {
        return responseData;
      }),
      catchError(errorRes => {
        return throwError(() => errorRes);
      })
    );
  }

  public getWorkerPictureByLocationId(locationId: number): Observable<WorkerPictureCompare> {
    let params = new HttpParams();
    params = params.append('locationId', locationId);
    return this._http.get<WorkerPictureCompare>(environment.serverUrl + EndpointRoutes.GET_WORKER_PICTURE_BY_LOCATION_ID, { params: params }).pipe(
      map(responseData => {
        return responseData;
      }),
      catchError(errorRes => {
        return throwError(() => errorRes);
      })
    );
  }

  public deleteWorker(id: string, comment?: string): Observable<any> {
    let params: any = {
      id: id
    };
    if (comment) {
      params.comment = comment;
    }
    return this._http.post(environment.serverUrl + EndpointRoutes.DELETE_WORKER, params).pipe(
      map(responseData => {
        return responseData;
      }),
      catchError(errorRes => {
        return throwError(() => errorRes);
      })
    );
  }


}
