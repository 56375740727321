import { Component, Input, OnInit, ViewChild} from '@angular/core';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { DataBindingDirective, DataStateChangeEvent, GridDataResult, MultipleSortSettings } from '@progress/kendo-angular-grid';
import { aggregateBy, AggregateDescriptor, GroupDescriptor, process, State } from '@progress/kendo-data-query';
import * as moment from 'moment';
import { DailyHoursReport } from 'src/app/shared/models/report.model';
import { UsersService } from '../../users/users.service';
import { ReportsService } from '../reports.service';
import { SharedService } from "../../../shared/shared.service";

@Component({
  selector: 'app-daily-hours',
  templateUrl: './daily-hours.component.html',
  styleUrls: ['./daily-hours.component.scss']
})

export class DailyHoursComponent implements OnInit {

  @ViewChild(DataBindingDirective) dataBinding: DataBindingDirective;

  @Input() generalManagerId: number;
  @Input() cropManagerId: number;
  @Input() supervisorId: number;

  public dateFromMax: Date = new Date();
  public dateToMax: Date = new Date();

  public dateFrom : Date = new Date(new Date().setDate(new Date().getDate()-1)); //yesterday
  public dateTo : Date = new Date(new Date().setDate(new Date().getDate()-1)); //yesterday
  public selectedSupervisorId?: number;
  public selectedUnitFarmId?: number;

  public gridData: GridDataResult;
  public mySelection: string[] = [];
  public dailyHoursArray: DailyHoursReport[] = [];
  public pageCode: string;

  public hiddenForMobile: boolean = false;
  public fixedWidth: number = 150;

  public state: State = {
    skip: 0
  };

  public showHeader = false;

  public aggregates: AggregateDescriptor[] = [
    { field: 'task.name', aggregate: 'count' },
    { field: 'minutes', aggregate: 'sum' }
  ];

  public group: GroupDescriptor[] = [
  ];

  public sortSettings: MultipleSortSettings = {
    mode: 'multiple'
};

  public total: any;

  public unitFarmsSource: Array<{ text: string; value: number }> = [];
  public unitFarmsArray: Array<{ text: string; value: number }>;

  public supervisorsSource: Array<{ text: string; value: number }> = [];
  public supervisorsArray: Array<{ text: string; value: number }>;

  public workersArray: number[] = [];
  public workersArrayCount: number = 0;

  constructor(private _reportsService: ReportsService, private _usersService: UsersService, private _sharedService: SharedService) {
      this.hiddenForMobile = (window.innerWidth <= 1024);
      if (window.innerWidth <= 768) {
        this.fixedWidth = 100;
      }
    };

  public ngOnInit(): void {

    const lsLoggedUser = localStorage.getItem('loggedUser')
    if (lsLoggedUser) {
      //this._currentUser = JSON.parse(lsLoggedUser);
      this._sharedService.getUnitFarms().subscribe({
        next: (res) => {
          res.forEach(rec => {
            this.unitFarmsSource.push({text: rec.name, value: rec.id});
            this.unitFarmsArray = this.unitFarmsSource;
          });
        },
        error: () => console.log('Unable to fetch unit farms'),
      });

      this._usersService.getSupervisors(0).subscribe(result => {
        if (!result) return;
        result.forEach(rec => {
          this.supervisorsSource.push({text: rec.firstName + ' ' + rec.lastName, value: rec.id});
          this.supervisorsArray = this.supervisorsSource;
        });
      });
    }

    this._setGridData();

  }

  private _setGridData() {
    const dateFrom = moment(this.dateFrom).format('YYYY-MM-DD');
    const dateTo = moment(this.dateTo).format('YYYY-MM-DD');
    const supervisorId = this.selectedSupervisorId;
    const unitFarmId = this.selectedUnitFarmId;
    this._reportsService.dailyHoursReport(dateFrom, dateTo, supervisorId, unitFarmId).subscribe(result => {
      if (result) {
        console.log('myResult:', result);
        this.dailyHoursArray = result;
        this.gridData = process(this.dailyHoursArray, this.state);
        this.total = aggregateBy(this.dailyHoursArray, this.aggregates);
        this.excelExportData = this.excelExportData.bind(this);
        this.createWorkerCount(this.gridData);
      }

    });

  }

  public dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    this.gridData = process(this.dailyHoursArray, this.state);
    this.createWorkerCount(this.gridData);
  }

  public excelExportData(): ExcelExportData {
    const excelState = this.state;
    const excelData = process(this.dailyHoursArray, {
      group: this.state.group,
      sort: this.state.sort,
      filter: this.state.filter,

    });
    const result: ExcelExportData = {
      data: excelData.data,
      group: excelState.group
    };
    return result;
  }

  public applyFilter() {
    this._setGridData();
  }

  public onGroupChange(group: GroupDescriptor[]): void {
    // set aggregates to the returned GroupDescriptor
    this.group = [];
    group.map((group) => (group.aggregates = this.aggregates));
    this.group = group;
  }

  public handleSupervisorFilter(value: any) {
    this.supervisorsArray = this.supervisorsSource.filter(
      (s) => s.text.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  }

  public handleSupervisorSelection(data: any) {
    if (data) {
      this.selectedSupervisorId = data.value;
    } else {
      this.selectedSupervisorId = undefined;
    }
  }

  public handleUnitFarmFilter(value: any) {
    this.unitFarmsArray = this.unitFarmsSource.filter(
      (u) => u.text.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  }

  public handleUnitFarmSelection(data: any) {
    if (data) {
      this.selectedUnitFarmId = data.value;
    } else {
      this.selectedUnitFarmId = undefined;
    }
  }

  public formatWorkingTime(minutes: number) {
    const hours = Math.floor(minutes/60);
    const minutesLeft = minutes % 60;
    const workingTimeString = `${hours < 10 ? '0' : ''}${hours}h   :  ${minutesLeft < 10 ? '0': ''}${minutesLeft}m`;
    return workingTimeString.toUpperCase();
  }

  public createWorkerCount(result: GridDataResult) {
    this.workersArray = [];
    result.data.forEach(rec => {
      if (!this.workersArray.includes(rec.worker.id)) {
        this.workersArray.push(rec.worker.id);
      }
    });
    this.workersArrayCount = this.workersArray.length;
  }

}
