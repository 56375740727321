<div *ngIf="!isSubPage" class="button-bar" style="width:90vw;margin-bottom:60px; border-bottom:1px solid rgba(0, 0, 0, 0.25); ">
  <button kendoButton themeColor="primary" fillMode="flat" (click)="goToButtonOnClick('listPage')">To the list</button>
</div>
<div *ngIf="isSubPage" class="button-bar" style="width:90vw;margin-bottom:60px; border-bottom:1px solid rgba(0, 0, 0, 0.25); ">
  <button kendoButton themeColor="primary" fillMode="flat" (click)="goToButtonOnClick('detailSubPage')">Dashboard of {{parentUser.firstName}} {{parentUser.lastName}}</button>
</div>
<div class="detail-page dashboard">
  <!-- LAYOUT OF DASHBOARD FOR GENERAL MANAGERS-->
  <div class="title-container" *ngIf="pageCode === 'generalManagers'">
    <h2>General manager dashboard</h2>
  </div>
  <kendo-tilelayout class="tile" [columns]="4" [rowHeight]="500" [reorderable]="true" [resizable]="true" *ngIf="pageCode === 'generalManagers'">
    <!-- Own data -->
    <kendo-tilelayout-item [col]="1" [colSpan]="1" >
      <kendo-tilelayout-item-header class="title">
        <h3>General manager data</h3>
      </kendo-tilelayout-item-header>
      <kendo-tilelayout-item-body>
        <div class="content">
        </div>
      </kendo-tilelayout-item-body>
    </kendo-tilelayout-item>
    <!-- Own crop managers -->
    <kendo-tilelayout-item [col]="2" [colSpan]="3">
      <kendo-tilelayout-item-header class="list-subpage title" fxLayout="row" fxLayoutAlign="none">
        <div class="main-title" fxFlex="50">
          <h3>
            Own crop managers
          </h3>
        </div>
        <div class="commands-bar" fxFlex="50">
          <button kendoButton [primary]="true" icon="plus-circle" (click)="onInsertButtonClick()">Insert</button>
        </div>
      </kendo-tilelayout-item-header>
      <kendo-tilelayout-item-body>
        <div class="content">
        </div>
      </kendo-tilelayout-item-body>
    </kendo-tilelayout-item>
  </kendo-tilelayout>
  <!-- LAYOUT OF DASHBOARD FOR CROP MANAGERS-->
  <div class="title-container" *ngIf="pageCode === 'cropManagers'">
    <h2>Crop manager dashboard</h2>
  </div>
  <kendo-tilelayout class="tile" [columns]="4" [rowHeight]="500" [reorderable]="true" [resizable]="true" *ngIf="pageCode === 'cropManagers'">
    <!-- Own data -->
    <kendo-tilelayout-item [col]="1" [colSpan]="1" >
      <kendo-tilelayout-item-header class="title">
        <h3>Crop manager data</h3>
      </kendo-tilelayout-item-header>
      <kendo-tilelayout-item-body>
        <div class="content">

        </div>
      </kendo-tilelayout-item-body>
    </kendo-tilelayout-item>
    <!-- Own supervisors -->
    <kendo-tilelayout-item [col]="2" [colSpan]="3">
      <kendo-tilelayout-item-header class="list-subpage title" fxLayout="row" fxLayoutAlign="none">
        <div class="main-title" fxFlex="50">
          <h3>
            Own crop managers
          </h3>
        </div>
        <div class="commands-bar" fxFlex="50">
          <button kendoButton [primary]="true" icon="plus-circle" (click)="onInsertButtonClick()">Insert</button>
        </div>
      </kendo-tilelayout-item-header>
      <kendo-tilelayout-item-body>
        <div class="content">
        </div>
      </kendo-tilelayout-item-body>
    </kendo-tilelayout-item>
  </kendo-tilelayout>
</div>
