<kendo-grid
  [data]="gridData"
  [pageSize]="state.take"
  [skip]="state.skip"
  [sort]="state.sort"
  [filter]="state.filter"
  [height]="370"
  [sortable]="sortingSettings"
  [pageable]="{
    info: pagingSettings.info,
    type: pagingSettings.pagerTypes,
    pageSizes: pagingSettings.pageSizes,
    previousNext: pagingSettings.previousNext,
    position: pagingSettings.position,
    responsive: pagingSettings.responsive
  }"
  [reorderable]="true"
  [resizable]="true"
  filterable="menu"
  [columnMenu]="{ filter: true }"
  (dataStateChange)="dataStateChange($event)"
>
  <kendo-grid-column
    field="firstName"
    title="First Name"
    filter="text"
    [width]="200"
    [headerClass]="'gridcolumn header'"
  >
  </kendo-grid-column>
  <kendo-grid-column
    field="lastName"
    title="Last Name"
    filter="text"
    [width]="200"
    [headerClass]="'gridcolumn header'"
  >
  </kendo-grid-column>
  <kendo-grid-column
    field="genderText"
    title="Gender"
    filter="text"
    [width]="150"
    [headerClass]="'gridcolumn header'"
  >
  </kendo-grid-column>
  <kendo-grid-column
    field="email"
    title="Email"
    filter="text"
    [width]="200"
    [headerClass]="'gridcolumn header'"
  >
  </kendo-grid-column>
  <kendo-grid-column
    field="mobile"
    title="Mobile"
    filter="text"
    [width]="200"
    [headerClass]="'gridcolumn header'"
  >
  </kendo-grid-column>
  <kendo-grid-column
    [headerClass]="{ 'text-center': true }"
    [class]="{ 'text-center': true }"
    [width]="150"
    [columnMenu]="false"
  >
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
      <kendo-splitbutton
        [data]="splitButtonItems"
        (buttonClick)="onSplitButtonClick()"
        (itemClick)="onSplitButtonItemClick($event, dataItem)">
        Actions
      </kendo-splitbutton>
    </ng-template>
  </kendo-grid-column>
</kendo-grid>
