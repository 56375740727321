import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import {
  DataBindingDirective,
  DataStateChangeEvent,
  GridDataResult,
  MultipleSortSettings,
  RemoveEvent
} from '@progress/kendo-angular-grid';
import { PagerPosition, PagerType } from '@progress/kendo-angular-grid/pager/pager-settings';
import { process, State } from '@progress/kendo-data-query';
import { User } from 'src/app/shared/models/user.model';
import { Worker } from 'src/app/shared/models/worker.model';
import { SharedService } from 'src/app/shared/shared.service';
import { WorkersService } from '../workers.service';
import { NotificationRef, NotificationService } from '@progress/kendo-angular-notification';

@Component({
  selector: 'app-workers-list',
  templateUrl: './workers-list.component.html',
  styleUrls: ['./workers-list.component.scss']
})
export class WorkersListComponent implements OnInit {

  @ViewChild(DataBindingDirective) dataBinding: DataBindingDirective;

  public gridData: GridDataResult;
  public mySelection: string[] = [];
  public workersDataArray: Worker[] = [];
  public pageCode: string;

  public hiddenForMobile: boolean = false;
  public fixedWidth: number = 150;

  private _varPagerTypes: PagerType = 'numeric';
  private _varPagerPosition: PagerPosition = 'both';

  private _currentUser: User;

  public dialogConfirmDeleteActive: boolean = false;

  public _currentWorker: Worker;

  public pagingSettings = {
    pagerTypes: this._varPagerTypes,
    type: 'input',
    // buttonCount: 5,
    info: true,
    previousNext: true,
    position: this._varPagerPosition,
    pageSize: 10,
    responsive: true,
    pageSizes: [
      5,
      10,
      20,
      50,
      100,
    ]
  };

  public sortingSettings: MultipleSortSettings = {
    allowUnsort: true
  };

  public state: State = {
    skip: 0,
    take: this.pagingSettings.pageSize,
    // Initial filter descriptor
  };

  public showHeader = false;

  public splitButtonItems = [
    {
      text: "Edit",
      icon: "edit",
      action: "update"
    },
    {
      text: "Delete",
      icon: "delete",
      action: "delete"
    },
    {
      text: "Dashboard",
      icon: "detail-section",
      action: "dashboard"
    }

  ];

  constructor(private _sharedService: SharedService,private _router: Router, private _workersService: WorkersService,
    private _notificationService: NotificationService) {
    this.hiddenForMobile = (window.innerWidth <= 1024);
    this.fixedWidth = (window.innerWidth <= 768) ? 100 : this.fixedWidth;
    if (window.innerWidth <= 768) {
      this.fixedWidth = 100;
      this.pagingSettings.pageSize = 5;
      this.state.take = 5;
    }
  };

  public ngOnInit(): void {

    const lsLoggedUser = localStorage.getItem('loggedUser')
    if (lsLoggedUser) {
      this._currentUser = JSON.parse(lsLoggedUser);
    }
    this._setGridData();
  }

  private _setGridData() {
    /*2023-07-03*/
    /*removed gerarchy all users can read, update or delete all supervisor*/
    /*this._workersService.getWorkers(this._currentUser.id).subscribe((result) => {*/
    this._workersService.getWorkers(
      this._currentUser.id, 'allWorkers'
      ).subscribe((result) => {
        result = result.filter(w => w.status === 1);
      result.forEach(user=> {
        user.genderText = this._sharedService.getGenderText(user.gender);
      } );
      this.workersDataArray = result;
      // console.log('this.workersDataArray:',result);
      this.gridData = process(result, this.state);
      this.excelExportData = this.excelExportData.bind(this);
    });
  }

  public dataStateChange(state: DataStateChangeEvent): void {
    if (state && state.group) {
      // state.group.map((group) => (group.aggregates = this.aggregates));
    }
    this.state = state;
    this.gridData = process(this.workersDataArray, this.state);
  }

  public excelExportData(): ExcelExportData {
    const excelState = this.state;
    const excelData = process(this.workersDataArray, {
      group: this.state.group,
      sort: this.state.sort,
      filter: this.state.filter
    });
    const result: ExcelExportData = {
      data: excelData.data,
      group: excelState.group
    };
    return result;
  }

  public onInsertButtonClick(): void {
    this._router.navigate(['staff/workers/insert']);
  }

  public onSplitButtonItemClick(button: { [key: string]: unknown }, dataItem:Worker): void {
    if (button) {
      switch (button['action']) {
        case 'dashboard':
          this._router.navigate(['staff/workers/dashboard'],{ queryParams: { workerId: dataItem.id } });
          break;
        case 'update':
          this._router.navigate(['staff/workers/update'],{ queryParams: { workerId: dataItem.id } });
          break;
        case 'delete':
          this.removeConfirmHandler(dataItem);
          break;
      }
    }
  }

  public onFilter(input: Event): void {
    const inputValue = (input.target as HTMLInputElement).value;
    if (inputValue) {
      this.state.filter =
        {
          logic: "or",
          filters: [
            {
              field: "firstName",
              operator: "contains",
              value: inputValue,
            },
            {
              field: "lastName",
              operator: "contains",
              value: inputValue,
            },
            {
              field: "code",
              operator: "contains",
              value: inputValue,
            },
            {
              field: "genderText",
              operator: "contains",
              value: inputValue,
            },
            {
              field: "city",
              operator: "contains",
              value: inputValue,
            },
            {
              field: "workerTypeName",
              operator: "contains",
              value: inputValue,
            },
            {
              field: "unitFarmName",
              operator: "contains",
              value: inputValue,
            },
            {
              field: "supervisorFullName",
              operator: "contains",
              value: inputValue,
            }
          ],
      };
    } else {
      this.state.filter = undefined;
    }
    this.state.skip = 0;
    this.gridData = process(this.workersDataArray, this.state);
  }

  public removeConfirmHandler(record: Worker): void {
    this.dialogConfirmDeleteActive = true
    this._currentWorker = record;
  }

  public dialogConfirmDeleteHandler(action:string) {
    switch (action.toLowerCase()) {
      case 'yes':
        this.removeHandler(this._currentWorker);
        this.dialogConfirmDeleteActive = false;
        break;
      case 'no':
        this.dialogConfirmDeleteActive = false;
        break;
    }
  }

  public removeHandler(record: Worker): void {
    this._workersService.deleteWorker(record.id.toString(), record.deletedReason).subscribe({
      complete:()=> {
        this._notificationService.show({
          content: 'The record has been successfully deleted',
          animation: { type: 'slide', duration: 500 },
          position: { horizontal: 'center', vertical: 'bottom' },
          type: { style: 'success', icon: true },
          hideAfter: 3000,
        });
        this._setGridData();
      },
      error: (error) => {
        console.log('error___this._workersService.deletePilot', error)
        const notification: NotificationRef =
          this._notificationService.show({
            content: error.error,
            animation: { type: 'slide', duration: 500 },
            position: { horizontal: 'center', vertical: 'bottom' },
            type: { style: 'error', icon: true },
            hideAfter: 3000,
          });

        notification.afterHide?.subscribe(()=> {
          this._setGridData();
        });
      }
    });
  }
}
