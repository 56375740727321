import { Injectable } from '@angular/core';
import { User } from './models/user.model';

@Injectable({
  providedIn: 'root'
})

export class PageNavigationService {
  pageCode: string;
  dataItemId: number | null = null;
  isSubPage: boolean;
  listPageUrl: string = '';
  dashboardPageUrl: string = '';
  updatePageUrl: string  = '';
  insertPageUrl: string = '';
  parentDashboardPageUrl: string = '';
  parentGeneralManager: User | null = null;
  parentCropManager: User | null = null;
  parentSupervisor: User | null = null;
  manageGeneralManagers: boolean;
  manageCropManagers: boolean;
  manageSupervisors: boolean;
  constructor() { }

  public removeLocalStorage() {
    localStorage.removeItem('pageNavigation');
  }
  public exportToLocalStorage() {
    localStorage.setItem('pageNavigation', JSON.stringify({
      pageCode: this.pageCode,
      dataItemId: this.dataItemId,
      isSubPage: this.isSubPage,
      listPageUrl: this.listPageUrl,
      dashboardPageUrl: this.dashboardPageUrl,
      updatePageUrl: this.updatePageUrl,
      insertPageUrl: this.insertPageUrl,
      parentDashboardPageUrl: this.parentDashboardPageUrl,
      parentGeneralManager: this.parentGeneralManager,
      parentCropManager: this.parentCropManager,
      parentSupervisor: this.parentSupervisor,
      manageGeneralManagers: this.manageGeneralManagers,
      manageCropManagers: this.manageCropManagers,
      manageSupervisors: this.manageSupervisors,
    }));
  }
  public importFromLocalStorage() {
    if (localStorage.getItem('pageNavigation')) {
      const raw = JSON.parse(localStorage.getItem('pageNavigation') || '{}');
      this.pageCode = raw.pageCode,
      this.dataItemId = raw.dataItemId,
      this.isSubPage = raw.isSubPage,
      this.listPageUrl = raw.listPageUrl,
      this.dashboardPageUrl = raw.dashboardPageUrl,
      this.updatePageUrl = raw.updatePageUrl,
      this.insertPageUrl = raw.insertPageUrl,
      this.parentDashboardPageUrl = raw.parentDashboardPageUrl,
      this.parentGeneralManager = raw.parentGeneralManager,
      this.parentCropManager = raw.parentCropManager,
      this.parentSupervisor = raw.parentSupervisor,
      this.manageGeneralManagers = raw.manageGeneralManagers,
      this.manageCropManagers = raw.manageCropManagers,
      this.manageSupervisors = raw.manageSupervisors
    }
  }
}
